import { UserModel } from '@/types/userModel.type';

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

// export const readToken = (): string => {
//   if (localStorage.getItem('remember')) return localStorage.getItem('accessToken') || '';
//   return '';
// };

export const readToken = (): string => {
  return localStorage.getItem('accessToken') || '';
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};
export const getPersistZone = () => {
  const zone = JSON.parse(localStorage.getItem('zone') || 'false');
  return zone;
};

export const deletePersistZone = (): void => {
  localStorage.removeItem('zone');
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : null;
};

export const deleteToken = (): void => {
  localStorage.removeItem('accessToken');
  sessionStorage.removeItem('accessToken');
};
export const deleteUser = (): void => localStorage.removeItem('user');
