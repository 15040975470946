import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@/components/common/forms/BaseForm/BaseForm';
import * as S from './SenSor.styles';
import * as Auth from '@/layouts/AuthLayout/AuthLayout.styles';

const initValues = {
  email: 'chris.johnson@altence.com',
};
const SenSorModal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BaseForm layout="vertical" initialValues={initValues}>
      <Auth.FormTitle>{t('sensorForm.title')}</Auth.FormTitle>
      <S.SensorDescription>
        {t('common.senSorSent')}
        <br />
        This will allow to connect the agent with your PTAAS instance
        <br />
        <br />
        If you need further help you can check
      </S.SensorDescription>
      <div
        style={{
          width: '25%',
          display: 'flex',
          borderStyle: 'solid',
          background: 'var(--UI-textbox, #F6F7FB)',
          margin: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <img style={{ width: 60, padding: 20 }} src="../images/hourglass 1.png" />

        <p
          style={{
            color: 'var(--UI-Element-05, #FF7168)',
            textAlign: 'center',
            fontSize: 35,
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            paddingTop: 5,
          }}
        >
          589400
        </p>
        <img style={{ width: 60, padding: 20 }} src="../images/copying 1.png" />
      </div>
      <BaseForm.Item noStyle>
        <S.SubmitButtonn>
          <S.SubmitButtonLeft>{t('sensor.closeInstructions')}</S.SubmitButtonLeft>
          <S.SubmitButtonRight>{t('sensor.acceptInstructions')}</S.SubmitButtonRight>
        </S.SubmitButtonn>
      </BaseForm.Item>
    </BaseForm>
  );
};

export default SenSorModal;
