import { BaseForm } from '@/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@/hooks/reduxHooks';
import * as Auth from '@/layouts/AuthLayout/AuthLayout.styles';
import { doLogin } from '@/store/slices/authSlice';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as S from './LoginForm.styles';
import { LoginFormData, initValues } from '@/types/auth.type';
import { strings } from '@/assets';
import { REDIRECT_URL_DEFAULT } from '@/constants/common.constant';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from 'antd';

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false); //Get Cookie Functions

  const handleSubmit = async (values: LoginFormData) => {
    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then(() => navigate(REDIRECT_URL_DEFAULT))
      .finally(() => setLoading(false));
  };
  return (
    <Auth.FormWrapper>
      <Form
        layout="vertical"
        onFinish={handleSubmit} // true/false/option/ react node
        initialValues={initValues}
        className="frm-login"
      >
        <Auth.FormTitle>Đăng nhập</Auth.FormTitle>
        <Auth.FormItem
          name="username"
          label="Tên đăng nhập"
          rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập' }]}
        >
          <Auth.FormInput
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={strings().username}
          />
        </Auth.FormItem>
        <Auth.FormItem
          label="Mật khẩu"
          name="password"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
        >
          <Auth.FormInputPassword
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder={'********'}
          />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>Quên mật khẩu</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Đăng nhập
          </Auth.SubmitButton>
        </BaseForm.Item>
      </Form>
    </Auth.FormWrapper>
  );
};
