export const pageTitles = {
  '/edit-homepage': 'home_page',
  '/users': 'user_page',
  '/dashboard': 'dashboard',
  '/general': 'general',
  '/comment': 'comment',
  '/media': 'media_page',
  '/pages': 'pages',
  '/categories': 'Categories',
  '/tag': 'tag',
  '/post': 'post',
  '/ckeditor': 'CKEditor',
  '/menu': 'Menu',
  '/news': 'News',
  '/page': 'manage_page',
  '/code-editor': 'code_editor',
};

export const greetingMessage = {
  top: 'welcome_admin',
  bottom: 'vasi',
};
