import { strings } from '@/assets';
import CKEditorCustom from '@/components/common/CKEditor';

import { DragDropFileInformation } from '@/components/fcomponents/FDragDrop/handle';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { hiddenDrawer } from '@/store/slices/appSlice';

import { Button, Form, Input, message, Tabs } from 'antd';
import { Store } from 'antd/es/form/interface';

import { FC, useEffect, useState } from 'react';
import './styles.scss';

import { TabsProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { createPage, updatePage } from '@/services/page.service';
import { Page } from '@/@types/page';

type TProps = {
  edit?: boolean;
  onFinishFailed: (errorInfo: unknown) => void;
  initialValues?: Store;
  buttonOkText?: string;
  attachments?: DragDropFileInformation;
  onClickEvent?: () => void;
  lang?: string;
};

type FieldType = {
  title?: string;
  _attachments: DragDropFileInformation;
  mediaId: string[];
  content?: string;
  category: string;
  thumbnail: string;
  excel: File;
  content_en?: string;
  title_en?: string;
};

const FrmPage: FC<TProps> = props => {
  const {
    edit = false,
    onFinishFailed,
    initialValues,
    buttonOkText = 'Create',
    attachments,
    onClickEvent,
  } = props;

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation('translation');

  const [attachmentList] = useState<DragDropFileInformation>({
    ...attachments,
  });

  useEffect(() => {
    form?.setFieldsValue({
      ...initialValues,
    });
  }, [attachmentList]);

  const onSubmitForm = async (id: string, values: Page) => {
    // Changed value been retrieved correctly, create logic to patch new detail to post

    // phduy - cmt: I log the value here to check if the value is correct
    if (!edit) {
      try {
        await createPage({
          ...values,
        });
        dispatch(hiddenDrawer());

        message.success(t('create_page_successful'));
      } catch (error: any) {
        message.error(error?.message);
      }
    } else {
      try {
        await updatePage(id, {
          ...values,
        });
        message.success(t('page_updated_successfully'));
        dispatch(hiddenDrawer());
      } catch (error: any) {
        console.error(error);
        message.error(error?.message);
      }
    }
  };

  // const handleEditorChange = (data: string) => {
  //   form.setFieldsValue({ content: data });
  // };

  const items: TabsProps['items'] = [
    {
      key: 'vi',
      label: 'VI',
      children: (
        <>
          <div className="f-frm__row">
            <Form.Item<FieldType>
              label={'Tiêu đề'}
              name="title"
              rules={[{ required: true, message: 'Nhập tiêu đề' }]}
            >
              <Input placeholder={'Nhập tiêu đề bài viết'} />
            </Form.Item>
          </div>

          <div className="f-frm__row">
            <Form.Item<FieldType>
              label={'Nội dung'}
              name={['content']}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập nội dung bài viết',
                },
              ]}
            >
              <CKEditorCustom
                data={initialValues?.content}
                onchange={data => {
                  form.setFieldsValue({ content: data });
                }}
              />
            </Form.Item>
          </div>
        </>
      ),
    },
    {
      key: 'en',
      label: 'EN',
      children: (
        <>
          <div className="f-frm__row">
            <Form.Item<FieldType> label={t('title')} name="title_en">
              <Input placeholder="Please input your title" />
            </Form.Item>
          </div>

          <div className="f-frm__row">
            <Form.Item<FieldType> label={t('content')} name={['content_en']}>
              <CKEditorCustom
                data={initialValues?.content_en}
                onchange={data => {
                  form.setFieldsValue({ content_en: data });
                }}
              />
            </Form.Item>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="f-frm user-wrap frmPost">
      {edit ? (
        <div className="article-link">
          <a
            target="_blank"
            href={`${import.meta.env.VITE_SITE_URL}/pages/${initialValues?.slug}`}
            className="btn btn-primary"
          >
            {t('view')}
          </a>
        </div>
      ) : null}
      <Form
        form={form}
        // key={Math.random()}
        name="frm-user"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{
          ...initialValues,
        }}
        onFinish={value => {
          onSubmitForm(initialValues?.id, {
            ...value,
          });
        }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Tabs defaultActiveKey={'vi'} items={items} />

        <div className="f-frm__controls">
          <Form.Item>
            <Button
              type="default"
              onClick={() => dispatch(hiddenDrawer())}
              className="mr-5 btn btn-drawer-cancel"
            >
              {strings().cancel}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn btn-drawer-ok"
              onClick={() => {
                onClickEvent?.();
              }}
            >
              {buttonOkText}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default FrmPage;
