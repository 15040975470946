import { UserModel } from '@/types/userModel.type';

export interface LoginFormData {
  username: string;
  password: string;
}

export const initValues: LoginFormData = {
  username: '',
  password: '',
};

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}
export interface ForgotPasswordRequest {
  newpassword: string;
  confirmpassword: string;
}
export interface NewPasswordRequest {
  currentpassword: string;
  newpassword: string;
  confirmpassword: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  password: string;
  username: string;
  key: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export interface LoginResponseDefault {
  data: { user: string; accessToken: string };
  response: Response;
}

export interface Response {
  user: User;
}

export interface User {
  authentication_token: string;
  email: string;
  name: string;
  id: number;
}

export interface Meta {
  code: number;
}

export interface UpdateUserProfileResponse {
  current_login_ip: string;
  last_login_ip: string;
  last_login_at?: unknown;
  id: number;
  username: string;
  user_type: string;
  login_count?: unknown;
  phone?: unknown;
  company?: unknown;
  active: boolean;
  email: string;
  zones: unknown[];
  current_login_at?: unknown;
  _id: number;
  name: string;
}

export interface UpdateUserProfileRequest {
  username: string;
  name: string;
  id?: number;
}
