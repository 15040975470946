import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IDrawerState {
  currentDrawer: string | null;
}

const initialState = {
  currentDrawer: null,
} as IDrawerState;

export const appSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setCurrentDrawer: (state, action: PayloadAction<string>) => {
      state.currentDrawer = action.payload;
    },
  },
});

export const { setCurrentDrawer } = appSlice.actions;
export default appSlice.reducer;
