import styled from 'styled-components';
import { FONT_SIZE, FONT_WEIGHT, media } from '@/styles/themes/constants';
import { BaseButton } from '@/components/common/BaseButton/BaseButton';

export const Description = styled.div`
  margin-bottom: 1.875rem;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};

  color: var(--Color-Text-03, #525d71);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xxs};
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
  }
`;
export const SubmitButtonn = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 20px 50px;
  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
`;
export const SubmitButtonLeft = styled(BaseButton)`
  width: 129px;
  height: 48px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--Primary-Colors-09, #305ec1);
  margin-right: 10px;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;
export const SubmitButtonRight = styled(BaseButton)`
  width: 129px;
  height: 48px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--Primary-Colors-09, #305ec1);
`;
