import React, { FC, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { FActions, FModal, FTable } from '@/components/fcomponents';
import FContent from '@/layouts/main/FContent';
import { CONTENT_STYLE } from '@/styles/themes/constants';
import { Content } from 'antd/es/layout/layout';
import { Flex, Space } from 'antd';
import { BaseButton } from '@/components/common/BaseButton/BaseButton';
import { t } from 'i18next';
import { strings } from '@/assets';
import { formatDate } from '@/utils';
import { ColumnsType } from 'antd/es/table';
import {
  deletePage,
  getAllPage,
  getInitFormFromPage,
  getPageDetail,
} from '@/services/page.service';
import { Page } from '@/@types/page';
import {
  hiddenModal,
  setContentDrawer,
  setContentModal,
} from '@/store/slices/appSlice';
import { useAppDispatch } from '@/hooks/reduxHooks';
import FrmPage from './FrmPage';
import { debounce } from 'lodash';

interface AllPagesPageProps {}

const AllPagesPage: FC<AllPagesPageProps> = (): React.JSX.Element => {
  const [loading, setLoading] = useState(true);

  // All Pages Data fetched from '/introduce' endpoint
  const [allPage, setAllPage] = useState<Page[]>([]);
  const [resultPages, setResultPages] = useState<Page[]>([]);
  const selectedUserRef = useRef<Page | null>(null);
  const formTypeRef = useRef('create');
  const dispatch = useAppDispatch();

  const columns: ColumnsType<Page> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 50,
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: t('title'),
      dataIndex: ['title'],
      width: 200,
      render: (text, record) => (
        <span className="cursor-pointer" onClick={() => handleClickRow(record)}>
          {text}
        </span>
      ),
    },

    {
      title: t('created_date'),
      dataIndex: ['created_at'],
      width: 200,
      render: text => (
        <span className="cursor-pointer">{formatDate(text)}</span>
      ),
    },
    {
      title: t('action'),
      dataIndex: 'action',
      align: 'center',
      width: 100,
      render(_, record) {
        return (
          <Space>
            <FActions
              id={record._id}
              showEdit={false}
              onClickRemove={() => {
                handleClickRemove(record as Page);
                setTimeout(() => {
                  fetchData();
                }, 1000);
              }}
              showPlus={false}
            />
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = async (): Promise<void> => {
    try {
      setLoading(true);
      const viewPage = await getAllPage();
      setAllPage(viewPage);
      setResultPages(viewPage);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleClickRemove = (record: Page) => {
    dispatch(
      setContentModal(
        <FModal
          title={t('delete_pages')}
          description={t('youre_deleting_an_page_this_action_cannot_be_undone')}
          onOk={() => handleDeletePost(record)}
        />,
      ),
    );
  };

  const handleDeletePost = async (record: Page) => {
    try {
      await deletePage(record._id);

      setResultPages(allPage.filter(page => page._id !== record._id));
      dispatch(setContentModal(null));
      dispatch(hiddenModal());
    } catch (error) {
      console.error('Error deleting report:', error);
    }
  };

  function renderHeaderRight() {
    return (
      <Flex vertical={false} gap={20} align="center" justify="flex-start">
        <BaseButton
          type="primary"
          className="capitalize add-button ml-6"
          icon={<i className="fa-light fa-circle-plus" />}
          onClick={() => handleShowDrawer('create')}
        >
          {t('add')}
        </BaseButton>
      </Flex>
    );
  }

  function handleShowDrawer(
    stt: 'create' | 'edit' | 'view',
    record: Page | null = null,
  ) {
    selectedUserRef.current = record;
    formTypeRef.current = stt;
    const commonProps = {
      // onFinish: handleFormSubmit,
      onFinishFailed: handleFormSubmitFailed,
      // @ts-expect-error
      initialValues: getInitFormFromPage(record?.data),
    };

    let title = '';
    let edit = false;
    let buttonOkText = '';

    switch (stt) {
      case 'create':
        title = `${t('create_new')}`;
        edit = false;
        buttonOkText = strings().create;
        break;
      case 'edit':
        title = `${t('edit')}`;
        edit = true;
        buttonOkText = strings().edit;
        break;
      case 'view':
        title = `${t('view')} `;
        edit = false;
        buttonOkText = '';
        break;
      default:
        break;
    }

    dispatch(
      setContentDrawer({
        content: (
          <FrmPage
            {...commonProps}
            edit={edit}
            buttonOkText={buttonOkText}
            onClickEvent={() => {
              setTimeout(() => {
                fetchData();
              }, 1000);
            }}
          />
        ),
        title: title,
      }),
    );
  }

  async function handleClickRow(record: Page) {
    const res = await getPageDetail(record.id);
    // Get specific post detail
    handleShowDrawer('edit', res);
  }

  const handleFormSubmitFailed = (errorInfo: unknown) => {
    console.log(
      '🚀 ~ file: index.tsx:34 ~ handleFormSubmitFailed ~ errorInfo:',
      errorInfo,
    );
  };

  const handleOnSearch = debounce((values: string) => {
    const filteredPosts = allPage.filter(page =>
      page.title.toLowerCase().includes(values.toLowerCase()),
    );
    setResultPages(filteredPosts);
  }, 500);

  return (
    <div className="all-pages">
      <Content style={CONTENT_STYLE}>
        <FContent renderPanelRight={renderHeaderRight}>
          <FTable
            useSearch
            onSearch={handleOnSearch}
            data={resultPages}
            tblProps={{
              rowKey: 'id',
              loading,
              columns,
              expandable: {},
            }}
          />
        </FContent>
      </Content>
    </div>
  );
};

export default AllPagesPage;
