import React, { FC } from 'react';
import './styles.scss';
import { Col, Row } from 'antd';
import { useResponsive } from '@/hooks/useResponsive';

interface FooterProps {
  children: React.ReactNode;
}

const Footer: FC<FooterProps> = ({ children }): React.JSX.Element => {
  const { isDesktop } = useResponsive();

  return (
    <footer className={`footer ${isDesktop ? 'is-footer-active' : ''}`}>
      <Row justify="center">
        <Col xxl={5} xs={24}>
          <p className="mb-0">{children}</p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
