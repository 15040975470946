import { strings } from '@/assets';
import APPCONFIG from '@/config/app.config';
import { Table as AntTable, Button, Form, Input } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { debounce } from 'lodash';
import { Fragment, useEffect } from 'react';
import './styles.scss';
import { FTableProps } from './type';

function FTable<T extends AnyObject>({
  data,
  className = '',
  wrapClassName = '',
  tblProps,
  useSearch,
  onSearch,
  onFocusSeach: onFocusSearch, // Please fix this spelling!
  renderTableControl,
  renderBottomControl,
}: FTableProps<T>) {
  const onFinish = (values: { s: string }) => {
    onSearch && onSearch(values['s']);
  };

  const debouncedHandleChange = debounce(value => {
    onSearch && onSearch(value);
  }, APPCONFIG.debounceTime);

  useEffect(() => {
    const updateShowText = () => {
      const paginationOptions = document.querySelector(
        '.ant-pagination-options',
      );

      if (paginationOptions) {
        let showText = paginationOptions.querySelector(
          '.custom-show-text',
        ) as HTMLElement;
        if (!showText) {
          showText = document.createElement('span') as HTMLElement;
          showText.className = 'custom-show-text';
          paginationOptions.insertBefore(
            showText,
            paginationOptions.firstChild,
          );
        }
        showText.textContent = strings().show; // Luôn cập nhật nội dung text tại đây
        showText.style.color = '#3F495C';
        showText.style.fontWeight = '600';
        showText.style.height = '32px';
      }
    };

    updateShowText();

    // Lắng nghe sự thay đổi của strings().show và cập nhật lại text
    const observer = new MutationObserver(updateShowText);
    observer.observe(document.documentElement, {
      attributes: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, [strings().show]);
  return (
    <div className={`ftable ftable-wrap ${wrapClassName}`}>
      <header className="ftable-head">
        <div className=" flex justify-between items-center">
          {/* <div className="ftable-head--left">
            {title && <h3 className="ftable-title">{title}</h3>}
          </div> */}
          <div className="ftable-head--right ftable-control flex">
            {useSearch ? (
              <Fragment>
                <Form
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 24 }}
                  onFinish={onFinish}
                  autoComplete="off"
                  className="relative"
                >
                  <Form.Item name="s" className="">
                    <Input
                      className="border rounded-[5px] border-solid border-[#EDF0F4] w-[300px] pl-4 pr-7 bg-fContent"
                      placeholder={strings().search}
                      onChange={e => debouncedHandleChange(e.target.value)}
                      onFocus={onFocusSearch}
                    />
                  </Form.Item>
                  <Form.Item className="absolute right-8 top-0 w-2">
                    <Button type="text" className="btn-search">
                      {/* <img  src={icons.search} alt="search" /> */}
                      <i className="fa-regular fa-magnifying-glass"></i>
                    </Button>
                  </Form.Item>
                </Form>
              </Fragment>
            ) : null}
            {renderTableControl?.()}
          </div>
        </div>
        {renderBottomControl && (
          <div className="ftable-head--bottom">{renderBottomControl?.()}</div>
        )}
      </header>
      <AntTable<T>
        className={`custom-table w-full ${className}`}
        dataSource={data} // Use 'data' instead of 'source'
        columns={tblProps.columns}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['20', '30', '50'],
          locale: { items_per_page: strings().rows },
          ...tblProps.pagination,
        }}
        expandable={tblProps.expandable}
        {...tblProps}
      />
    </div>
  );
}

export default FTable;
