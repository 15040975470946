import { ArticleType, CategoryData } from '@/@types/post';
import { strings } from '@/assets';
import CKEditorCustom from '@/components/common/CKEditor';

import { DragDropFileInformation } from '@/components/fcomponents/FDragDrop/handle';
import { useAppDispatch } from '@/hooks/reduxHooks';
import {
  createPost,
  getAllCategories,
  updatePost,
} from '@/services/post.service';
import { hiddenDrawer } from '@/store/slices/appSlice';

import {
  Button,
  Form,
  Image,
  Input,
  List,
  message,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Tabs,
  Upload,
} from 'antd';
import { Store } from 'antd/es/form/interface';

import { FC, useEffect, useState } from 'react';
import './styles.scss';

import { MediaTypeByID } from '@/@types/media';
import { getMediaDetailByID } from '@/services/media.service';

import { TabsProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import UploadClientComponent from './UploadClient';

type TProps = {
  edit?: boolean;
  onFinishFailed: (errorInfo: unknown) => void;
  initialValues?: Store;
  buttonOkText?: string;
  attachments?: DragDropFileInformation;
  onClickEvent?: () => void;
  lang?: string;
};

type FieldType = {
  title?: string;
  categoriesId?: string;
  _attachments: DragDropFileInformation;
  mediaId: string[];
  content?: string;
  category: string;
  thumbnail: string;
  excel: File;
  content_en?: string;
  title_en?: string;
};
interface TaxonomyPropType {
  allTaxonomy: CategoryData[] | undefined;
}

const FrmPost: FC<TProps> = props => {
  const {
    edit = false,
    onFinishFailed,
    initialValues,
    buttonOkText = 'Create',
    attachments,
    onClickEvent,
  } = props;

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { t } = useTranslation('translation');

  const [attachmentList] = useState<DragDropFileInformation>({
    ...attachments,
  });
  const [newsData, setNewsData] = useState<TaxonomyPropType>();
  const [fileThumbnail, setFileThumbnail] = useState<null>();
  const [fileExcel, setFileExcel] = useState<File | null>();
  const [selectedImages, setSelectedImages] = useState<any>(null);
  const [value, setValue] = useState(1);
  const [mediaData, setMediaData] = useState<MediaTypeByID>();

  const handleSubmitSelection = (result: any) => {
    setSelectedImages(result);
  };

  useEffect(() => {
    form?.setFieldsValue({
      ...initialValues,
      categoriesId: Array.isArray(initialValues?.category)
        ? initialValues?.category.map(item => item._id)
        : [],
      thumbnail: initialValues?.thumbnail,
      excel: initialValues?.excel,
    });
    setSelectedImages(initialValues?.mediaId);
  }, [attachmentList]);

  // No need to fetch Taxonomy List since the data already in article detail
  useEffect(() => {
    const fetchTaxonomyList = async () => {
      try {
        const allCategories = await getAllCategories();

        setNewsData({
          allTaxonomy: allCategories,
        });
      } catch (error: any) {
        message.error(error.message);
      }
    };

    fetchTaxonomyList();
  }, []);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const mediaId = selectedImages;
        if (mediaId) {
          const media = await getMediaDetailByID(mediaId);

          setMediaData(media);
        }
      } catch (error: any) {
        if (error.message === 'Không tìm thấy media!') {
          return;
        } else message.error(error.message);
      }
    };

    fetchMedia();
  }, [selectedImages]);

  const onSubmitForm = async (id: string, values: ArticleType) => {
    // Changed value been retrieved correctly, create logic to patch new detail to post

    // phduy - cmt: I log the value here to check if the value is correct
    if (!edit) {
      try {
        await createPost({
          ...values,
          fileExcel,
          fileThumbnail,
          mediaId: selectedImages[0],
        });
        dispatch(hiddenDrawer());

        message.success(t('create_successful_news'));
      } catch (error: any) {
        message.error(error?.message);
      }
    } else {
      try {
        await updatePost(id, {
          ...values,
          fileExcel,
          fileThumbnail,
          mediaId: selectedImages,
        });
        message.success(t('edited_successfully'));
        dispatch(hiddenDrawer());
      } catch (error: any) {
        message.error(error?.message);
      }
    }
  };

  // const handleEditorChange = (data: string) => {
  //   form.setFieldsValue({ content: data });
  // };

  const uploadThumbnail = ({ file }: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      message.success(t('uploaded_successfully'));
    };
    reader.onerror = () => {
      message.error(t('upload_failed'));
    };

    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (allowedFileTypes.includes(file.type)) {
      setFileThumbnail(file);
      reader.readAsDataURL(file);
    } else {
      message.error(t('only_image_files_are_allowed'));
    }
  };

  const uploadExcel = ({ file }: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      message.success(t('uploaded_successfully'));
    };
    reader.onerror = () => {
      message.error(t('upload_failed'));
    };

    const allowedFileTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    if (allowedFileTypes.includes(file.type)) {
      setFileExcel(file);
      reader.readAsDataURL(file);
    } else {
      message.error(t('only_excel_files_are_allowed'));
      setFileExcel(null);
      return;
    }
  };

  const items: TabsProps['items'] = [
    {
      key: 'vi',
      label: 'VI',
      children: (
        <>
          <div className="f-frm__row">
            <Form.Item<FieldType>
              label={'Tiêu đề'}
              name="title"
              rules={[{ required: true, message: 'Nhập tiêu đề' }]}
            >
              <Input placeholder={'Nhập tiêu đề bài viết'} />
            </Form.Item>
          </div>

          <div className="f-frm__row">
            <Form.Item<FieldType>
              label={'Nội dung'}
              name={['content']}
              rules={[
                {
                  required: true,
                  message: strings().please_enter_article_content,
                },
              ]}
            >
              <CKEditorCustom
                data={initialValues?.content}
                onchange={data => {
                  form.setFieldsValue({ content: data });
                }}
              />
            </Form.Item>
          </div>
        </>
      ),
    },
    {
      key: 'en',
      label: 'EN',
      children: (
        <>
          <div className="f-frm__row">
            <Form.Item<FieldType> label={t('title')} name="title_en">
              <Input placeholder={strings().please_input_your_title} />
            </Form.Item>
          </div>

          <div className="f-frm__row">
            <Form.Item<FieldType> label={t('content')} name={['content_en']}>
              <CKEditorCustom
                data={initialValues?.content_en}
                onchange={data => {
                  form.setFieldsValue({ content_en: data });
                }}
              />
            </Form.Item>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="f-frm user-wrap frmPost">
      {edit ? (
        <div className="article-link">
          <a
            target="_blank"
            href={`${import.meta.env.VITE_SITE_URL}/news/${initialValues?.slug}`}
            className="btn btn-primary"
          >
            {t('view')}
          </a>
        </div>
      ) : null}
      <Form
        form={form}
        // key={Math.random()}
        name="frm-user"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{
          ...initialValues,
          categoriesId: Array.isArray(initialValues?.category)
            ? initialValues?.category.map(item => item._id)
            : [],
        }}
        onFinish={value => {
          onSubmitForm(initialValues?.id, {
            ...value,
            // fileExcel,
            fileThumbnail,
            mediaId: selectedImages,
          });
        }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {/* 
          Thumbnail upload & selection logic
          */}

        {/* TODO: For debug */}
        <Tabs defaultActiveKey={'vi'} items={items} />

        <div className="f-frm__row">
          <Form.Item<FieldType>
            label={t('category')}
            name="categoriesId"
            rules={[
              { required: true, message: t('please_chose_your_category') },
            ]}
          >
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder={t('select_article_category')}
              mode="multiple"
              maxTagCount={5}
              maxTagPlaceholder={omittedValues =>
                `+${omittedValues.length} more`
              }
              maxTagTextLength={10}
            >
              {newsData?.allTaxonomy?.map(item => (
                <Option key={item._id} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="f-frm__row">
          <p className="mb-4">{t('image')}</p>
          <Radio.Group
            onChange={(e: RadioChangeEvent) => {
              setValue(e.target.value);
            }}
            value={value}
          >
            <Space direction="vertical" size={'large'}>
              <div className="thumbnail-wrapper">
                <Radio value={1}>
                  {/* [ ] Complete Thumbnail upload & selection logic  */}
                  <Upload
                    maxCount={1}
                    fileList={[]}
                    customRequest={uploadThumbnail}
                    showUploadList={false}
                  >
                    <Button
                      type="default"
                      size="large"
                      style={{ paddingInline: 23.5 }}
                    >
                      {t('upload_new_image')}
                    </Button>
                  </Upload>
                </Radio>
              </div>

              {value == 1 && (
                <Image
                  src={
                    fileThumbnail
                      ? URL.createObjectURL(fileThumbnail)
                      : initialValues?.thumbnail
                  }
                  height={200}
                />
              )}
              <Radio value={2}>
                {/* [ ] Complete media upload & selection logic  */}
                <UploadClientComponent
                  onUploadFile={handleSubmitSelection}
                  type="single"
                />
              </Radio>

              {value == 2 && (
                <div className="img-wraper">
                  <Image
                    src={
                      selectedImages
                        ? mediaData?.data.url
                        : initialValues?.mediaId
                    }
                    height={200}
                  />
                </div>
              )}
            </Space>
          </Radio.Group>
        </div>

        <div className="f-frm__row">
          <Form.Item<FieldType>
            className="evidence--upload-section"
            label="Excel"
            name="excel"
          >
            {/* [ ] Complete media upload & selection logic  */}
            <Upload
              maxCount={1}
              customRequest={uploadExcel}
              showUploadList={false}
            >
              <Button
                type="default"
                size="large"
                style={{ paddingInline: 23.5 }}
              >
                {t('upload_excel')}
              </Button>
            </Upload>

            {initialValues &&
              initialValues.tables.length > 0 &&
              initialValues.excelName &&
              !fileExcel && (
                <List
                  style={{ marginTop: 10 }}
                  size="small"
                  bordered
                  dataSource={[
                    {
                      name: '',
                    },
                  ]}
                  renderItem={_item => (
                    <List.Item actions={[]}>
                      {initialValues.excelName}
                    </List.Item>
                  )}
                />
              )}
            {fileExcel && (
              <List
                style={{ marginTop: 10 }}
                size="small"
                bordered
                dataSource={[
                  {
                    name: fileExcel.name,
                  },
                ]}
                renderItem={item => (
                  <List.Item
                    actions={[
                      <Button
                        type="link"
                        danger
                        onClick={() => {
                          // Handle file deletion logic here
                          setFileExcel(null);
                        }}
                      >
                        {t('delete')}
                      </Button>,
                    ]}
                  >
                    {item.name}
                  </List.Item>
                )}
              />
            )}
          </Form.Item>
        </div>

        <div className="f-frm__controls">
          <Form.Item>
            <Button
              type="default"
              onClick={() => dispatch(hiddenDrawer())}
              className="mr-5 btn btn-drawer-cancel"
            >
              {strings().cancel}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn btn-drawer-ok"
              onClick={() => {
                onClickEvent?.();
              }}
            >
              {buttonOkText}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default FrmPost;
