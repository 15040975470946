import { strings } from '@/assets';
import { BaseButton } from '@/components/common/BaseButton/BaseButton';
import { FActions, FModal, FTable } from '@/components/fcomponents';
import { useAppDispatch } from '@/hooks/reduxHooks';
import FContent from '@/layouts/main/FContent';
import {
  hiddenDrawer,
  hiddenModal,
  setContentModal,
} from '@/store/slices/appSlice';
import { Flex, Form, Input, Modal, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC, useEffect, useRef, useState } from 'react';
// import { UserType } from '@/@types/user';
import { CategoryType } from '@/@types/categories';
import { notificationController } from '@/components/controllers/notificationController';
import {
  createCategory,
  deleteCategory,
  getAllCategory,
  getCategory,
  makeDataCategoryEditRequest,
  makeDataCategoryRequest,
  updateCategory,
} from '@/services/categories.service';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { Content } from 'antd/es/layout/layout';
import { CONTENT_STYLE } from '@/styles/themes/constants';
import { Store } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '@/hooks/useLanguage';

type TProps = {
  edit?: boolean;
  initialValues?: Store;
  buttonOkText?: string;
  editPass?: boolean;
  onFinish?: (values: unknown) => void;
  onCancel?: () => void;
};

type FieldType = {
  name?: string;
  slug?: string;
  title_en?: string;
};
const CategoriesPage: FC<TProps> = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const selectedUserRef = useRef<CategoryType | null>(null);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState<CategoryType[]>([]);
  const [resultcategory, setResultCategory] = useState<CategoryType[]>([]);
  const formTypeRef = useRef('create');
  const dispatch = useAppDispatch();
  useEffect(() => {
    fetchData();
  }, []);
  const columns: ColumnsType<CategoryType> = [
    {
      title: strings().id,
      dataIndex: ['id'],
      width: 70,
      render: (_, record, index) => (
        <span style={{ fontSize: 15 }} onClick={() => handleClickRow(record)}>
          {index + 1}
        </span>
      ),
    },
    {
      title: strings().name,
      dataIndex: ['name'],
      width: 150,
      render: (text, record) => (
        <span style={{ fontSize: 15 }} onClick={() => handleClickRow(record)}>
          {text}
        </span>
      ),
    },
    {
      title: strings().title_en,
      dataIndex: ['title_en'],
      width: 150,
      render: (text, record) => (
        <span style={{ fontSize: 15 }} onClick={() => handleClickRow(record)}>
          {text}
        </span>
      ),
    },

    {
      title: 'Slug',
      dataIndex: ['slug'],
      width: 200,
      render: text => <span style={{ fontSize: 15 }}>{text}</span>,
    },
    {
      title: strings().action,
      dataIndex: 'action',
      align: 'center',
      width: 100,
      render(_, record) {
        return (
          <FActions
            showEdit={false}
            id={record._id}
            onClickRemove={() => handleClickRemove(record as CategoryType)}
            showPlus={false}
          />
        );
      },
    },
  ];
  const fetchData = async () => {
    setLoading(true);
    try {
      const categories = await getAllCategory();
      setCategory(categories);
      setResultCategory(categories);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
    setLoading(false);
  };
  const handleOnSearch = debounce((values: string) => {
    const filteredCategory = category.filter(categorys =>
      categorys.name.toLowerCase().includes(values.toLowerCase()),
    );
    setResultCategory(filteredCategory);
  }, 500);
  const handleClickRemove = (record: CategoryType) => {
    dispatch(
      setContentModal(
        <FModal
          title={t('delete_category')}
          description={t(
            'youre_deleting_category_this_action_cannot_be_undone_are_you_sure_you_want_to_proceed',
          )}
          onOk={() => handleDeleteUser(record)}
        />,
      ),
    );
  };
  const handleDeleteUser = async (record: CategoryType) => {
    try {
      await deleteCategory(record._id);
      setResultCategory(
        category.filter(category => category._id !== record._id),
      );
      fetchData();
      dispatch(setContentModal(null));
      dispatch(hiddenModal());
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };
  const handleFormSubmit = async (values: unknown) => {
    const selectedUser = selectedUserRef.current;
    try {
      if (formTypeRef.current === 'edit' && selectedUser) {
        if (typeof values === 'object' && values !== null) {
          const updateValues = {
            ...values,
          };
          const req = makeDataCategoryEditRequest(
            updateValues as CategoryType & Record<string, number | string>,
          );
          setResultCategory(prevCategory => {
            return prevCategory.map(category => {
              if (category._id === selectedUserRef.current?._id) {
                return {
                  ...category,
                  ...req,
                };
              }
              return category;
            });
          });
          await updateCategory(selectedUser._id, req);
          fetchData();
          dispatch(hiddenDrawer());
          setIsEditClockModalOpen(false);
          notificationController.success({
            message: t('category_updated_successfully'),
          });
        }
      } else {
        try {
          const req = makeDataCategoryRequest(
            values as CategoryType & Record<string, number | string>,
          );
          const newCategoryResponse = await createCategory(req);
          const newCategoryDoc: CategoryType = {
            _id: newCategoryResponse._id || '',
            name: newCategoryResponse.name || '',
            slug: newCategoryResponse.slug || '',
            title_en: newCategoryResponse.title_en || '',
          };
          setCategory(prevCategory => [...prevCategory, newCategoryDoc]);
          fetchData();
          dispatch(hiddenDrawer());
          setIsEditClockModalOpen(false);
          notificationController.success({
            message: t('categories_created_successfully'),
          });
        } catch (error) {
          console.error('Error creating category:', error);
        }
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      //TODO: Find out why when choosing higher permissions, error messages are not displayed
      if (axiosError.response && axiosError.response.status === 409) {
        notificationController.error({
          message: 'Email address is already in use',
        });
      } else {
        notificationController.error({
          message:
            'Create dupdated account role is higher than current account role',
        });
      }
    }
  };

  function handleShowDrawer(
    stt: 'create' | 'edit' | 'view',
    record: CategoryType | null = null,
  ) {
    selectedUserRef.current = record;
    formTypeRef.current = stt;
    if (stt === 'edit') {
      form.setFieldsValue({
        name: record?.name,
        title_en: record?.title_en,
      });
    }
    handleEditClockClick();
  }

  const [isEditClockModalOpen, setIsEditClockModalOpen] = useState(false);
  const handleEditClockClick = () => {
    setIsEditClockModalOpen(true);
  };
  function renderHeaderRight() {
    return (
      <Flex vertical={false} gap={20} align="center" justify="flex-start">
        <BaseButton
          type="primary"
          className="capitalize add-button"
          icon={<i className="fa-light fa-circle-plus" />}
          onClick={() => {
            formTypeRef.current = 'create';
            form.resetFields(); // Reset lại các field trong form khi tạo mới
            setIsEditClockModalOpen(true);
          }}
        >
          {t('add')}
        </BaseButton>
        <Modal
          title={
            formTypeRef.current === 'edit'
              ? t('edit_category')
              : t('add_new_category')
          }
          onCancel={() => setIsEditClockModalOpen(false)}
          open={isEditClockModalOpen}
          onOk={() => form.submit()} // Gọi submit form thay vì gọi trực tiếp handleFormSubmit
        >
          <Form
            form={form}
            name="frm-user"
            initialValues={{ name: name }}
            autoComplete="off"
            onFinish={handleFormSubmit}
          >
            <Tabs
              defaultActiveKey={language === 'vi' ? '1' : '2'}
              items={[
                {
                  label: 'VI',
                  key: '1',
                  children: (
                    <Form.Item<FieldType>
                      label={strings().name}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: t('please_input_your_category'),
                        },
                      ]}
                    >
                      <Input placeholder={t('label_category')} />
                    </Form.Item>
                  ),
                },
                {
                  label: 'EN',
                  key: '2',
                  children: (
                    <Form.Item<FieldType>
                      label={strings().name}
                      name="title_en"
                    >
                      <Input placeholder={t('label_category')} />
                    </Form.Item>
                  ),
                },
              ]}
            />
          </Form>
        </Modal>
      </Flex>
    );
  }
  async function handleClickRow(record: CategoryType) {
    try {
      const res = await getCategory(record._id);
      handleShowDrawer('edit', res.data);
    } catch (error) {
      console.error('Error clicking row:', error);
    }
  }
  return (
    <div className="categories-page">
      <Content style={CONTENT_STYLE}>
        <FContent renderPanelRight={renderHeaderRight}>
          <FTable
            title="All Categories"
            useSearch
            onSearch={handleOnSearch}
            data={resultcategory}
            tblProps={{
              rowKey: 'id',
              loading,
              columns,
              expandable: {},
            }}
          />
        </FContent>
      </Content>
    </div>
  );
};

export default CategoriesPage;
