import React from 'react';
import { ButtonProps as AntButtonProps, Button as AntdButton } from 'antd';
import { Severity } from '@/types/common.type';
import * as S from './BaseButton.styles';

export const ButtonGroup = AntdButton.Group;

export interface BaseButtonProps extends AntButtonProps {
  severity?: Severity;
  noStyle?: boolean;
}

export const BaseButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, BaseButtonProps>(
  ({ className, severity, noStyle, children, ...props }, ref) => (
    <S.Button ref={ref as React.Ref<HTMLButtonElement | HTMLAnchorElement>} className={`p-5 ${className}`} $noStyle={noStyle} {...props} $severity={severity}>
      {children}
    </S.Button>
  ),
);
