import MediaGrid from '@/components/fcomponents/MediaGrid';
import { ApiConfig } from '@/config/api.config';
import { readToken } from '@/services/localStorage.service';
import { CONTENT_STYLE } from '@/styles/themes/constants';
import { defaultStyle } from '@/utils/theme';
import { Button, Input, message, Modal, Upload, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { Content } from 'antd/es/layout/layout';
import { UploadFile } from 'antd/lib/upload/interface'; // Import UploadFile type
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const MediaPage = () => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewFile, setPreviewFile] = useState<UploadFile | null>(null); // New state for the file being previewed
  const [pendingUploadList, setPendingUploadList] = useState<UploadFile[]>([]);
  const [reRender, setReRender] = useState(false);

  const getBase64 = (file: File | Blob) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };
  const handlePreview = async (file: UploadFile) => {
    let previewImageUrl = file.url || '';

    if (!previewImageUrl && file.preview) {
      previewImageUrl = file.preview;
    } else if (!previewImageUrl && !file.preview) {
      previewImageUrl = await getBase64(file.originFileObj as File);
    }

    setPreviewVisible(true);
    setPreviewImage(previewImageUrl);
    setPreviewFile(file); // Update the previewFile state with the current file
  };

  const handleCancel = () => {
    setPreviewVisible(false);
    setPreviewFile(null);
  };

  /**
   * This function is called when the fileList state is changed.
   * It takes an object as a parameter with a property called fileList,
   * which is an array of UploadFile objects.
   * The function updates the fileList state with the new fileList array.
   */
  const handleChange = ({
    fileList,
    file,
  }: {
    fileList: UploadFile[];
    file: UploadFile;
  }) => {
    // Update the fileList state with the new fileList array.
    setPendingUploadList(fileList);

    if (file.status === 'done') {
      setReRender(!reRender); // Prevent multiple renders
      setPreviewVisible(false)
      setPendingUploadList([]);

      message.success(t('success'));
    } else if ( file.status === "error") {
      message.error(file.response.message);
    }
  };

  const { t } = useTranslation();

  const uploadButton = (
    <div>
      <div className="ant-upload-text">{t('upload')}</div>
    </div>
  );

  const token = readToken();

  const props: UploadProps = {
    action: ApiConfig.apiBaseUrl + '/medias',
    headers: {
      authorization: 'Bearer ' + token,
    },
  };

  return (
    <Content style={CONTENT_STYLE}>
      <div className="media-page">
        {/* Upload Box */}
        <ImgCrop>
          <Upload
            fileList={pendingUploadList}
            onPreview={handlePreview}
            onChange={handleChange}
            {...props}
          >
            <Button style={{ ...defaultStyle.defaultDimension }}>
              {pendingUploadList.length >= 8 ? null : uploadButton}
            </Button>
          </Upload>
        </ImgCrop>
        {/* Preview Image modal */}
        <Modal
          visible={previewVisible}
          title="Preview"
          footer={null}
          onCancel={handleCancel}
        >
          <div className="image-preview-container">
            <img alt="example" className="preview-image" src={previewImage} />
            <div className="image-info">
              {previewFile && ( // Check if previewFile is not null before rendering its info
                <div style={{ paddingBottom: 130 }}>
                  <p
                    style={{
                      width: 100,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    Name: {previewFile.name}
                  </p>
                  <p>Size: {previewFile.size} bytes</p>
                  <p>Type: {previewFile.type}</p>
                  <Input placeholder="Title" />
                  <Input.TextArea placeholder="Description" />
                  <div style={{ display: 'flex' }}>
                    <Button>Save</Button>
                    <Button>Cancel</Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
        <MediaGrid reRender={reRender} />
      </div>
    </Content>
  );
};

export default MediaPage;
