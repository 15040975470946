import React, { FC } from 'react';
import './styles.scss';
import Header from '@/layouts/main/Header';
import MainContent from '@/layouts/main/MainContent';
import { LayoutContext } from './layoutContext';

const MainLayout: FC = (): React.JSX.Element => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true); // This state has to be sync with <Sidebar />'s

  const contextValue = {
    isSidebarOpen,
    setIsSidebarOpen,
  };

  return (
    <>
      <LayoutContext.Provider value={contextValue}>
        <div className="page-wrapper null compact-wrapper" id="pageWrapper">
          <Header />
          <MainContent />
        </div>
      </LayoutContext.Provider>
    </>
  );
};

export default MainLayout;
