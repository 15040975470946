import React from 'react';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { ForgotPasswordForm } from '@/components/auth/ForgotPasswordForm/ForgotPasswordForm';
const ForgotPasswordPage: React.FC = () => {
  return (
    <>
      <PageTitle>Reset Password</PageTitle>
      <ForgotPasswordForm />
    </>
  );
};

export default ForgotPasswordPage;
