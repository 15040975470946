import { ApiConfig } from '@/config/api.config';
import { readToken } from '@/services/localStorage.service';
import { Button, Upload, UploadFile, UploadProps } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface MediaProps {
  handleChange: (
    info: UploadChangeParam<UploadFile>,
    identifier?: string,
  ) => void;
  pendingUploadList: UploadFile[];
  identifier?: string | undefined;
  custom?: boolean;
}

const MediaUpload: FC<MediaProps> = ({
  handleChange,
  pendingUploadList,
  identifier,
  custom = true,
}): React.JSX.Element => {
  const token = readToken();
  const uploadProps: UploadProps = {
    action: ApiConfig.apiBaseUrl + '/medias',
    headers: {
      authorization: 'Bearer ' + token,
    },
    fileList: pendingUploadList,
    onChange: info => {
      handleChange(info, identifier);
    },
    // onChange: identifier => handleChange,
  };

  const { t } = useTranslation();

  const uploadButton = (
    <Button type="default" size="large" style={{ paddingInline: 23.5 }}>
      {t('uploadNewImage')}
    </Button>
  );

  return (
    <div>
      {custom ? <Upload {...uploadProps}>{uploadButton}</Upload> : null}
    </div>
  );
};

export default MediaUpload;
