import { defaultStyle } from '@/utils/theme';
import { Button, Flex } from 'antd';
import React, { FC, useState } from 'react';
import MediaUpload from '../HandleMediaFileUpload';
import './styles.scss';

import { UploadFile } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import SelectExistingImageModal from '../SelectExistingImageModal';

interface MediaUploadGroupProps {
  // isShowDrawer: boolean;
  // setIsShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  pendingUploadList: UploadFile[];
  handleFileChange: (
    fileList: UploadFile<any>[],
    file: UploadFile<any>,
  ) => Promise<void>;
  handleSubmitSelection: (result: string[] | number[]) => void;
  custom?: boolean;
  excel?: boolean;
  type?: 'muti' | 'single';
  onUploadFile?: (file: any) => void;
  excludedMedia?: number[] | string[];
  gapValue?: number;
}

const MediaUploadGroup: FC<MediaUploadGroupProps> = ({
  pendingUploadList,
  type,
  handleSubmitSelection,
  custom,
  excludedMedia,
  handleFileChange,
  gapValue = 20,
}): React.JSX.Element => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const selectButton = (
    <Button
      type="primary"
      size="large"
      onClick={() => setIsShowModal(!isShowModal)}
      style={{ ...defaultStyle.primaryButton, marginLeft: custom ? 10 : 0 }}
    >
      {t('Select Existing Image')}
    </Button>
  );

  return (
    <div className="media-upload-group">
      <Flex gap={gapValue}>
        <MediaUpload
          pendingUploadList={pendingUploadList}
          //@ts-expect-error handleChange in childComponent asking for identifier (feature still works though)
          handleChange={handleFileChange}
          custom={custom}
        />
        {selectButton}
      </Flex>

      <SelectExistingImageModal
        isShowDrawer={isShowModal}
        setIsShowDrawer={setIsShowModal}
        drawerProps={{
          title: t('Select Media'),
          height: '85%',
          placement: 'bottom',
          width: 500,
        }}
        excludedMedia={excludedMedia}
        // [x] Complete the submit process for this component
        onProccessComplete={handleSubmitSelection}
        type={type}
      />
    </div>
  );
};

export default MediaUploadGroup;
