import { MenuData, MenuDataHunter } from '@/@types/menus';
import { httpApi } from '@/services/http.service';

export const getAllMenu = async () => {
  const res = await httpApi.get<{
    success: boolean;
    data: MenuData[];
  }>('/menus');

  return res.data.data;
};

export const getAllNavigation = async () => {
  const res = await httpApi.get<{
    success: boolean;
    //@ts-expect-ignore Navigation is not available on new endpoint
    data: any;
  }>('/navigations');
  return res.data.data;
};

export const getMenu = async (id: number) => {
  const res = await httpApi.get(`/navigations/${id}`);
  return res.data;
};

export const createMenu = async (
  data: MenuDataHunter,
): Promise<MenuDataHunter> => {
  const res = await httpApi.post<MenuDataHunter>('/menus', data);
  return res.data;
};

export const updateMenu = async (menuId: MenuData['id'], body: any) => {
  const res = await httpApi.put(`/menus/${menuId}`, body);
  return res.data;
};

export const deleteMenu = async (menuId: number): Promise<void> => {
  await httpApi.delete<void>(`/menus/${menuId}`);
};
