import React, { FC } from 'react';
import { Col, Input, Typography } from 'antd';
import { GeneralType } from '@/@types/general';
import TextArea from 'antd/es/input/TextArea';

interface InputBoxProps {
  label: string;
  identifier: keyof GeneralType;
  inputValue: string | number;
  handleInputChange: (value: string, identifier: keyof GeneralType) => void;
  responsiveProps: Object;
  isTextArea?: boolean;
}

const InputBox: FC<InputBoxProps> = ({
  label,
  inputValue,
  handleInputChange,
  identifier,
  responsiveProps,
  isTextArea,
}): React.JSX.Element => {
  return (
    <Col {...responsiveProps} style={{paddingBottom: 8, paddingTop: 8}}>
      <Typography.Title level={5}>{label}</Typography.Title>
      {isTextArea ? (
        <TextArea
          rows={3}
          value={inputValue}
          onChange={event => handleInputChange(event?.target.value, identifier)}
        />
      ) : (
        <Input
          value={inputValue}
          onChange={event => handleInputChange(event.target.value, identifier)}
        />
      )}
    </Col>
  );
};

export default InputBox;
