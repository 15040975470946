import { strings } from '@/assets';
import type { FormProps } from 'antd';
import { Button, Form, Input } from 'antd';
import { t } from 'i18next';

type FieldType = {
  type: string;
  link?: string;
  label?: string;
};

interface IProps extends FormProps<FieldType> {
  onFinish: (values: FieldType) => void;
}

function CreateCustomLink(props: IProps) {
  const { onFinish } = props;
  const [form] = Form.useForm();
  //reset form before submit
  const handleSubmit: (values: any) => void = values => {
    form.resetFields();
    onFinish(values);
  };
  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      autoComplete="off"
    >
      <Form.Item<FieldType> name="type" hidden initialValue={'custom'}>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item<FieldType>
        label={t('label')}
        name="label"
        rules={[{ required: true, message: strings().please_input_your_label }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<FieldType>
        label={t('link')}
        name="link"
        rules={[
          { required: true, message: strings().please_input_your_link, type: 'url' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 24 }}>
        <Button type="primary" htmlType="submit" style={{backgroundColor: 'rgb(1,80,154)'}}>
          {strings().add}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CreateCustomLink;
