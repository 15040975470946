import { BaseForm } from '@/components/common/forms/BaseForm/BaseForm';
import React from 'react';
import * as S from './styles';
import PopupImage from '@/assets/images/Layer_1.png';
// import * as Auth from '@/layouts/AuthLayout/AuthLayout.styles';

import { Button } from '@/components/common/BaseButton/BaseButton.styles';
import { hiddenModal } from '@/store/slices/appSlice';
import { useAppDispatch } from '@/hooks/reduxHooks';
import SenSorModal from './SenSor';
import { strings } from '@/assets';

export type TPropsModal = {
  title: string;
  description: string;
  onCancel?: () => void;
  onOk?: () => void;
  isDisabled?:boolean
};

interface FModalCompositions {
  SensorModal: typeof SenSorModal;
}

const FModal: React.FC<TPropsModal> & FModalCompositions = props => {
  const dispatch = useAppDispatch();

  const { title, description, onCancel, onOk,isDisabled } = props;
  function handleClickCancel() {
    dispatch(hiddenModal());
    onCancel?.();
  }
  return (
    <div className="modal-wrap">
      <S.ImageWrapper>
        <img src={PopupImage} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} alt="Not found" />
      </S.ImageWrapper>
      <S.FormTitle>{title}</S.FormTitle>
      <S.PopupDescription>
        {description}
        {!isDisabled && <>
          <br /> {strings().are_you_sure_you_want_to_proceed}
        </>}
      </S.PopupDescription>
      <BaseForm.Item noStyle>
        <S.SubmitButtonn>
          <Button onClick={handleClickCancel} className="btn btn-cancel mr-1">
            {strings().cancel}
          </Button>
          <Button onClick={onOk} className="btn btn-save ml-2 " disabled={isDisabled}>
            {strings().delete}
          </Button>
        </S.SubmitButtonn>
      </BaseForm.Item>
    </div>
  );
};

FModal.SensorModal = SenSorModal;

export default FModal;
