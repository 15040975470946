import axios, { AxiosError, AxiosResponse } from 'axios';
// import { AxiosError } from 'axios';
// import { ApiError } from '@/api/ApiError';
import { readToken } from '@/services/localStorage.service';
import { ApiConfig } from '@/config/api.config';
import { notificationController } from '@/components/controllers/notificationController';
import { IResError } from '@/@types/res';
// import { message as AntdMessage } from 'antd';

export const httpApi = axios.create({
  baseURL: ApiConfig.apiBaseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    timeout: 60000,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

// export const newHttpApi = axios.create({
//   baseURL: ApiConfig.apiNewBaseUrl,
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     timeout: 60000,
//   },
// });


httpApi.interceptors.request.use(config => {
  const token = readToken(); // Function which retrieves token from local storage
  if (token !== '') {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

httpApi.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError<IResError>) => {
    if (error.response?.status === 400) {
      const { messages, message } = error.response.data;

      if (message) {
        // AntdMessage.error(message);
      }

      if (Array.isArray(messages)) {
        messages.forEach(msg => {
          console.log('msg', msg);
          // AntdMessage.error(msg);
        });
      } else if (typeof messages === 'string') {
        // AntdMessage.error(messages);
      } else if (typeof messages === 'object' && messages.json) {
        if (typeof messages.json === 'object') {
          Object.entries(messages.json).forEach(([key, value]) => {
            // AntdMessage.error(`${key}: ${value}`);
            notificationController.error({ message: `${key}: ${value}` });
          });
        } else {
          // AntdMessage.error(messages.json);
          notificationController.error({ message: `${messages.json}` });
        }
      } else {
        //
      }

      return Promise.reject(error.response.data);
    }

    throw error;
  },
);

// httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
//   throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
// });

export interface ApiErrorData {
  message: string;
}
