import { HomeConfigData } from '@/@types/home-carousel';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';

import { Badge, Button, Col, Image, Modal, Row, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import './styles.scss';
import { t } from 'i18next';

interface CarouselMediaGridProps {
  activeCarouselData: HomeConfigData['sliders'];
  onChangeActive: (data: HomeConfigData['sliders']) => void;
}

const CarouselMediaGrid: FC<CarouselMediaGridProps> = ({
  activeCarouselData,
  onChangeActive,
}): React.JSX.Element => {
  const [mediaData, setMediaData] =
    useState<HomeConfigData['sliders']>(activeCarouselData);
  const [currentlySelectedID, setCurrentlySelectedID] = useState<string>();

  const { confirm } = Modal;

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: t('Are you sure delete this image'),
      icon: <ExclamationCircleFilled />,
      content: t('This action cannot be undone'),
      okText: t('yes'),
      okType: 'primary',
      cancelText:  t('cancel'),

      styles: {
        footer: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },

      onOk() {
        handleDelete(id);
      },
    });
  };

  useEffect(() => {
    setMediaData(activeCarouselData);
  }, [activeCarouselData]);

  const handleDelete = (item: string) => {
    const updatedData = mediaData.filter(media => media._id !== item);
    setMediaData(updatedData);
    onChangeActive(updatedData);
  };

  const deleteButton = (id: string) => {
    return (
      <Button
        icon={<DeleteOutlined />}
        onClick={() => showDeleteConfirm(id)}
      ></Button>
    );
  };

  const handleImageClick = (id: string) => {
    setCurrentlySelectedID(currentlySelectedID === id ? undefined : id);
  };

  const handleMoveLeft = () => {
    if (currentlySelectedID === null || currentlySelectedID === undefined) {
      return;
    }

    const index = mediaData.findIndex(item => item._id === currentlySelectedID);
    if (index > 0) {
      const updatedData = [...mediaData];
      const temp = updatedData[index];
      updatedData[index] = updatedData[index - 1];
      updatedData[index - 1] = temp;
      setMediaData(updatedData);
      onChangeActive(updatedData);
    }
  };

  const handleMoveRight = () => {
    if (currentlySelectedID === null || currentlySelectedID === undefined) {
      return;
    }

    const index = mediaData.findIndex(item => item._id === currentlySelectedID);
    if (index < mediaData.length - 1) {
      const updatedData = [...mediaData];
      const temp = updatedData[index];
      updatedData[index] = updatedData[index + 1];
      updatedData[index + 1] = temp;
      setMediaData(updatedData);
      onChangeActive(updatedData);
    }
  };

  return (
    <div className="media-grid">
      <Row
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 32 },
          { xs: 8, sm: 16, md: 24, lg: 32 },
        ]}
      >
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) =>
              console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          {mediaData.map(item => (
            <Col
              lg={4}
              xs={24}
              md={12}
              className="gutter-row"
              span={6}
              key={item.url}
            >
              <Tooltip title={`ID: ${item._id} - ${item.filename}`}>
                <Badge count={deleteButton(item._id)}>
                  <div
                    className={`carousel-items ${currentlySelectedID === item._id ? 'active' : ''}`}
                  >
                    <Image
                      key={item.url}
                      src={item.sizes.desktop}
                      alt={`${item._id} - ${item.filename}`}
                      preview={false}
                      onClick={() => handleImageClick(item._id)}
                    />
                  </div>
                </Badge>
              </Tooltip>
            </Col>
          ))}
        </Image.PreviewGroup>
      </Row>
      <div className="media-grid__control">
        <Button onClick={handleMoveLeft}>
          <CaretLeftOutlined />
        </Button>
        <Button onClick={handleMoveRight}>
          <CaretRightOutlined />
        </Button>
      </div>
    </div>
  );
};

export default CarouselMediaGrid;
