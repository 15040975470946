import { FC, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import viVN from 'antd/lib/locale/vi_VN';
import enUS from 'antd/lib/locale/en_US';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
// import * as lightTheme from './theme/light.json';
import GlobalStyle from './styles/GlobalStyle';
import './styles/global.scss';
import './assets/fonts/fonts.css';
import { useAppDispatch } from './hooks/reduxHooks';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const App: FC = () => {
  const { language } = useLanguage();
  const dispatch = useAppDispatch();
  // const dispatch = useAppDispatch();
  //onload all required data
  useEffect(() => {
    async function onLoad() { }
    onLoad();
  }, [dispatch]);

  return (
    <>
      {/* <meta name="theme-color" content={themeObject[theme].primary} /> */}
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider
          locale={language === 'en' ? enUS : viVN}
          theme={{
            token: {
              fontFamily: 'Inter-regular',
            },
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <AppRouter />
          </DndProvider>
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
