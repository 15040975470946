const primaryButtonColor:string = "#273c75"


export const defaultStyle = {
  primaryButton: {
    backgroundColor: primaryButtonColor,
    color: '#fff',
    width: 190,
    gap: 0,
  },

  roundedPrimaryButton: {
    width: 87,
    height: 87,
    marginInlineEnd: 8,
    marginBlockEnd: 8,
    verticalAlign: 'top',
    backgroundColor: primaryButtonColor,
    border: '1px dashed #d9d9d9',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'border-color 0.3s',
    alignSelf: 'baseline',
  },
  defaultDimension: {
    height: 40,
    width: 190,
  },
};
