import RequireAuth from '@/components/router/RequireAuth';
import MainLayout from '@/layouts/main';
import LoginPage from '@/pages/LoginPage';
import UsersPage from '@/pages/UsersPage';
import { FC, lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = lazy(() => import('@/layouts/AuthLayout/AuthLayout'));
// import RequireZone from '@/components/router/RequireZone';
import { withLoading } from '@/hocs/withLoading.hoc';
import CategoriesPage from '@/pages/CategoriesPage';
import CodeEditorPage from '@/pages/CodeEditorPage';

import HomePage from '@/pages/HomePage';
import MediaPage from '@/pages/MediaPage';

import ForgotPasswordPage from '@/pages/ForgotPasswordPage';
import NavigationPage from '@/pages/NavigationPage';
import PostPage from '@/pages/PostPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage';

import AllPagesPage from '@/pages/AllPagesPage';
import GeneralPage from '@/pages/Settings/General';

const Error404Page = lazy(() => import('@/pages/Error404Page'));
const Logout = lazy(() => import('./Logout'));
const Error404 = withLoading(Error404Page);
const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={''} element={protectedLayout}>
          <Route index element={<Navigate to="/auth/login" />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="edit-homepage" element={<HomePage />} />
          <Route path="404" element={<Error404 />} />
          <Route path="general" element={<GeneralPage />} />
          <Route path="page" element={<AllPagesPage />} />
          <Route path="media" element={<MediaPage />} />
          <Route path="categories" element={<CategoriesPage />} />
          <Route path="news" element={<PostPage />} />
          <Route path="menu" element={<NavigationPage />} />
          <Route path="code-editor" element={<CodeEditorPage />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
        </Route>
        <Route path="logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
