/**
 * Retrieves all carousel images from the '/news' endpoint.
 *
 */

import { IArticle, TaxonomyData } from '@/@types/news';
import { httpApi } from './http.service';

// Get news/taxonomy
export const getAllNewsTaxonomy = async () => {
  const res = await httpApi.get<{
    success: boolean;
    data: TaxonomyData[];
  }>('/categories');
  return res.data.data;
};

// Patch news/taxonomy
export const updateNewsTaxonomy = async (items: string[], pageID: string) => {
  const res = await httpApi.put<{
    success: boolean;
  }>(`/web-page/${pageID}`, {
    categoriesId: items,
  });
  console.log('ITEM PATCHED SUCCESSFULLY', items, res.data);
  return res.data;
};

// Get all articles
export const getAllArticles = async () => {
  const res = await httpApi.get<{
    success: boolean;
    data: IArticle[];
  }>('/articles');
  return res.data.data;
};

//get all categories
export const getAllCategories = async () => {
  const res = await httpApi.get<{
    success: boolean;
    data: TaxonomyData[];
  }>('/categories');
  return res.data.data;
};