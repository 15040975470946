import { WithChildrenProps } from '@/types/generalTypes.type';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import APPCONFIG from '@/config/app.config';

export const PageTitle: React.FC<WithChildrenProps> = ({ children }) => {
  return (
    <Helmet>
      <title>
        {children} | {APPCONFIG.siteName}{' '}
      </title>
    </Helmet>
  );
};
