import add from './icons/add.svg';
import arrow_bottom from './icons/arrow_bottom.svg';
import arrow_down from './icons/arrow_down.svg';
import arrow_right from './icons/arrow_right.svg';
import bell from './icons/bell.svg';
import config from './icons/config.svg';
import direct_download from './icons/direct-download.svg';
import file_text from './icons/file-text.svg';
import flags_uk from './icons/flags/uk.svg';
import flags_vietnam from './icons/flags/vietnam.svg';
import icon_delete from './icons/icon-delete.svg';
import icon_check_update from './icons/icon_check_update.svg';
import icon_download from './icons/icon_download.svg';
import icon_plus_blue from './icons/icon_plus_blue.svg';
import icon_search from './icons/icon_search.svg';
import icon_setting from './icons/icon_setting.svg';
import layers from './icons/layers.svg';
import login from './icons/login.svg';
import logout from './icons/logout.svg';
import pencil2 from './icons/pencil2.svg';
import search from './icons/search.svg';
import setting from './icons/setting.svg';
import side_menu_uk from './icons/side_menu_uk.svg';
import side_menu_vietnam from './icons/side_menu_vietnam.svg';
import timer from './icons/timer.svg';
import user_total from './icons/user_total.svg';
import view from './icons/view.svg';
import xml from './icons/xml.svg'
      
const icons = {
  add,arrow_bottom,arrow_down,arrow_right,bell,config,direct_download,file_text,flags_uk,flags_vietnam,icon_delete,icon_check_update,icon_download,icon_plus_blue,icon_search,icon_setting,layers,login,logout,pencil2,search,setting,side_menu_uk,side_menu_vietnam,timer,user_total,view,xml
}
export default icons