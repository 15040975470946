import { Dates } from '@/utils/Dates';
import { LanguageType } from '@/types/common.type';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// const localLanguage = (localStorage.getItem('lng') as LanguageType) || 'en';

/**
 * Custom hook to manage the language of the application.
 * @returns An object with the current language and a function to change it.
 */
export const useLanguage = (): {
  language: LanguageType;
  setLanguage: (locale: LanguageType) => Promise<void>;
} => {
  // Get the i18n object from react-i18next
  const { i18n } = useTranslation();

  /**
   * Function to change the language of the application.
   * @param locale The new language to set.
   * @returns A promise that resolves when the language has been changed.
   */
  const handleChangeLanguage = useCallback(
    (locale: LanguageType): Promise<void> => {
      return new Promise(resolve => {
        // console.log('Changing language to:', locale);
        // Change the locale of the Dates object to the new language.
        Dates.setLocale(locale);
        // Save the new language in localStorage.
        localStorage.setItem('lng', locale);
        // Change the locale of dayjs to the new language.
        dayjs.locale(locale);
        // Change the language of the i18n object to the new language.
        i18n.changeLanguage(locale);
        // Resolve the promise.
        resolve();
      });
    },
    [i18n], // Memoize the function to avoid unnecessary re-renders.
  );

  const localLanguage = localStorage.getItem('lng') as LanguageType;
  useEffect(() => {
    localLanguage && handleChangeLanguage(localLanguage);
  }, [handleChangeLanguage]);

  return useMemo(
    () => ({
      language: i18n.language as LanguageType,
      setLanguage: handleChangeLanguage,
    }),
    [handleChangeLanguage, i18n.language],
  );
};
