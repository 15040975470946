export interface CustomSidebarGroup {
  key: string;
  label: string;
  href?: string;
  icon?: React.ReactNode;
  children?: SideBarItem[];
  labelString?: string;
}

type SideBarItem = {
  key: string;
  label: string;
  icon?: React.ReactNode;
  labelString?: string;
  href?: string;
};

export const generalItems: CustomSidebarGroup[] = [
  // {
  //   key: 'z1',
  //   label: 'dashboard',
  //   labelString: 'Dashboardpage',
  //   href: '/dashboard',
  //   icon: <i className="fa fa-home"></i>,
  // },
  {
    key: 'h1',
    label: 'home_page',
    labelString: 'Homepage',
    icon: <i className="fa fa-home"></i>,
    href: '/edit-homepage',
  },
  {
    key: 'u1',
    label: 'user_page',
    labelString: 'Users',
    icon: <i className="fa-thin fa-user" />,
    href: '/users',
  },
  {
    key: 'n1',
    label: 'media_page',
    labelString: 'Media',
    icon: <i className="fa-light fa-photo-film-music" />,
    href: '/media',
  },
  // {
  //   key: 'n1',
  //   label: 'custom_css',
  //   labelString: 'Custom CSS',
  //   icon: <i className="fa-light fa-photo-film-music" />,
  //   href: '/code-editor',
  // },

  // Custom CSS is set to be a children of mange_page
  {
    key: 'h1',
    label: 'page',
    labelString: 'page',
    icon: <i className="fa-light fa-photo-film-music" />,
    href: '/page',
    children: [
      {
        key: 'h1.1',
        label: 'custom_css',
        labelString: 'Custom CSS',
        href: '/code-editor',
      },
      {
        key: 'h1.2',
        label: 'manage_page',
        labelString: 'manage_page',
        href: '/page',
      }
    ],
  },
  // {
  //   key: 'h1',
  //   label: 'page',
  //   labelString: 'page',
  //   icon: <i className="fa-light fa-photo-film-music" />,
  //   href: '/page',
  // },
  {
    key: 't1',
    label: 'modules',
    labelString: 'Module',
    icon: <i className="fa-light fa-signs-post" />,
    children: [
      {
        key: 't1.1',
        label: 'categories',
        labelString: 'Categories',
        icon: <i className="fa-light fa-sign-post" />,
        href: '/categories',
      },
      {
        key: 't1.2',
        label: 'news',
        labelString: 'News',
        icon: <i className="fa-regular fa-tag" />,
        href: '/news',
      },
    ],
  },
  {
    key: 'z2',
    label: 'appearance',
    labelString: 'Appearance',
    icon: <i className="fa-light fa-sign-posts" />,
    children: [
      {
        key: 'z2.1',
        label: 'menu',
        labelString: 'Menu',
        icon: <i className="fa-light fa-sign-post" />,
        href: '/menu',
      },
    ],
  },
];

export const settingsItems: CustomSidebarGroup[] = [
  {
    key: 'k1',
    label: 'settings',
    labelString: 'Settings',
    icon: <i className="fa-light fa-signs-post" />,
    children: [
      {
        key: 'k1.1',
        label: 'general',
        labelString: 'general',
        icon: <i className="fa-sharp fa-thin fa-stethoscope" />,
        href: '/general',
      },
    ],
  },
];
