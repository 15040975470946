import React from 'react';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { ResetPasswordForm } from '@/components/auth/ResetPasswordForm/ResetPasswordForm';
const ResetPasswordPage: React.FC = () => {
  return (
    <>
      <PageTitle>Reset Password</PageTitle>
      <ResetPasswordForm />
    </>
  );
};

export default ResetPasswordPage;
