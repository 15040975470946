import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  login,
  signUp,
  resetPassword,
  verifySecurityCode,
  setNewPassword,
  logout,
} from '@/services/auth.service';
import {
  ResetPasswordRequest,
  LoginRequest,
  SignUpRequest,
  SecurityCodePayload,
  NewPasswordData,
} from '@/types/auth.type';
import { setUser } from '@/store/slices/userSlice';
import {
  deletePersistZone,
  deleteToken,
  deleteUser,
  persistToken,
  readToken,
  readUser,
} from '@/services/localStorage.service';
import { UserModel } from '@/types/userModel.type';
import { notificationController } from '@/components/controllers/notificationController';

export interface AuthSlice {
  token: string | null;
  user: UserModel | null;
}

const initialState: AuthSlice = {
  token: readToken(),
  user: readUser(),
};

export const doLogin = createAsyncThunk(
  'auth/doLogin',
  async (loginPayload: LoginRequest, { dispatch }) =>
    login(loginPayload)
      .then(res => {
        const { user, accessToken } = res.data;
        const userConvertData: Partial<UserModel> = user as Partial<UserModel>;
        dispatch(setUser(userConvertData));
        persistToken(accessToken); // save access token to local
        return accessToken;
      })
      .catch((errors: { message: string }) => {
        console.log(errors.message);
        notificationController.error(errors);
        // for (const key in errors.response.errors) {
        //   notificationController.error({ message: errors.response.errors[key] });
        // }
      }),
);

export const doSignUp = createAsyncThunk(
  'auth/doSignUp',
  async (signUpPayload: SignUpRequest) => signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) =>
    resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) =>
    verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk(
  'auth/doSetNewPassword',
  async (newPasswordData: NewPasswordData) => setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk(
  'auth/doLogout',
  async (_, { dispatch }) => {
    deleteToken();
    deleteUser();
    deletePersistZone();
    dispatch(setUser(null));
    await logout();
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload || '';
    });
    builder.addCase(doLogout.fulfilled, state => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
