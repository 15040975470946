import React, { FC } from 'react';
import { MenuFoldOutlined } from '@ant-design/icons';

import HeaderProfile from './HeaderProfile';

import { images } from '@/assets';
import { useLayoutContext } from '../layoutContext';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@/utils/utils';
import { readUser } from '@/services/localStorage.service';
import { useResponsive } from '@/hooks/useResponsive';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHooks';
import { selectedSidebar, setOpenSidebar } from '@/store/slices/sidebarSlice';
import './styles.scss';

const Header: FC = (): React.JSX.Element => {
  const { isDesktop } = useResponsive();
  const dispatch = useAppDispatch();
  const isOpenSidebar = useAppSelector(selectedSidebar);
  const { isSidebarOpen: isOpen } = useLayoutContext();
  const { t } = useTranslation();
  const userProfileFromStorage = readUser();

  if (!userProfileFromStorage) {
    return <></>;
  }
  const activeProfile = {
    name: userProfileFromStorage.username,
    role: userProfileFromStorage.email,
    profileIcon: images.dummy_logo,
  };

  // Hide/Show sidebar responsive
  const handleOpenMenu = () => {
    dispatch(setOpenSidebar(!isOpenSidebar));
  };

  return (
    <div className={`page-header ${isOpen ? '' : 'close_icon'}`}>
      <div className="header-wrapper">
          {!isDesktop ? <Button icon={<MenuFoldOutlined />} onClick={handleOpenMenu} /> : null}
        <HeaderProfile
          name={capitalize(activeProfile.name)}
          role={capitalize(t(activeProfile.role))}
          img={activeProfile.profileIcon}
        />
      </div>
    </div>
  );
};

export default Header;
