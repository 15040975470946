// import { SubmitButton } from './../../../layouts/AuthLayout/AuthLayout.styles';
import styled from 'styled-components';
import { FONT_SIZE, FONT_WEIGHT, media } from '@/styles/themes/constants';
// import { BaseButton } from '@/components/common/BaseButton/BaseButton';

export const SensorDescription = styled.div`
  margin-bottom: 1.875rem;
  color: var(--Color-Text-05, #8790a0);
  text-align: center;
  font-style: normal;
  line-height: 16px;
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};

  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xxs};
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
  }
`;

export const SubmitButtonn = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  padding-top: 30px;
`;
export const SubmitButtonLeft = styled.div`
  // font-size: ${FONT_SIZE.md};
  // font-weight: ${FONT_WEIGHT.semibold};
  // width: 100%;
  // margin-top: 1.125rem;
  // margin-bottom: 1rem;

  display: flex;
  width: 76.406px;
  height: 30px;
  padding: 10.188px 15.281px;
  justify-content: center;
  align-items: center;
  gap: 6.367px;
  margin-right: 12px;
  color: var(--Primary-Colors-02, #2252b8);
  font-size: 10.188px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.281px;
  border-radius: 3.184px;
  background: var(--Primary-Colors-09, #ecf1fe);
`;

export const SubmitButtonRight = styled.div`
  // font-size: ${FONT_SIZE.md};
  // font-weight: ${FONT_WEIGHT.semibold};
  // width: 100%;
  // margin-top: 1.125rem;
  // margin-bottom: 1rem;
  display: flex;
  width: 76.406px;
  height: 28.016px;
  padding: 10.188px;
  justify-content: center;
  align-items: center;
  gap: 6.367px;
  color: var(--Greys-Colors-09, #fff);
  font-size: 10.188px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.281px;
  border-radius: 3.184px;
  background: var(--Primary-Colors-03, #305ec1);

  // margin-left: 8px;
`;
