import { NotificationType } from '@/@types/notification';
import { strings } from '@/assets';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
export interface IAppState {
  visibleDrawer: boolean;
  drawerContent?: ReactNode;
  drawerBackIcon: boolean;
  drawerTitle: string | ReactNode | null;
  visibleModal: boolean;
  modalContent?: ReactNode;
  countNotification: number;
  notification: NotificationType.IResponsive | null;
}

const initialState = {
  visibleDrawer: false,
  countNotification: 0,
  drawerContent: undefined,
  drawerTitle: null,
  drawerBackIcon: false,
  visibleModal: false,
  modalContent: undefined,
  headerZone: [],
  dataZone: [],
  notification: null,
} as IAppState;

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showDrawer: state => {
      state.visibleDrawer = true;
    },
    hiddenDrawer: state => {
      state.drawerContent = null;
      state.visibleDrawer = false;
    },
    toggleDrawer: state => {
      state.visibleDrawer = !state.visibleDrawer;
    },
    setContentDrawer: (state, action: PayloadAction<{ title: string | ReactNode | null; content: ReactNode }>) => {
      state.drawerTitle = action.payload.title;
      state.drawerContent = action.payload.content;
      state.visibleDrawer = true;
    },
    setBackDrawerIcon: (state, action: PayloadAction<boolean>) => {
      state.drawerBackIcon = action.payload;
    },
    showNotificationDrawer: state => {
      state.visibleDrawer = true;
      state.drawerTitle = strings().notification;
    },
    showModal: state => {
      state.visibleModal = true;
    },
    hiddenModal: state => {
      state.modalContent = null;
      state.visibleModal = false;
    },
    toggleModal: state => {
      state.visibleModal = !state.visibleModal;
    },
    setContentModal: (state, action: PayloadAction<ReactNode>) => {
      state.modalContent = action.payload;
      state.visibleModal = true;
    },
    setNotificationCount: (state, action: PayloadAction<number>) => {
      state.countNotification = action.payload;
    },
    setNotification: (state, action: PayloadAction<NotificationType.IResponsive>) => {
      state.notification = action.payload;
    },
    clearNotification: state => {
      state.notification = { count: 0, rows: [], count_mark_read: 0 };
      state.visibleDrawer = false;
    },
  },
});

export const {
  showDrawer,
  hiddenDrawer,
  toggleDrawer,
  setContentDrawer,
  setBackDrawerIcon,
  setNotificationCount,
  setNotification,
  showModal,
  hiddenModal,
  toggleModal,
  setContentModal,
  showNotificationDrawer,
  clearNotification,
} = appSlice.actions;
export default appSlice.reducer;
