import { FDrawer } from '@/components/fcomponents';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHooks';
import { hiddenDrawer } from '@/store/slices/appSlice';
import { Modal } from 'antd';
import { Fragment } from 'react';
import './styles.scss';
import { TProps } from './type';

function FContent(props: TProps) {
  const {
    visibleDrawer,
    drawerContent,
    drawerBackIcon,
    drawerTitle: titleDrawer,
    visibleModal,
    modalContent,
  } = useAppSelector(state => state.app);
  const dispatch = useAppDispatch();
  const {
    title,
    children,
    renderPanelRight,
    configDrawer,
    drawerTitle,
    drawerWidth,

    height,
    width,
    placement,
  } = props;

  const onClose = () => {
    dispatch(hiddenDrawer());
  };

  return (
    <Fragment>
      <div className="f-main f-main-wrap bg-fContent">
        <div className="f-content">
          <div className="f-content__panel">
            {title ? (
              <h3 className="f-content__title not-italic font-semibold leading-[48px] text-ftextModal mr-5">
                {title}
              </h3>
            ) : null}
            {renderPanelRight?.()}
          </div>
          {children}
        </div>
      </div>
      <FDrawer
        drawerWidth={drawerWidth}
        drawerTitle={drawerTitle || titleDrawer}
        backIcon={drawerBackIcon}
        open={visibleDrawer}
        onClose={onClose}
        renderContent={drawerContent}
        placement={placement ?? 'bottom'}
        height={height ?? '100%'}
        width={width ?? '100%'}
        forceRender
        {...configDrawer}
      />
      <Modal title={false} footer={false} closable={false} open={visibleModal}>
        {modalContent}
      </Modal>
    </Fragment>
  );
}

export default FContent;
