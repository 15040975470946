import React, { FC } from 'react';
import './styles.scss';
import { Select, Typography } from 'antd';
import { CategoryData } from '@/@types/post';

interface NavTableProps {
  allNavigation: { label: string; value: string }[] | undefined;
  title: string;
  onUpdateNavConfigs: (id: string) => void;
  preSelectedValue?: CategoryData['_id'] | undefined;
}

const NavSelection: FC<NavTableProps> = ({
  allNavigation,
  title,
  onUpdateNavConfigs,
  preSelectedValue,
}): React.JSX.Element => {
  const handleChange = (value: string) => {
    onUpdateNavConfigs(value);
  };

  return (
    <div className="nav-table">
          <Typography.Title level={5}>{title}</Typography.Title>
    
          <Select
            style={{ width: '100%' }}
            placeholder="Please select navigation"
            options={allNavigation}
            onChange={handleChange}
            size="middle"
            // Somehow AntSelect requires a number? 
            defaultValue={preSelectedValue}
          />
    </div>
  );
};

export default NavSelection;
