import { strings } from '@/assets';
import { ApiConfig } from '@/config/api.config';
import Editor from '@monaco-editor/react';
import { Button, message } from 'antd';
import { useEffect, useState } from 'react';
import './style.scss';

function MonacoComponent() {
  const [data, setData] = useState<string | undefined>('');

  useEffect(() => {
    fetch('http://portal.fint.vn:5010/public/styles.css')
      .then(response => {
        return response.text();
      })
      .then(cssContent => {
        return setData(cssContent);
      })
      .catch((error: any) => message.error(error.message));
  }, []);

  const apiPost = ApiConfig.apiBaseUrl + '/config/css';

  const handleSave = () => {
    fetch(apiPost, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
      },
      body: data,
    })
      .then(response => {
        if (response.ok) {
          message.success(strings().CSS_content_saved_successfully);
        } else {
          message.error(strings().Failed_to_save_CSS_content);
        }
      })
      .catch((error: any) => message.error(error.message));
  };

  return (
    <div className="codeEditor-wrapper">
      <div className="pb-4">
        <Button
          style={{ backgroundColor: '#273c75' }}
          size="large"
          htmlType="button"
          type="primary"
          onClick={handleSave}
        >
          {strings().save}
        </Button>
      </div>
      <Editor
        height="70vh"
        defaultLanguage="css"
        defaultValue={data}
        onChange={value => {
          setData(value);
        }}
      />
    </div>
  );
}

export default MonacoComponent;
