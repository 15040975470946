import { useCallback, useState } from "react";

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
}

// Custom hook to copy text to clipboard
const useCopyToClipboard = (timeoutDuration: number = 1000) => {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  
  const copyToClipboard = useCallback(
    async (text: string) => {
      try {
        if ('clipboard' in navigator && window.isSecureContext) {
          await navigator.clipboard.writeText(text);
        } else {
          fallbackCopyTextToClipboard(text);
        }
        setCopied(true);
        setError(null);
        setTimeout(() => setCopied(false), timeoutDuration);
      } catch (err) {
        setError(err instanceof Error ? err : new Error("Failed to copy text"));
      }
    },
    [timeoutDuration]
  );

  return { copied, error, copyToClipboard };
};

export default useCopyToClipboard;