import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  isOpenSidebar: false,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setOpenSidebar: (state, action: PayloadAction<boolean>) => {
      state.isOpenSidebar = action.payload;
    },
  },
});

export const selectedSidebar = (state: RootState) => state.sidebar.isOpenSidebar;
export const { setOpenSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
