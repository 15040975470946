import { Drawer, DrawerProps } from 'antd';
import { FC, ReactNode } from 'react';
import './styles.scss';

interface FDrawerProps extends DrawerProps {
  renderContent: ReactNode;
  drawerTitle: ReactNode;
  drawerWidth?: number | undefined;
  backIcon?: boolean;
  handleBackIcon?: () => void;
  
  // Specify custom drawer height & width (with fallback value)
  height? : string | number;
  width? : string | number;
  placement? : 'left' | 'right' | 'top' | 'bottom' | undefined;
}

const FDrawer: FC<FDrawerProps> = props => {
  const { renderContent, drawerTitle, height, width, placement } = props;
  // const drawerTitle = (
  //   <div style={{ display: 'flex', alignItems: 'center' }}>
  //     {backIcon ? (
  //       <Button type="text" className="backBtn" onClick={handleBackIcon}>
  //         <img
  //           style={{ width: 20, height: 20, paddingRight: 10 }}
  //           src={backImage}
  //           alt="back"
  //           className="cursor-pointer"
  //         />
  //       </Button>
  //     ) : null}
  //     {props.drawerTitle ? <span>{props.drawerTitle}</span> : null}
  //   </div>
  // );

  const acceptLine = () => {
    if (!drawerTitle) {
      return 'f-drawer-no-line';
    } else return 'f-drawer';
  };

  return (
    <Drawer
      open={props.open}
      onClose={props.onClose}
      title={drawerTitle}
      className={`${acceptLine()} f-drawer-wrap`}
      placement={placement ?? 'bottom'}
      height={height ?? '100%'} 
      style={{borderRadius: 0}}
      width={width ?? '100%'}
      forceRender={props.forceRender}
      destroyOnClose
    >
      {renderContent}
    </Drawer>
  );
};

export default FDrawer;
