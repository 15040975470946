import { BannerConfigType, HomeConfigData, HomePageOutputType } from '@/@types/home-carousel';
import { MediaType } from '@/@types/media';
import { AxiosRequestConfig } from 'axios';
import { httpApi } from './http.service';

/**
 * Retrieves all carousel images from the '/home' endpoint.
 *
 */
export const getAllHomeConfig = async (): Promise<HomeConfigData> => {
  // Make a GET request to the '/media' endpoint
  const res = await httpApi.get<{
    success: boolean;
    data: HomeConfigData;
  }>('/pages/home-page');
  // Return the 'data' property from the response object, which contains the array of MediaType objects
  return res.data.data;
};

export const getAllHomeMediaWithParams = async (
  params?: AxiosRequestConfig['params'],
): Promise<MediaType> => {
  // Make a GET request to the '/home' endpoint
  const res = await httpApi.get<MediaType>('/pages/home-page', { params });
  return res.data;
};

export const updateMedia = async (items: string[], pageID: string) => {
  const res = await httpApi.put<{
    sliders: string[];
  }>(`/home/${pageID}`, {
    mediaIds: items,
  });
  console.log('MEDIA PATCHED SUCCESSFULLY', items, res.data);
  return res.data;
};

export const getBannerConfig = async (): Promise<BannerConfigType> => {
  const res = await httpApi.get<{
    success: boolean;
    data: HomeConfigData;
  }>('pages/home-page');
  return res.data.data.banner;
};

export const getConfigID = async () => {
  const res = await httpApi.get<{
    success: boolean;
    data: HomeConfigData;
  }>('pages/home-page');
  return res.data.data._id;
};

export const updateHomePageConfig = async (data: HomePageOutputType, pageID: string) => {
  // current ID: 66b2de3826a48081caed6320
  const res = await httpApi.put(`/web-page/${pageID}`, data);
  return res.data;
};
