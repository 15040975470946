import React, { FC } from 'react';
import './styles.scss';
import SidebarLogo from './SidebarLogo';
import SidebarContent from './SidebarContent';
import { useLayoutContext } from '../../layoutContext';

const Sidebar: FC = (): React.JSX.Element => {
  const { isSidebarOpen: isOpen } = useLayoutContext();

  return (
    <div
      className={`sidebar-wrapper ${isOpen ? '' : 'close_icon'}`}
      data-layout="stroke-svg"
    >
      <a href="#">
        <SidebarLogo />
      </a>
      <nav className="sidebar-main">
        <div id="sidebar-menu">
          <ul
            className="sidebar-links"
            id="simple-bar"
            data-simplebar="init"
            style={{ display: 'block' }}
          >
            <div className="simplebar-wrapper" style={{ margin: 0 }}>
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer" />
              </div>
              <div className="simplebar-mask">
                <div
                  className="simplebar-offset"
                  style={{ right: 0, bottom: 0 }}
                >
                  <div
                    className="simplebar-content-wrapper"
                    style={{ height: '100%', overflow: 'hidden scroll' }}
                  >
                    <div className="simplebar-content">
                      <SidebarContent />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="simplebar-placeholder"
                style={{ width: 'auto', height: 2154 }}
              />
            </div>
            <div
              className="simplebar-track simplebar-horizontal"
              style={{ visibility: 'hidden' }}
            >
              <div
                className="simplebar-scrollbar"
                style={{ width: 0, display: 'none' }}
              />
            </div>
            <div
              className="simplebar-track simplebar-vertical"
              style={{ visibility: 'visible' }}
            >
              <div
                className="simplebar-scrollbar"
                style={{
                  height: 25,
                  transform: 'translate3d(0px, 0px, 0px)',
                  display: 'block',
                }}
              />
            </div>
          </ul>
          <div className="right-arrow" id="right-arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-right"
            >
              <line x1={5} y1={12} x2={19} y2={12} />
              <polyline points="12 5 19 12 12 19" />
            </svg>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
