import { TaxonomyData } from '@/@types/news';
import { strings } from '@/assets';
import { getAllCategories } from '@/services/post.service';
import type { FormProps } from 'antd';
import { Button, Form, Input, Select } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
type FieldType = {
  category?: string;
  type: string;
};

interface IProps extends FormProps<FieldType> {
  onFinish: (values: FieldType) => void;
}

interface ICategoryOption {
  value: string;
  label: string;
}

function CreateCategoryLink(props: IProps) {
  const [categories, setCategories] = useState<ICategoryOption[]>([]);
  const { onFinish } = props;
  const [form] = Form.useForm();
  //reset form before submit
  const handleSubmit: (values: any) => void = values => {
    form.resetFields();
    onFinish({
      ...values,
      label: categories.find(category => category.value === values.category)
        ?.label,
    });
  };
  useEffect(() => {
    async function onLoad() {
      const data = await getAllCategories();
      const result = coverCategoriesToOptions(data);
      setCategories(result);
      // setCategories(articles);
    }
    onLoad();
  }, []);
  const onSelectArticle = (value: string) => {
    console.log(`selected ${value}`);
  };

  function coverCategoriesToOptions(ctgs: TaxonomyData[]): ICategoryOption[] {
    return ctgs.map(ctg => {
      return {
        value: ctg.slug,
        label: ctg.name,
      };
    });
  }
  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      autoComplete="off"
    >
      <Form.Item<FieldType> hidden name={'type'} initialValue={'category'}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item<FieldType>
        label={t('category')}
        name="category"
        rules={[{ required: true, message: t('please_input_category') }]}
      >
        <Select
          placeholder={t('select_a_option_and_change_input_text_above')}
          onChange={onSelectArticle}
          allowClear
          showSearch
          options={categories}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '')
              .toLowerCase()
              .localeCompare((optionB?.label ?? '').toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 24 }}>
        <Button type="primary" htmlType="submit" style={{backgroundColor: 'rgb(1,80,154)'}}>
          {strings().add}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CreateCategoryLink;
