import { FONT_SIZE, FONT_WEIGHT, media } from '@/styles/themes/constants';
import styled from 'styled-components';

export const LoginDescription = styled.div`
  margin-bottom: 1.875rem;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};

  @media only screen and ${media.xs} {
    margin-bottom: 1.5625rem;
    font-weight: ${FONT_WEIGHT.bold};
  }

  @media only screen and ${media.md} {
    margin-bottom: 1.75rem;
    font-weight: ${FONT_WEIGHT.regular};
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1.875rem;
  }
`;

export const RememberMeText = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: var(--Color-Text-03, #525d71);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const ForgotPasswordText = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: var(--primary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
