import MediaUploadGroup from '@/components/media/MediaUploadGroup';
import { getAllHomeConfig, getConfigID, updateMedia } from '@/services/home.service';
import { message, UploadFile } from 'antd';
import React, { useState } from 'react';

interface Iprop {
  onUploadFile: (result: any) => void;
  type?: 'muti' | 'single';
}

const UploadClientComponent: React.FC<Iprop> = props => {
  const { onUploadFile, type } = props;
  const [pendingUploadList, setPendingUploadList] = useState<UploadFile[]>([]);
  const [, setReRender] = useState(false);

  const [isShowDrawer, setIsShowDrawer] = useState(false);

  const getListOfSliders = async () => {
    const currentSliderImages = await getAllHomeConfig();

    const result: string[] = [];

    currentSliderImages.sliders.map(item => {
      if (item._id) {
        result.push(item._id);
      } else {
        result.push(item._id);
      }
    });

    return result;
  };

  const handleFileChange = async ({
    fileList,
    file,
  }: {
    fileList: UploadFile<any>[];
    file: UploadFile<any>;
  }) => {
    setPendingUploadList(fileList);
    const configID = await getConfigID();

    if (file.status === 'done') {
      const previousSliders = await getListOfSliders();
      await updateMedia(previousSliders, configID);
      setReRender(prev => !prev);
    } else if ( file.status === "error") {
      message.error(file.response.message);
    }
  };

  return (
    <div>
      <MediaUploadGroup
        gapValue={0}
        isShowDrawer={isShowDrawer}
        setIsShowDrawer={setIsShowDrawer}
        pendingUploadList={pendingUploadList}
        //@ts-expect-error fix later
        handleFileChange={handleFileChange}
        handleSubmitSelection={onUploadFile}
        custom={false}
        type={type}
      />
    </div>
  );
};

export default UploadClientComponent;
