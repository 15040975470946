import SelectableMediaGrid from '@/components/media/SelectExistingImageModal/SelectableMediaGrid';
import { Button, Drawer, message, Space } from 'antd';
import React, { FC, useState } from 'react';
import './styles.scss';

interface DrawerStylingProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placement: any;
  width: number | string;
  height: number | string;
}

interface SelectExistingImageModalProps {
  drawerProps: DrawerStylingProps;
  onProccessComplete: (imageID: number[]) => void;
  excludedMedia?: string[] | number[];
  isShowDrawer: boolean;
  setIsShowDrawer: (value: boolean) => void;
  type?: 'muti' | 'single';
}

const SelectExistingImageModal: FC<SelectExistingImageModalProps> = ({
  drawerProps,
  onProccessComplete,
  excludedMedia,
  isShowDrawer,
  setIsShowDrawer,
  type = 'muti',
}): React.JSX.Element => {
  const [reRender, setReRender] = useState(false);
  const [pendingSelectedImages, setPendingSelectedImages] = useState<any[]>([]);

  const handleOk = () => {
    if (pendingSelectedImages.length === 0) {
      message.info('Vui lòng chọn ít nhất 1 ảnh');
      return;
    }
    onProccessComplete(pendingSelectedImages);
    setIsShowDrawer(false);
    setReRender(!reRender);

    setPendingSelectedImages([]);
  };

  return (
    <div className="select-existing-image-modal">
      <Drawer
        open={isShowDrawer}
        onClose={() => setIsShowDrawer(false)}
        {...drawerProps}
        extra={
          <Space>
            <Button onClick={() => setIsShowDrawer(false)}>Cancel</Button>
            <Button type="primary" onClick={handleOk}>
              OK
            </Button>
          </Space>
        }
      >
        <SelectableMediaGrid
          reRender={reRender}
          pendingSelectedImages={pendingSelectedImages}
          setPendingSelectedImages={setPendingSelectedImages}
          type={type}
          excludedMedia={excludedMedia} // Exclude carousel media from selection
        />
      </Drawer>
    </div>
  );
};

export default SelectExistingImageModal;
