import { get } from 'lodash';
import { httpApi } from './http.service';
import { Page } from '@/@types/page';

export const getAllPage = async (): Promise<Page[]> => {
  const res = await httpApi.get<{
    total: number;
    data: Page[];
  }>('/introduce');
  return res.data.data;
};

export const getPageDetail = async (id: string) => {
  const res = await httpApi.get<Page>(`/introduce/${id}`);
  return res.data;
};

export const createPage = async (data: Page): Promise<Page> => {
  const formData = new FormData();
  formData.append('title', data.title);
  formData.append('content', data.content);
  if (data.title_en) {
    formData.append('title_en', data.title_en);
  }
  if (data.content_en) {
    formData.append('content_en', data.content_en);
  }

  const requestedData = getInitFormFromPage(data);

  const res = await httpApi.post<Page>(`/introduce`, requestedData, {
    headers: { 'Content-Type': 'application/json' },
  });

  return res.data;
};

export const updatePage = async (pageId: string, data: Page) => {
  const formData = new FormData();

  formData.append('title', data.title);
  formData.append('content', data.content);

  if (data.title_en) {
    formData.append('title_en', data.title_en);
  }
  if (data.content_en) {
    formData.append('content_en', data.content_en);
  }

  const requestedData = getInitFormFromPage(data);

  try {
    const res = await httpApi.put(`/introduce/${pageId}`, requestedData, {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log('🚀 ~ updatePost ~ res:', res.data);
    return res.data;
  } catch (error: any) {
    console.error(error?.message);
    throw error;
  }
};

export const getInitFormFromPage = (page: Page | undefined) => {
  return {
    id: get(page, 'id', ''),
    title: get(page, 'title', ''),
    title_en: get(page, 'title_en', ''),
    content: get(page, 'content', ''),
    content_en: get(page, 'content_en', ''),
    slug: get(page, 'slug', ''),
  };
};
export const deletePage = async (pageId: string): Promise<void> => {
  await httpApi.delete<void>(`/introduce/${pageId}`);
};
