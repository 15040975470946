import React, { FC } from 'react';
import './styles.scss';
import SidebarList from './SidebarList';
import { CustomSidebarGroup } from '../../sidebarNavigation';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { setOpenSidebar } from '@/store/slices/sidebarSlice';

interface SidebarGroupProps {
  title: string;
  itemList: CustomSidebarGroup[];
}

const SidebarGroup: FC<SidebarGroupProps> = ({
  title,
  itemList,
}): React.JSX.Element | null => {
  const { t } = useTranslation();
  if (!itemList) return null;
  const dispatch = useAppDispatch();

  return (
    <div className={`simplebar-content`} style={{ padding: 0 }}>
      <li className="sidebar-main-title">
        <div>
          <h6 className="lan-1">{t(title)}</h6>
        </div>
      </li>

      {itemList.map(item => {
        return (
          <SidebarList
            key={item?.key}
            labelString={(item as CustomSidebarGroup)?.labelString}
            label={
              <Link to={(item as CustomSidebarGroup)?.href as string}>
                {t((item as CustomSidebarGroup)?.label)}
              </Link>
            }
            link={item.href as string}
          >
            {item?.children?.map((child, index) => (
              <li key={index}>
                <Link to={child.href as string} onClick={() => dispatch(setOpenSidebar(false))}>
                  {t(child.label)}
                </Link>
              </li>
            ))}
          </SidebarList>
        );
      })}
    </div>
  );
};

export default SidebarGroup;
