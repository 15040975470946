import { CSSProperties } from 'react';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { iOS } from '../../utilities';
// import { Props as TreeItemProps } from '@/types/';
import { Props as TreeItemProps } from '@/@types/dnd';
import { TreeItem } from '../TreeItem';

interface Props extends TreeItemProps {
  id: UniqueIdentifier;
  onEdit: (nodeUniqueId: string) => void
}

const animateLayoutChanges: AnimateLayoutChanges = ({
  isSorting,
  wasDragging,
}) => (isSorting || wasDragging ? false : true);

export default function SortableTreeItem({
  id,
  onEdit,
  depth,
  ...props
}: Props) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    minWidth: 250,
  };

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      depth={depth}
      ghost={isDragging}
      disableSelection={iOS}
      disableInteraction={isSorting}
      onEdit={onEdit}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      {...props}
    />
  );
}
