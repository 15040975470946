import React, { FC, useEffect, useState } from 'react';
import './styles.scss';
import {
  Typography,
  Button,
  message,
  Divider,
  UploadFile,
  Upload,
  GetProp,
  UploadProps,
  Image,
  ConfigProvider,
  Row,
  Col,
  TabsProps,
  Tabs,
} from 'antd';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import {
  GeneralPatchBody,
  getAllGeneral,
  updateGeneral,
} from '@/services/general.service';
import { GeneralType } from '@/@types/general';
import { Content } from 'antd/es/layout/layout';
import { CONTENT_STYLE } from '@/styles/themes/constants';
import { Card } from 'antd/lib';
import { ApiConfig } from '@/config/api.config';
import { readToken } from '@/services/localStorage.service';
import { defaultStyle } from '@/utils/theme';
import SelectExistingImageModal from '@/components/media/SelectExistingImageModal';
import NavigationConfig from './NavigationConfig';
import { useTranslation } from 'react-i18next';
import InputBox from './InputBox';
interface BannerConfigProps {}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export type MediaIdentifier = 'logo' | 'sublogo' | 'logoFooterId';
export type MediaState = { [key in MediaIdentifier]: any };

// interface ExtendedUploadProps extends UploadProps {
//   beforeUpload?: ((file: RcFile, FileList: RcFile[]) => boolean | Promise<boolean>) | undefined
// }

const GeneralPage: FC<BannerConfigProps> = (): React.JSX.Element => {
  const [messageApi, contextHolder] = message.useMessage();
  const [generalData, setGeneralData] = useState<GeneralType | null>(null); // Changed to GeneralType | null
  const [reRender, setReRender] = useState(false);

  const { t } = useTranslation();

  // State used for image upload
  const [previewOpen, setPreviewOpen] = useState<MediaState>({
    logo: false,
    sublogo: false,
    logoFooterId: false,
  });

  const [fileList, setFileList] = useState<{
    [key in MediaIdentifier]: UploadFile[];
  }>({
    logo: [],
    sublogo: [],
    logoFooterId: [],
  });

  const [previewImage, setPreviewImage] = useState<MediaState>({
    logo: '',
    sublogo: '',
    logoFooterId: '',
  });

  // State for drawer
  const [isShowDrawer, setIsShowDrawer] = useState<MediaState>({
    logo: false,
    sublogo: false,
    logoFooterId: false,
  });

  // State for edit general config
  const [editGeneralConfig, setEditGeneralConfig] =
    useState<Partial<GeneralPatchBody> | null>(null);

  // State for Menu config
  // const [allMenu, setAllMenu] = useState();

  // fetch current config data
  useEffect(() => {
    const fetchConfigData = async () => {
      const data = await getAllGeneral();
      setGeneralData(data);
    };

    fetchConfigData();
  }, [reRender]);

  const success = (message: string) => {
    messageApi.open({
      type: 'success',
      content: message,
    });
  };

  // const error = () => {
  //   messageApi.open({
  //     type: 'error',
  //     content: 'This is an error message',
  //   });
  // };

  const handleInputChange = (value: string, identifier: keyof GeneralType) => {
    const formattedValue =
      identifier === 'slogan' ? value.replace(/\n/g, '<br/>') : value;
    identifier === 'sloganFooter' ? value.replace(/\n/g, '<br/>') : value;

    setGeneralData(prevConfig => ({
      ...prevConfig!,
      [identifier]: value,
    }));

    setEditGeneralConfig(prevConfig => ({
      ...prevConfig!,
      [identifier]: formattedValue,
    }));
  };

  const handleSubmitConfig = () => {
    try {
      // Handle update config by using currentConfig
      if (editGeneralConfig) {
        console.log(
          '🪳 ~ file: index.tsx:123 ~ handleSubmitConfig ~ editGeneralConfig||',
          editGeneralConfig,
        );

        updateGeneral(editGeneralConfig);
      } else {
        throw new Error('Config data is undefined');
      }
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: `Error: ${error}`,
      });
    } finally {
      success(t('Config_Applied_Successfully'));
    }
  };

  // const handleSubmitMenuPositionConfig = () => {
  //   message.error('Chưa có logic cập nhật ');

  //   // [x] Update logic for Menu Position using new api endpoint
  // };

  // Since the new endpoint having diffrent data key, transformer function is needed
  const identifierTransformer = (identifier: MediaIdentifier) => {
    console.log('transforming', identifier);

    if (identifier === 'logo') {
      return 'logoId';
    } else if (identifier === 'logoFooterId') {
      return 'logoFooterId';
    } else {
      return 'subLogoId';
    }
  };

  const handleMediaUpload = async () => {
    // Handle media upload

    const identifiers: [MediaIdentifier, MediaIdentifier, MediaIdentifier] = [
      'logo',
      'sublogo',
      'logoFooterId',
    ];

    for (let identifier of identifiers) {
      const uploadingFileList = fileList[identifier];

      // if (identifier === 'logoFooterId') {
      //   identifier = ''
      // }

      console.log(
        '🪳 ~ file: index.tsx:112 ~ handleMediaUpload ~ uploadingFileList||',
        uploadingFileList,
      );

      if (uploadingFileList.length === 0) {
        continue;
      }

      const formData = new FormData();
      uploadingFileList.forEach(file => {
        formData.append('file', file.originFileObj as Blob);
      });

      fetch(uploadProps.action as string, {
        method: 'POST',
        headers: uploadProps.headers,
        body: formData,
      })
        .then(res => res.json())
        .then(data => {
          const postUploadImageID = data.id;

          console.log(
            '🪳 ~ file: index.tsx:178 ~ handleMediaUpload ~ postUploadImageID||',
            postUploadImageID,
          );

          // Since the new endpoint having diffrent data key, transformer function is needed
          const transformedIdentifier = identifierTransformer(identifier);

          // Handle selecting upload file
          updateGeneral({ [transformedIdentifier]: postUploadImageID });

          setFileList(prev => ({ ...prev, [identifier]: [] }));
        })
        .catch(eeee => {
          console.log(eeee);
          message.error(`Media upload failed at ${identifier}`);
        })
        .finally(() => {
          message.success(
            `${identifier.toUpperCase()} Is Uploaded Successfully`,
          );

          setReRender(prev => !prev);
        });
    }
  };

  const inputResponsiveSets = { xs: 24, sm: 12 };

  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });

  const handlePreview = async (
    file: UploadFile,
    identifier: MediaIdentifier,
  ) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(prev => ({
      ...prev,
      [identifier]: file.url || (file.preview as string),
    }));
    setPreviewOpen(prev => ({
      ...prev,
      [identifier]: true,
    }));

    console.log(`Previewing file for ${identifier}`);
  };

  const handleChange = (
    info: { fileList: UploadFile[] },
    identifier: MediaIdentifier,
  ) => {
    const { fileList: newFileList } = info;
    setFileList(prev => ({ ...prev, [identifier]: newFileList }));
  };

  const token = readToken();
  const uploadProps: UploadProps = {
    listType: 'picture-circle',

    action: ApiConfig.apiBaseUrl + '/medias',
    headers: {
      authorization: 'Bearer ' + token,
    },

    beforeUpload: () => false, // This prevent antComponent auto upload
    maxCount: 1,
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t('upload')}</div>
    </button>
  );

  const selectButton = (identifier: MediaIdentifier) => {
    // Width and height are left unused on purpose!
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { width, height, ...otherStyles } = defaultStyle.roundedPrimaryButton;

    const customStyles = {
      width: 78,
      height: 78,
      ...otherStyles,
    };

    return (
      <Button
        type="primary"
        size="large"
        onClick={() =>
          setIsShowDrawer(prevState => ({
            ...prevState,
            [identifier]: !prevState[identifier],
          }))
        }
        style={customStyles}
      >
        {t('select')}
      </Button>
    );
  };

  // This function is used for user selecting existing image
  const handleSubmitSelection = async (
    identifier: MediaIdentifier,
    imageID: number[],
  ) => {
    if (Array.isArray(imageID) && imageID.length > 1) {
      message.error(t('Multiple images are not allowed'));
      return;
    }

    const postSelectedImageID = imageID[0]; // Only take the first image
    const transformedIdentifier = identifierTransformer(identifier);

    // Logic for upload selected images
    updateGeneral({ [transformedIdentifier]: postSelectedImageID }).finally(
      () => {
        message.success(`Ảnh [ID:${imageID}] được lựa chọn thành công`);
        setReRender(!reRender);
      },
    );
  };

  const handleToggleDrawer = (identifier: MediaIdentifier) => {
    setIsShowDrawer(prevState => ({
      ...prevState,
      [identifier]: !prevState[identifier],
    }));
  };

  const imageProps: React.CSSProperties = {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  };

  const renderDrawer = (identifier: MediaIdentifier) => {
    return (
      <>
        <SelectExistingImageModal
          isShowDrawer={isShowDrawer[identifier]}
          setIsShowDrawer={() => handleToggleDrawer(identifier)}
          drawerProps={{
            title: `${t('select_existing_media')} ${t(identifier)}`,
            // title: `Select Media For The ${identifier.toUpperCase()}`,
            height: '85%',
            placement: 'bottom',
            width: 500,
          }}
          // onProccessComplete={(imageID: number) => {handleSubmitSelection(identifier, imageID);}}
          onProccessComplete={(imageID: number[]) =>
            handleSubmitSelection(identifier, imageID)
          }
        />

        <Button
          type="primary"
          icon={<CheckOutlined />}
          size="middle"
          style={{ marginTop: '20px' }}
          onClick={handleMediaUpload}
        >
          Apply
        </Button>
      </>
    );
  };

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'VI',
      children: (
        <>
          <Row gutter={16} wrap>
            <InputBox
              label={t('vietnamese_slogan')}
              identifier="slogan"
              inputValue={generalData?.slogan?.replace(/<br\/>/g, '\n') || ''}
              handleInputChange={handleInputChange}
              responsiveProps={inputResponsiveSets}
              isTextArea
            />
            <InputBox
              label={t('vietnamese_footer_slogan')}
              identifier="sloganFooter"
              inputValue={
                generalData?.sloganFooter?.replace(/<br\/>/g, '\n') || ''
              }
              handleInputChange={handleInputChange}
              responsiveProps={inputResponsiveSets}
              isTextArea
            />
          </Row>
          <Row gutter={16} wrap>
            <InputBox
              label={`Copyright ${t('vietnamese')}`}
              identifier="copyright"
              inputValue={
                generalData?.copyright?.replace(/<br\/>/g, '\n') || ''
              }
              handleInputChange={handleInputChange}
              responsiveProps={inputResponsiveSets}
              isTextArea
            />
            <InputBox
              label={`${t('address')} ${t('vietnamese')}`}
              identifier="address"
              inputValue={generalData?.address?.replace(/<br\/>/g, '\n') || ''}
              handleInputChange={handleInputChange}
              responsiveProps={inputResponsiveSets}
              isTextArea
            />
          </Row>
        </>
      ),
    },
    {
      key: '2',
      label: 'EN',
      children: (
        <>
          <Row gutter={16} wrap>
            <InputBox
              label={t('slogan')}
              identifier="slogan"
              inputValue={
                generalData?.slogan_en?.replace(/<br\/>/g, '\n') || ''
              }
              handleInputChange={handleInputChange}
              responsiveProps={inputResponsiveSets}
              isTextArea
            />
            <InputBox
              label={t('footer_slogan')}
              identifier="sloganFooter_en"
              inputValue={
                generalData?.sloganFooter_en?.replace(/<br\/>/g, '\n') || ''
              }
              handleInputChange={handleInputChange}
              responsiveProps={inputResponsiveSets}
              isTextArea
            />
          </Row>

          <Row gutter={16} wrap>
            <InputBox
              label="Copyright"
              inputValue={
                generalData?.copyright_en?.replace(/<br\/>/g, '\n') || ''
              }
              handleInputChange={handleInputChange}
              identifier="copyright_en"
              responsiveProps={inputResponsiveSets}
              isTextArea
            />
            <InputBox
              label={t('address')}
              inputValue={
                generalData?.address_en?.replace(/<br\/>/g, '\n') || ''
              }
              handleInputChange={handleInputChange}
              identifier="address_en"
              responsiveProps={inputResponsiveSets}
              isTextArea
            />
          </Row>
        </>
      ),
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Transfer: {
            listWidth: 500,
          },
        },
      }}
    >
      {contextHolder}
      <div className="banner-config">
        <Content style={CONTENT_STYLE}>
          <Row justify="space-around" gutter={[20, 20]}>
            <Col xxl={8} xl={6} >
              <Card hoverable style={{ width: 300 }}>
                <Typography.Title
                  level={5}
                  style={{
                    textTransform: 'uppercase',
                    marginBottom: 10,
                    letterSpacing: 5,
                  }}
                >
                  Logo
                </Typography.Title>
                <div className="image-holder">
                  <Image
                    src={generalData?.logo}
                    alt="logo-config"
                    style={imageProps}
                  />
                </div>
                <div style={{ alignSelf: 'flex-start', marginBottom: 10 }}>
                  <Upload
                    {...uploadProps}
                    fileList={fileList.logo}
                    onPreview={file => handlePreview(file, 'logo')}
                    onChange={info => handleChange(info, 'logo')}
                    style={{ alignSelf: 'flex-start' }}
                  >
                    {fileList['logo'].length >= 2 ? null : uploadButton}
                  </Upload>
                </div>
                {previewImage.logo && (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen.logo,
                      onVisibleChange: visible =>
                        setPreviewOpen(prev => ({ ...prev, logo: visible })),
                      afterOpenChange: visible =>
                        !visible &&
                        setPreviewImage(prev => ({ ...prev, logo: '' })),
                    }}
                    src={previewImage['logo']}
                  />
                )}
                {selectButton('logo')}
              </Card>
            </Col>

            <Col xxl={8} xl={6} >
              <Card hoverable style={{ width: 300 }}>
                <Typography.Title
                  level={5}
                  style={{
                    textTransform: 'uppercase',
                    marginBottom: 10,
                    letterSpacing: 5,
                  }}
                >
                  {t('footer_logo')}
                </Typography.Title>
                <div className="image-holder">
                  <Image
                    src={generalData?.logoFooter}
                    alt="logo-config"
                    style={imageProps}
                  />
                </div>
                <div style={{ alignSelf: 'flex-start', marginBottom: 10 }}>
                  <Upload
                    {...uploadProps}
                    fileList={fileList.logoFooterId}
                    onPreview={file => handlePreview(file, 'logoFooterId')}
                    onChange={info => handleChange(info, 'logoFooterId')}
                    style={{ alignSelf: 'flex-start' }}
                  >
                    {fileList['logoFooterId'].length >= 2 ? null : uploadButton}
                  </Upload>
                </div>
                {previewImage.logoFooterId && (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen.logoFooterId,
                      onVisibleChange: visible =>
                        setPreviewOpen(prev => ({
                          ...prev,
                          logoFooterId: visible,
                        })),
                      afterOpenChange: visible =>
                        !visible &&
                        setPreviewImage(prev => ({
                          ...prev,
                          logoFooterId: '',
                        })),
                    }}
                    src={previewImage['logoFooterId']}
                  />
                )}
                {selectButton('logoFooterId')}
              </Card>
            </Col>

            <Col xxl={8} xl={12} >
              <Card hoverable style={{ width: 300 }}>
                <Typography.Title
                  level={5}
                  style={{
                    textTransform: 'uppercase',
                    marginBottom: 10,
                    letterSpacing: 3,
                  }}
                >
                  {t('sublogo')}
                </Typography.Title>
                <div className="image-holder">
                  <Image
                    src={generalData?.sublogo}
                    alt="sublogo-config"
                    style={imageProps}
                  />
                </div>
                <div style={{ alignSelf: 'flex-start', marginBottom: 10 }}>
                  <Upload
                    {...uploadProps}
                    fileList={fileList.sublogo}
                    onPreview={file => handlePreview(file, 'sublogo')}
                    onChange={info => handleChange(info, 'sublogo')}
                  >
                    {fileList['sublogo'].length >= 2 ? null : uploadButton}
                  </Upload>
                </div>
                {previewImage.sublogo && (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen.sublogo,
                      onVisibleChange: visible =>
                        setPreviewOpen(prev => ({ ...prev, sublogo: visible })),
                      afterOpenChange: visible =>
                        !visible &&
                        setPreviewImage(prev => ({ ...prev, sublogo: '' })),
                    }}
                    src={previewImage['sublogo']}
                  />
                )}
                {selectButton('sublogo')}
              </Card>
            </Col>
          </Row>
          {isShowDrawer.logo && renderDrawer('logo')}
          {isShowDrawer.sublogo && renderDrawer('sublogo')}
          {isShowDrawer.logoFooterId && renderDrawer('logoFooterId')}
          <Button
            type="primary"
            icon={<CheckOutlined />}
            size="middle"
            style={{ marginTop: '20px' }}
            onClick={handleMediaUpload}
          >
            {t('Update Image Config')}
          </Button>
          <Divider>{t('General Info')}</Divider>

          {/* General Info Configs */}
          <Row gutter={16} wrap>
            <InputBox
              label={t('phone')}
              handleInputChange={handleInputChange}
              responsiveProps={inputResponsiveSets}
              identifier="phone"
              inputValue={generalData?.phone || ''}
            />

            <InputBox
              label={t('email')}
              handleInputChange={handleInputChange}
              responsiveProps={inputResponsiveSets}
              identifier="email"
              inputValue={generalData?.email || ''}
            />

            <InputBox
              label={t('contact_email')}
              handleInputChange={handleInputChange}
              responsiveProps={inputResponsiveSets}
              identifier="contact"
              inputValue={generalData?.contact || ''}
            />

            <InputBox
              label={'Facebook'}
              handleInputChange={handleInputChange}
              responsiveProps={inputResponsiveSets}
              identifier="facebook"
              inputValue={generalData?.facebook || ''}
            />
          </Row>

          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />

          <Button
            type="primary"
            icon={<CheckOutlined />}
            size="middle"
            style={{ marginTop: '20px' }}
            onClick={handleSubmitConfig}
          >
            {t('Update General Config')}
          </Button>
          <Divider>{t('Navigation Config')}</Divider>
          {generalData ? (
            <Row justify="space-between">
              <Col xl={12} xs={24} >
                <NavigationConfig
                  activeNavigationConfig={generalData.navigation}
                />
              </Col>
            </Row>
          ) : (
            <p>Loading Navigation Config...</p>
          )}
        </Content>
      </div>
    </ConfigProvider>
  );
};

export default GeneralPage;
