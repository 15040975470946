import { MediaType, MediaTypeByID } from '@/@types/media';
import { httpApi } from '@/services/http.service';
import { AxiosRequestConfig } from 'axios';

// [ ] Refactor: Create a 'getAllMedia' function which is more versatile and flexible
/**
 * Retrieves all carousel images from the '/medias' endpoint.
 *
 */
export const getAllMedia = async (): Promise<MediaType[]> => {
  // Make a GET request to the '/medias' endpoint
  const res = await httpApi.get<{
    // The response object should have a 'success' property set to true
    success: boolean;
    // The response object should have a 'data' property containing an array of MediaType objects
    data: MediaType[];
  }>('/medias');
  // Return the 'data' property from the response object, which contains the array of MediaType objects
  return res.data.data;
};

export const getAllMediaWithParams = async (
  params?: AxiosRequestConfig['params'],
): Promise<MediaType> => {
  // Make a GET request to the '/medias' endpoint
  const res = await httpApi.get<MediaType>('/medias', { params });
  return res.data;
};

export const getMediaDetailByID = async (id: number | string): Promise<MediaTypeByID> => {
  const res = await httpApi.get<MediaTypeByID>(`/medias/${id}`);
  return res.data;
};

export const getMedia = async (id: number): Promise<MediaType> => {
  const res = await httpApi.get<MediaType>(`/medias/${id}`);
  return res.data;
};

export const createMedia = async (data: MediaType): Promise<MediaType> => {
  const res = await httpApi.post<MediaType>(`/medias`, data);
  return res.data;
};

export const deleteMedia = async (mediaId: number): Promise<void> => {
  await httpApi.delete<void>(`/medias/${mediaId}`);
};
