import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Empty } from 'antd';
import React, { FC } from 'react';
import './styles.scss';

import { TaxonomyData } from '@/@types/news';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import SortableItems from './SortableItems';

interface TargetBoxProps {
  widgetsCard: string[];
  setWidgetsCard: React.Dispatch<React.SetStateAction<string[]>>;
  onTargetBox: boolean;
  onDrop: (event: React.DragEvent) => void;
  onDragOver: (event: React.DragEvent) => void;
  allTaxonomy: TaxonomyData[] | undefined;
  language: 'en' | 'vi';
}

const TargetBox: FC<TargetBoxProps> = ({
  widgetsCard,
  setWidgetsCard,
  onTargetBox,
  onDrop,
  onDragOver,
  allTaxonomy,
  language
}): React.JSX.Element => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8, // Respond to a click on draggables
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const taxonomyContent = widgetsCard
    ?.map((id: string) => allTaxonomy?.find(item => item._id === id))
    .filter((item: TaxonomyData | undefined) => item !== undefined);

  // useEffect(() => {
  //   const updateTaxonomyArray = async () => {
  //     await updateHomePage(widgetsCard);
  //   };

  //   updateTaxonomyArray();
  // }, [widgetsCard]);

  if (taxonomyContent?.length === 0) {
    return (
      <div
        className={`target-box ${onTargetBox ? 'active' : ''} empty`}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <Empty description="Drag a Taxonomy here" />
      </div>
    );
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over?.id) {
      setWidgetsCard(widgetsCard => {
        const oldIndex = widgetsCard.indexOf(active.id.toString());
        const newIndex = widgetsCard.indexOf(over.id.toString());

        return arrayMove(widgetsCard, oldIndex, newIndex);
      });
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <div
        className={`target-box ${onTargetBox ? 'active' : ''} `}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <SortableContext
          items={widgetsCard}
          strategy={verticalListSortingStrategy}
        >
          {taxonomyContent ? (
            taxonomyContent.map((item, index) => {
              if (!item) return null;
              return (
                <SortableItems
                  index={index}
                  key={item._id}
                  id={item._id}
                  title={language === 'vi' ? item.name : item.title_en}
                  setWidgetsCard={setWidgetsCard}
                />
              );
            })
          ) : (
            <p>Loading</p>
          )}
        </SortableContext>
      </div>
    </DndContext>
  );
};

export default TargetBox;
