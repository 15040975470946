import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseForm } from '@/components/common/forms/BaseForm/BaseForm';
import * as S from './ForgotPasswordForm.styles';
import * as Auth from '@/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { doResetPassword } from '@/store/slices/authSlice';
import { notification, Modal, Form } from 'antd';

interface ForgotPasswordFormData {
  email: string;
}

export const ForgotPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);
    dispatch(doResetPassword(values))
      .unwrap()
      .then(() => {
        setLoading(false);
        Modal.success({
          title: 'Email khôi phục mật khẩu đã được gửi',
          content: 'Vui lòng kiểm tra email của bạn để đổi mật khẩu.',
        });
      })
      .catch(err => {
        notification.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
      >
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          Quay lại
        </Auth.BackWrapper>
        <Auth.FormTitle>Khôi phục mật khẩu</Auth.FormTitle>
        <Auth.FormItem
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Vui lòng nhập email" },
            { type: 'email', message: "Kiểm tra email" },
          ]}
        >
          <Auth.FormInput placeholder="abc@gmail.com" />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButtonn>
            <S.SubmitButtonLeft
              type="primary"
              htmlType="button"
              onClick={() => navigate(-1)}
            >
              Quay lại
            </S.SubmitButtonLeft>
            <S.SubmitButtonRight
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Gửi
            </S.SubmitButtonRight>
          </S.SubmitButtonn>
        </BaseForm.Item>
      </Form>
    </Auth.FormWrapper>
  );
};
