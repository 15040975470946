import { TaxonomyData } from '@/@types/news';
import { Card } from 'antd';
import React, { FC } from 'react';
import './styles.scss';

interface TaxonomyListProps {
  handleOnDrag: (event: React.DragEvent, widgetItemID: string) => void;
  handleOnDrop: (event: React.DragEvent) => void;
  allTaxonomy: TaxonomyData[] | undefined;
  widgetsCard: string[];
  language: 'en' | 'vi';
}

const TaxonomyList: FC<TaxonomyListProps> = ({
  allTaxonomy,
  handleOnDrag,
  handleOnDrop,
  widgetsCard,
  language,
}): React.JSX.Element => {
  const taxonomyWithoutSelected: TaxonomyData[] | [] =
    allTaxonomy?.filter(item => !widgetsCard.includes(item._id)) ?? [];

  return (
    <div className="taxonomy-list">
      {taxonomyWithoutSelected?.map(item => (
        <Card
          size="small"
          key={item._id}
          draggable
          onDragStart={event => handleOnDrag(event, item._id)}
          onDrop={event => handleOnDrop(event)}
        >
          <p>{language === 'vi' ? item.name : item.title_en}</p>
        </Card>
      ))}
    </div>
  );
};

export default TaxonomyList;
