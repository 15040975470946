import React, { FC } from 'react';
import Sidebar from './Sidebar';
import Footer from '../Footer';
import PageTitle from './PageTitle';
import { Outlet, useLocation } from 'react-router-dom';
import { pageTitles } from '@/constants/page.common';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useAppSelector } from '@/hooks/reduxHooks';
import { selectedSidebar } from '@/store/slices/sidebarSlice';
// import SidebarOpen from './SidebarOpen';
// import { Content } from 'antd/es/layout/layout';
// import { CONTENT_STYLE } from '@/styles/themes/constants';

interface MainContentProps {}

const MainContent: FC<MainContentProps> = (): React.JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();

  const isSidebarVisible = useAppSelector(selectedSidebar);

  const currentPath = location.pathname;
  const currentPage: string = t(
    pageTitles[currentPath as keyof typeof pageTitles] || '',
  );

  return (
    <div className="main-content__wrapper">
      {/* Completely different sidebar just for responsive purpose */}
      {/* <SidebarOpen isOpen={isOpenSidebar} /> */}
      <Row >
        
        {isSidebarVisible ? (
          <Col xs={3}>
            <div className="page-body-wrapper">
              <Sidebar />
            </div>
          </Col>
        ) : (
          <Col xs={0} xxl={3} xl={4}>
            <div className="page-body-wrapper">
              <Sidebar />
            </div>
          </Col>
        )}

        <Col xs={24} xxl={21} xl={20}>
          <div className="page-body">
            <div className="container-fluid">
              <PageTitle
                currentPage={currentPage}
                breadcrumb={{ title: currentPage, subtitle: t('default') }}
              />
            </div>
            <div className="container-fluid">
              <Outlet />
            </div>
          </div>
          <Footer>Copyright 2024 © FintSoft</Footer>
        </Col>
      </Row>
    </div>
  );
};

export default MainContent;
