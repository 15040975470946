import { ArticleData, ArticleType } from '@/@types/post';
import { strings } from '@/assets';
import { BaseButton } from '@/components/common/BaseButton/BaseButton';
import { FActions, FModal, FTable } from '@/components/fcomponents';
import { useAppDispatch } from '@/hooks/reduxHooks';
import FContent from '@/layouts/main/FContent';
import {
  deletePost,
  getAllArticle,
  getArticleDetail,
  getInitFormFromPost,
} from '@/services/post.service';
import {
  // hiddenDrawer,
  hiddenModal,
  setContentDrawer,
  setContentModal,
} from '@/store/slices/appSlice';
import { Flex, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { useEffect, useRef, useState } from 'react';
import FrmPost from './FrmPost';
// import { notificationController } from '@/components/controllers/notificationController';
// import { AxiosError } from 'axios';
import { formatDate } from '@/utils';

import { CONTENT_STYLE } from '@/styles/themes/constants';
import { Content } from 'antd/es/layout/layout';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

// const { Title } = Typography;
const PostPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState<ArticleType[]>([]);
  const selectedUserRef = useRef<ArticleData | null>(null);
  const formTypeRef = useRef('create');
  const [resultPosts, setResultPosts] = useState<ArticleType[]>([]);
  const dispatch = useAppDispatch();

  const { t } = useTranslation('translation');

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const columns: ColumnsType<ArticleType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 50,
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: t('title'),
      dataIndex: ['title'],
      width: 200,
      render: (text, record) => (
        <span
          className="semibold cursor-pointer"
          onClick={() => handleClickRow(record)}
        >
          {text}
        </span>
      ),
    },
    {
      title: t('excerpt'),
      dataIndex: ['excerpt'],
      width: 200,
      render: record => {
        return <div dangerouslySetInnerHTML={{ __html: record }} />;
      },
    },

    {
      title: t('created_date'),
      dataIndex: ['updatedAt'],
      key: 'updatedAt',
      width: 120,
      align: 'start',
      render: date => (
        <span className="cursor-pointer">{formatDate(date)}</span>
      ),
    },
    {
      title: t('categories'),
      dataIndex: ['categories'],
      // key: 'categoriesId',
      width: 100,
      align: 'start',
      render: categories =>
        categories.map((item: any) => {
          const randomColorPicker = () => {
            const colorArray = ['red', 'blue', 'green', 'orange', 'pink'];

            return colorArray[Math.floor(Math.random() * colorArray.length)];
          };

          return (
            <Tag
              key={item._id}
              color={randomColorPicker()}
              style={{ marginBlock: 5 }}
            >
              {item.name}
            </Tag>
          );
        }),
    },
    {
      title: t('action'),
      dataIndex: 'action',
      align: 'center',
      width: 100,
      render(_, record) {
        return (
          <Space>
            <FActions
              id={record._id}
              showEdit={false}
              onClickRemove={() => {
                handleClickRemove(record as ArticleType);
                setTimeout(() => {
                  fetchData();
                }, 1000);
              }}
              showPlus={false}
            />
          </Space>
        );
      },
    },
  ];

  async function handleClickRow(record: ArticleType) {
    const res = await getArticleDetail(record.id);
    // Get specific post detail
    handleShowDrawer('edit', res);
  }

  const handleOnSearch = debounce((values: string) => {
    const filteredPosts = posts.filter(post =>
      post.title.toLowerCase().includes(values.toLowerCase()),
    );
    setResultPosts(filteredPosts);
  }, 500);

  const fetchData = async (): Promise<void> => {
    try {
      setLoading(true);
      const news = await getAllArticle();
      setPosts(news);
      setResultPosts(news);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickRemove = (record: ArticleType) => {
    dispatch(
      setContentModal(
        <FModal
          title={t('delete_news')}
          description={t('youre_deleting_an_new_this_action_cannot_be_undone')}
          onOk={() => handleDeletePost(record)}
        />,
      ),
    );
  };

  const handleDeletePost = async (record: ArticleType) => {
    try {
      await deletePost(record._id);

      setResultPosts(posts.filter(post => post._id !== record._id));
      dispatch(setContentModal(null));
      dispatch(hiddenModal());
    } catch (error) {
      console.error('Error deleting report:', error);
    }
  };
  const handleFormSubmitFailed = (errorInfo: unknown) => {
    console.log(
      '🚀 ~ file: index.tsx:34 ~ handleFormSubmitFailed ~ errorInfo:',
      errorInfo,
    );
  };

  function handleShowDrawer(
    stt: 'create' | 'edit' | 'view',
    record: ArticleData | null = null,
  ) {
    selectedUserRef.current = record;
    formTypeRef.current = stt;
    const commonProps = {
      // onFinish: handleFormSubmit,
      onFinishFailed: handleFormSubmitFailed,
      initialValues: getInitFormFromPost(record?.data),
    };

    let title = '';
    let edit = false;
    let buttonOkText = '';

    switch (stt) {
      case 'create':
        title = `${t('create_new')}`;
        edit = false;
        buttonOkText = strings().create;
        break;
      case 'edit':
        title = `${t('edit')}`;
        edit = true;
        buttonOkText = strings().edit;
        break;
      case 'view':
        title = `${t('view')} `;
        edit = false;
        buttonOkText = '';
        break;
      default:
        break;
    }

    dispatch(
      setContentDrawer({
        content: (
          <FrmPost
            {...commonProps}
            edit={edit}
            buttonOkText={buttonOkText}
            onClickEvent={() => {
              setTimeout(() => {
                fetchData();
              }, 1000);
            }}
          />
        ),
        title: title,
      }),
    );
  }
  function renderHeaderRight() {
    return (
      <Flex vertical={false} gap={20} align="center" justify="flex-start">
        <BaseButton
          type="primary"
          className="capitalize add-button"
          icon={<i className="fa-light fa-circle-plus" />}
          onClick={() => handleShowDrawer('create')}
        >
          {t('add')}
        </BaseButton>
      </Flex>
    );
  }

  return (
    <div className="news-page">
      <Content style={CONTENT_STYLE}>
        <FContent renderPanelRight={renderHeaderRight}>
          <FTable
            useSearch
            onSearch={handleOnSearch}
            title="All News"
            data={resultPosts}
            tblProps={{
              rowKey: 'id',
              loading,
              columns,
              expandable: {},
            }}
          />
        </FContent>
      </Content>
    </div>
  );
};

export default PostPage;
