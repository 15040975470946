import { UserType } from '@/@types/user';
import { httpApi } from '@/services/http.service';
import { get } from 'lodash';

export const getAllUser = async (): Promise<UserType[]> => {
  const res = await httpApi.get<{
    success: boolean;
    data: UserType[];
  }>('/users');
  return res.data.data;
};

export const getUser = async (id: number) => {
  const res = await httpApi.get<UserType>(`/users/${id}`);
  return res;
};

export const createUser = async (data: UserType) => {
  const res = await httpApi.post<UserType>('/users', data);
  return res.data;
};

export const updateUser = async (userId: number, data: UserType) => {
  const res = await httpApi.put<UserType>(`/users/${userId}`, data);
  return res;
};

export const deleteUser = async (userId: number): Promise<void> => {
  await httpApi.delete<void>(`/users/${userId}`);
};

export const deleteMultiUser = async (ids: number[]) => {
  // const res = await httpApi.delete<UserType.IUser>(`_api/v3/user`, { ids });
  ids.forEach(async id => {
    await deleteUser(id);
  });
};
export const getInitFormFromUser = (user: UserType | null) => ({
  username: get(user, 'username', ''),
  password: '',
  email: get(user, 'email', ''),
});
export const makeDataUserRequest = (
  data: UserType & Record<string, number | string>,
): UserType => ({
  id: data.id,
  username: data.username,
  email: data.email,
  password: `${data.password}`,
  _id: data._id,
});

export const makeDataUserEditRequest = (
  data: UserType & Record<string, number | string>,
): UserType => {
  const userEditData: UserType = {
    id: data.id,
    username: data.username,
    email: data.email,
    _id: data._id,
  };
  if (data.password) {
    userEditData.password = `${data.password}`;
  }
  return userEditData;
};
