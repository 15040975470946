import { icons } from '@/assets';
import { Button } from 'antd';
import { ReactSVG } from 'react-svg';
import './styles.scss';

type IProps = {
  id: string;
  showPlus?: boolean;
  onClickPlus?: () => void;
  showEdit?: boolean;
  onClickEdit?: () => void;
  showRemove?: boolean;
  onClickRemove?: () => void;
  showDownload?: boolean;
  onClickDownload?: () => void;
};

function FActions(props: IProps) {
  const {
    showPlus = true,
    showEdit = true,
    showRemove = true,
    showDownload = false,
    onClickEdit,
    onClickPlus,
    onClickRemove,
    onClickDownload,
  } = props;

  return (
    <div className="f-actions f-actions-wrap">
      {showPlus ? (
        <Button onClick={onClickPlus} className="f-action-button bg-fBgAction">
          <ReactSVG src={icons.add} wrapper="span" className="f-icon" />
        </Button>
      ) : null}
      {showEdit ? (
        <Button
          onClick={onClickEdit}
          className="f-action-button bg-fBgAction mx-[8px]"
        >
          <ReactSVG src={icons.pencil2} wrapper="span" className="f-icon" />
        </Button>
      ) : null}
      {showDownload ? (
        <Button
          className="f-action-button bg-fBgAction border-none"
          onClick={onClickDownload}
        >
          <ReactSVG
            src={icons.direct_download}
            wrapper="span"
            className="f-icon"
          />
        </Button>
      ) : null}
      {showRemove ? (
        // <Popconfirm
        //   title="Delete this item?"
        //   description="Are you sure to delete this item?"
        //   onConfirm={onClickRemove}
        //   okButtonProps={{ danger: true }}
        // >
        <Button
          className="f-action-button bg-fBgAction border-none"
          onClick={onClickRemove}
        >
          <ReactSVG src={icons.icon_delete} wrapper="span" className="f-icon" />
        </Button>
      ) : // </Popconfirm>
      null}
    </div>
  );
}

export default FActions;
