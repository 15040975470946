import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BaseForm } from '@/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { doSetNewPassword } from '@/store/slices/authSlice';
import { Form, notification } from 'antd'; // Import notification từ antd
import * as S from './ResetPasswordForm.styles';
import * as Auth from '@/layouts/AuthLayout/AuthLayout.styles';

interface NewPasswordFormData {
  password: string;
}

const initStates = {
  confirmPassword: 'new-password',
};

export const ResetPasswordForm: React.FC = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const username = query.get('username');
  const key = query.get('key');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const openNotification = () => {
    notification.success({
      message: 'Thành công',
      description: 'Đổi mật khẩu thành công',
    });
  };

  const handleSubmit = (values: NewPasswordFormData) => {
    console.log('username:', username);
    console.log('key:', key);
    if (!username || !key) {
      console.error('Missing username or key in URL params');
      return;
    }

    setLoading(true);
    dispatch(
      doSetNewPassword({
        password: values.password,
        username,
        key,
      }),
    )
      .unwrap()
      .then(() => {
        openNotification(); // Gọi hàm hiển thị thông báo khi thành công
        navigate('/auth/login');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initStates}
      >
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          Quay lại
        </Auth.BackWrapper>
        <Auth.FormTitle>Tạo mật khẩu mới</Auth.FormTitle>
        <Auth.FormItem
          name="password"
          label="Tạo mật khẩu mới"
          rules={[
            { required: true, message: 'Vui lòng tạo mật khẩu mới' },
            {
              pattern:
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~#$%^&+=@]).{8,}$/,
              message:
                'Mật khẩu phải dài ít nhất 8 ký tự bao gồm ít nhất một chữ hoa, một chữ thường, một số và một ký tự đặc biệt; các ký tự đặc biệt phải có trong @~#$%^&+=',
            },
          ]}
        >
          <Auth.FormInputPassword placeholder="******" />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            Đổi mật khẩu
          </S.SubmitButton>
        </BaseForm.Item>
      </Form>
    </Auth.FormWrapper>
  );
};
