import { MenuCategory, MenuData } from '@/@types/menus';
import { strings } from '@/assets';
import { notificationController } from '@/components/controllers/notificationController';
import { getAllMenu, updateMenu } from '@/services/menu.service';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  TabsProps,
} from 'antd';
import _ from 'lodash';
import { CollapseProps } from 'antd/lib';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import CreateCategoryLink from './CreateCategoryLink';
import CreateCustomLink from './CreateCustomLink';
import './styles.scss';
import { NodeModel } from './types';
import { Content } from 'antd/es/layout/layout';
import { CONTENT_STYLE } from '@/styles/themes/constants';
import SortableMenu from './SortableModule/SortableMenu';
import { OutputTreeItems, TreeItem, TreeItems } from '@/@types/dnd';
import { generateRandomId } from '@/utils/utils';
import { UniqueIdentifier } from '@dnd-kit/core';

function makeRandomId() {
  return `${Math.random().toString(36).substring(7)}`;
}

export type MultiLanguageDataType = 'data' | 'data_en';
export interface patchingPayload {
  data: OutputTreeItems;
  data_en: OutputTreeItems;
}

function NavigationPage() {
  const [loading, setLoading] = useState(true);

  // State for DATA fetching (both languages)
  // const [treeData, setTreeData] = useState<any[]>([]);
  // const [treeDataEN, setTreeDataEN] = useState<any[]>([]);
  const [currentLanguageTab, setCurrentLanguageTab] =
    useState<MultiLanguageDataType>('data');

  const [listNavigations, setListNavigations] = useState<MenuData[]>([]);
  const [optionsNavigations, setOptionsNavigations] = useState<
    { value: string; label: string }[]
  >([]);
  const [selected, setSelected] = useState<string>('');
  const [percent, setPercent] = useState(0);

  // State for menu tree data
  const [transformedTreeData, setTransformedTreeData] = useState<TreeItems>([]);
  const [transformedTreeDataEN, setTransformedTreeDataEN] = useState<TreeItems>(
    [],
  );

  const [outputTreeData, setOutputTreeData] = useState<OutputTreeItems>([]);
  const [outputTreeDataEN, setOutputTreeDataEN] = useState<OutputTreeItems>([]);

  // State for controlling Modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCustomLink, setEditCustomLink] = useState<{
    label: string;
    link: string;
  }>({ label: '', link: '' });
  const [form] = Form.useForm();

  // Test coverting TreeData for NavMenu
  type TreeDataTransformer = (data: any[]) => TreeItems;
  type outputTreeDataTransformer = (
    outputData: OutputTreeItems,
  ) => MenuCategory[];
  // Transform original fetched data to match with Menu data format

  // [x] Tree Data transformer needs to be updated to match with Menu data format ( with unique ID generator )
  const treeDataTransformer: TreeDataTransformer = (
    fetchedData: MenuCategory[],
  ) => {
    if (_.isEmpty(fetchedData)) {
      return [];
    }

    // ID need to be unique and auto generated, for now use "label" as item label
    return fetchedData.map(node => {
      const result = {
        originalId: node.id as number,
        link: node.link,
        label: node.label,
        id: generateRandomId(),
        children: !_.isEmpty(node.children) // If not empty, then generate children
          ? node.children.map(child => ({
              originalId: child.id as number,
              id: generateRandomId(),
              children: treeDataTransformer(child.children),
              label: child.label,
              link: child.link,
            }))
          : [],
      };
      return result;
    });
  };

  const outputTreeDataTransformer: outputTreeDataTransformer = outputData => {
    if (_.isEmpty(outputData)) {
      return [];
    }

    // ID need to be unique and auto generated, for now use "label" as item label
    return outputData.map(node => {
      const result = {
        // Duplicate source format
        id: node.originalId as UniqueIdentifier,
        label: node.label,
        link: node.link,

        ...(!_.isEmpty(node.children) && {
          children: node.children.map(child => ({
            id: child.originalId as UniqueIdentifier,
            label: child.label,
            link: child.link,
          })),
        }),
      } as MenuCategory;

      return result;
    });
  };

  // [x] Output data transform back to source format
  const handleGetSortedData = (language: MultiLanguageDataType, data: any) => {
    if (data) {
      if (language === 'data') {
        const outputResult = outputTreeDataTransformer(data);
        setTransformedTreeData(data);
        setOutputTreeData(outputResult);
      } else if (language === 'data_en') {
        const outputResult = outputTreeDataTransformer(data);
        setTransformedTreeDataEN(data);
        setOutputTreeDataEN(outputResult);
      }
    } else {
      console.error('Cannot get sorted data');
    }
  };

  // useEffect for transforming outputTreeDATA into data_en initially to ensure that data_en is available before patching, even if the user hasn't intereacted with data_en yet
  useEffect(() => {
    const updateOutputTreeDATA = () => {
      if (transformedTreeDataEN) {
        const transformedData = outputTreeDataTransformer(
          transformedTreeDataEN,
        );

        setOutputTreeDataEN(transformedData);
      } else {
        console.log('Cant find data');
      }
    };

    updateOutputTreeDATA();
  }, [transformedTreeDataEN]);

  //get all Navigation
  useEffect(() => {
    let ptg = -10;

    const interval = setInterval(() => {
      ptg += 5;
      setPercent(ptg);
    }, 100);
    getAllMenu().then(data => {
      setListNavigations(data);
      setOptionsNavigations(covertNavigationToSelectData(data));

      setTransformedTreeData(treeDataTransformer(data[0].data));
      setTransformedTreeDataEN(treeDataTransformer(data[0].data_en));

      setSelected(data[0].id);
      clearInterval(interval);
      setPercent(0);
      setLoading(false);
    });
  }, []);

  // Update editLink modal fields on every state change
  useEffect(() => {
    form.setFieldsValue(editCustomLink);
  }, [editCustomLink, form]);

  function covertNavigationToSelectData(
    navigations: MenuData[],
  ): { value: string; label: string }[] {
    const result = navigations.map(nav => ({
      value: nav.id,
      label: nav.title,
    }));
    return result;
  }

  function handleAddMenu(values: any) {
    switch (values.type) {
      case 'category':
        handleAddNewMenu(
          {
            text: values.label,
            link: `/category/${values.category}`,
          },
          currentLanguageTab,
        );
        break;
      case 'news':
        handleAddNewMenu(
          {
            text: values.label,
            link: `/news/${values.article}`,
          },
          currentLanguageTab,
        );
        break;
      default:
        handleAddNewMenu(
          {
            text: values.label,
            link: values.link,
          },
          currentLanguageTab,
        );
        break;
    }
  }

  function handleAddNewMenu(
    newNode: Pick<NodeModel, 'text' | 'link'>,
    identifier: MultiLanguageDataType,
  ) {
    const treeDataQuantity =
      identifier === 'data'
        ? transformedTreeData.length
        : transformedTreeDataEN.length;

    const newData: TreeItem = {
      originalId: treeDataQuantity + 1,
      label: newNode.text,
      link: newNode.link,
      id: makeRandomId(),
      children: [],
      parentId: '0',
      index: treeDataQuantity + 1,
      depth: 0,
    };

    if (identifier === 'data') {
      // setTreeData([
      //   ...treeData,
      //   {
      //     id: makeRandomId(),
      //     parent: '0',
      //     droppable: true,
      //     text: newNode.text,
      //     link: newNode.link,
      //   },
      // ]);

      setTransformedTreeData([
        ...transformedTreeData,
        {
          ...newData,
        },
      ]);
    }

    if (identifier === 'data_en') {
      // setTreeDataEN([
      //   ...treeDataEN,
      //   {
      //     id: makeRandomId(),
      //     parent: '0',
      //     droppable: true,
      //     text: newNode.text,
      //     link: newNode.link,
      //   },
      // ]);

      setTransformedTreeDataEN([
        ...transformedTreeDataEN,
        {
          ...newData,
        },
      ]);
    }
  }

  // function coverNagivationToTreeData(navigations: MenuCategory[]): NodeModel[] {
  //   const result: NodeModel[] = [];
  //   navigations.map(nav => {
  //     const randomID = makeRandomId();
  //     result.push({
  //       id: randomID,
  //       parent: '0',
  //       droppable: true,
  //       text: nav.label,
  //       link: nav.link,
  //       isOpen: true,
  //     });
  //     if (nav.children) {
  //       nav.children.map(child => {
  //         result.push({
  //           id: makeRandomId(),
  //           parent: randomID,
  //           droppable: true,
  //           text: child.label,
  //           link: child.link,
  //           isOpen: true,
  //         });
  //       });
  //     }
  //   });

  //   return result;
  // }

  function handleUpdateMenu() {
    // Update Navigation
    const languagePack: MultiLanguageDataType[] = ['data', 'data_en'];

    const selectedNavigation = listNavigations.find(
      nav => nav.id === selected,
    ) as MenuData;

    const processMenuPerLanguage = (): patchingPayload => {
      const dataReadyforPatching: patchingPayload = {
        data: [],
        data_en: [],
      };

      languagePack.forEach(lang => {
        if (lang === 'data') {
          dataReadyforPatching.data = outputTreeData;
        } else if (lang === 'data_en') {
          dataReadyforPatching.data_en = outputTreeDataEN;
        }
      });

      return dataReadyforPatching;
    };

    const handleSubmitPayload = (
      targetNavItem: MenuData,
      payload: patchingPayload,
    ) => {
      try {
        const result = {
          ...payload,
          title: targetNavItem.title,
          title_en: targetNavItem.title,
        };
        updateMenu(targetNavItem.id, result);
      } catch (error) {
        console.error(error);
      } finally {
        notificationController.success({
          message: t('update_navigation_success'),
        });
      }
    };

    const submitData = processMenuPerLanguage();
    handleSubmitPayload(selectedNavigation, submitData);
  }

  const handleChangeSelectNavigation = (value: string) => {
    const selectedNavigation = listNavigations.find(nav => nav.id === value);

    const transformedTreeData = treeDataTransformer(
      selectedNavigation?.data || [],
    );
    const transformedTreeDataEN = treeDataTransformer(
      selectedNavigation?.data_en || [],
    );
    setTransformedTreeData(transformedTreeData);
    setTransformedTreeDataEN(transformedTreeDataEN);

    setSelected(value);
  };
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: t('custom_links'),
      children: <CreateCustomLink onFinish={handleAddMenu} />,
    },
    {
      key: '2',
      label: t('category'),
      children: <CreateCategoryLink onFinish={handleAddMenu} />,
    },
  ];
  
  if (listNavigations.length === 0) {
    return <Spin spinning={loading} percent={percent} fullscreen />;
  }

  // [Helper Function] Traverse TreeData to find selected node
  const findNodeByLabel = (
    targetLabel: MenuCategory['label'],
    node: OutputTreeItems,
  ): MenuCategory | MenuCategory | null => {
    for (const child of node) {
      if (child.label === targetLabel) {
        return child;
      }

      if (child.children && child.children.length > 0) {
        const foundNode: MenuCategory | null = findNodeByLabel(
          // Recursive call
          targetLabel,
          child.children,
        );
        if (foundNode) {
          return foundNode;
        }
      }
    }

    return null;
  };

  const handleShowEditModal = (
    language: MultiLanguageDataType,
    nodeLabel: MenuCategory['label'],
  ) => {
    let selectedNode = null;

    if (language === 'data') {
      selectedNode = findNodeByLabel(nodeLabel, outputTreeData);
    } else if (language === 'data_en') {
      selectedNode = findNodeByLabel(nodeLabel, outputTreeDataEN);
    } else {
      console.error('Invalid language:', language);
    }

    if (selectedNode) {
      const { label, link } = selectedNode;
      setEditCustomLink({ label, link });
      setIsEditModalOpen(true);
    }
  };

  const new_tab_items: TabsProps['items'] = [
    {
      key: '1',
      label: 'VI',
      children: (
        <SortableMenu
          treeData={transformedTreeData}
          onChange={data => {
            handleGetSortedData('data', data);
          }}
          onEdit={nodeUniqueId => {
            handleShowEditModal('data', nodeUniqueId);
          }}
          collapsible
          indicator
          removable
        />
      ),
    },
    {
      key: '2',
      label: 'EN',
      children: (
        <SortableMenu
          treeData={transformedTreeDataEN}
          onChange={data_en => {
            handleGetSortedData('data_en', data_en);
          }}
          onEdit={nodeUniqueId => {
            handleShowEditModal('data_en', nodeUniqueId);
          }}
          collapsible
          indicator
          removable
        />
      ),
    },
  ];

  const onChangeLanguageTab = (key: string) => {
    let currentTab: 'data' | 'data_en' = key === '1' ? 'data' : 'data_en';

    console.log(`Switching to ${currentTab}`);
    setCurrentLanguageTab(currentTab);
  };

  function updateNodeTree(
    node: OutputTreeItems,
    targetLabel: MenuCategory['label'],
    newConfig: { label: string; link: string },
  ): OutputTreeItems {
    console.log({ node, targetLabel, newConfig });

    return node.map(item => {
      if (item.label === targetLabel) {
        return { ...item, label: newConfig.label, link: newConfig.link };
      }

      if (item.children) {
        // updateNodeTree(item.children, targetLabel, newConfig);
        return {
          ...item,
          children: updateNodeTree(item.children, targetLabel, newConfig),
        };
      }

      return item; // No changes for this node
    });
  }

  // [Helper Function] Also using the same logic as the `updateNodeTree` function, but this one directly update the TransformedTreeData state
  function updateTransformedTreeData(
    node: TreeItems,
    targetLabel: MenuCategory['label'],
    newConfig: { label: string; link: string },
  ): TreeItems {
    console.log({ node, targetLabel, newConfig });

    return node.map(item => {
      if (item.label === targetLabel) {
        return { ...item, label: newConfig.label, link: newConfig.link };
      }

      if (item.children) {
        return {
          ...item,
          children: updateTransformedTreeData(
            item.children,
            targetLabel,
            newConfig,
          ),
        };
      }

      return item; // No changes for this node
    });
  }

  function handleSubmitModalData() {
    let customLinkValue = null;

    form
      .validateFields()
      .then(values => {
        customLinkValue = {
          label: values.label,
          link: values.link,
        };
        // setEditCustomLink({ label: values.label, link: values.link });
        const result = updateNodeTree(
          currentLanguageTab === 'data' ? outputTreeData : outputTreeDataEN,
          editCustomLink.label,
          customLinkValue,
        );

        const menuDisplayResult = updateTransformedTreeData(
          currentLanguageTab === 'data'
            ? transformedTreeData
            : transformedTreeDataEN,
          editCustomLink.label,
          customLinkValue,
        );

        if (currentLanguageTab === 'data') {
          setOutputTreeData(result);
          setTransformedTreeData(menuDisplayResult); // Update back treeData view
        } else if (currentLanguageTab === 'data_en') {
          setOutputTreeDataEN(result);
          setTransformedTreeDataEN(menuDisplayResult); // Update back treeData view
        }

        setIsEditModalOpen(false);
        setEditCustomLink({ label: '', link: '' });
        form.resetFields();
      })
      .catch(errorInfo => {
        console.log(errorInfo);
      });
  }

  return (
    <Content style={CONTENT_STYLE}>
      <p>{editCustomLink.label}</p>
      <p>{editCustomLink.link}</p>

      <Modal
        title={t('Custom Links')}
        open={isEditModalOpen}
        onOk={handleSubmitModalData}
        onCancel={() => {
          setIsEditModalOpen(false);
          setEditCustomLink({ label: '', link: '' });
        }} // Close the modal on cancel
      >
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={editCustomLink}
          autoComplete="on"
        >
          <Form.Item
            label={t('label')}
            name="label"
            rules={[
              {
                required: true,
                message: strings().please_input_your_label,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('link')}
            name="link"
            rules={[
              {
                required: true,
                message: strings().please_input_your_link,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Row gutter={80}>
        <Col xl={12} >
          <Collapse accordion items={items} defaultActiveKey={['1']} />
        </Col>
        {/* Test New DND module */}
        <Col xl={12}>
          <div className="sortable-menu">
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col span={24}>
                <Select
                  defaultValue={optionsNavigations[0].value}
                  style={{ width: 300 }}
                  onChange={handleChangeSelectNavigation}
                  options={optionsNavigations}
                />
                <Button
                  type="primary"
                  onClick={handleUpdateMenu}
                  style={{ backgroundColor: 'rgb(1,80,154)', marginLeft: 20 }}
                >
                  {strings().update}
                </Button>
              </Col>
            </Row>

            <Tabs
              defaultActiveKey="1"
              items={new_tab_items}
              onChange={onChangeLanguageTab}
            />
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default NavigationPage;
