import { ColumnsType } from 'antd/es/table';
import { Flex, Form, Input, Modal, Space } from 'antd';
import { FActions, FModal, FTable } from '@/components/fcomponents';
import { FC, useEffect, useRef, useState } from 'react';
import FContent from '@/layouts/main/FContent';
import { BaseButton } from '@/components/common/BaseButton/BaseButton';
import './styles.scss';
import { useAppDispatch } from '@/hooks/reduxHooks';
import {
  setContentModal,
  hiddenModal,
  hiddenDrawer,
} from '@/store/slices/appSlice';
import { UserType } from '@/@types/user';
import { strings } from '@/assets';
import { deleteUser, getAllUser, getUser, createUser } from '@/services';
import {
  makeDataUserEditRequest,
  makeDataUserRequest,
  updateUser,
} from '@/services/users.service';
import { AxiosError } from 'axios';
import { notificationController } from '@/components/controllers/notificationController';
import { debounce } from 'lodash';
import { Content } from 'antd/es/layout/layout';
import { CONTENT_STYLE } from '@/styles/themes/constants';
import { Store } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
type TProps = {
  edit?: boolean;
  initialValues?: Store;
  buttonOkText?: string;
  editPass?: boolean;
  onFinish?: (values: unknown) => void;
  visible?: boolean;
  onCancel?: () => void;
};

type FieldType = {
  username?: string;
  password?: string;
  email?: string;
};
const UsersPage: FC<TProps> = props => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { visible, editPass } = props;
  const [loading, setLoading] = useState(true);
  const selectedUserRef = useRef<UserType | null>(null);
  const formTypeRef = useRef('create');
  const [users, setUsers] = useState<UserType[]>([]);
  const [resultUsers, setResultUsers] = useState<UserType[]>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    fetchData();
  }, []);

  const columns: ColumnsType<UserType> = [
    {
      title: strings().id,
      dataIndex: ['id'],
      width: 70,
      render: (_, record, index) => (
        <span style={{ fontSize: 15 }} onClick={() => handleClickRow(record)}>
          {index + 1}
        </span>
      ),
    },
    {
      title: strings().email,
      dataIndex: ['email'],
      width: 120,
      render: (text, record) => (
        <span style={{ fontSize: 15 }} onClick={() => handleClickRow(record)}>
          {text}
        </span>
      ),
    },
    {
      title: strings().username,
      dataIndex: ['username'],
      width: 200,
      render: text => <span style={{ fontSize: 15 }}>{text}</span>,
    },
    {
      title: strings().action,
      dataIndex: 'action',
      align: 'center',
      width: 100,
      render(_, record) {
        return (
          <Space>
            <FActions
              id={JSON.stringify(record.id)} // Temp fix TS error, might cause bug
              showEdit={false}
              onClickRemove={() => handleClickRemove(record as UserType)}
              showPlus={false}
            />
          </Space>
        );
      },
    },
  ];
  // const data: UserType[] = [];
  // for (let i = 0; i < 100; i++) {
  //   data.push({
  //     key: i,
  //     age: i + 1,
  //   });
  // }
  const handleOnSearch = debounce((values: string) => {
    const filteredUsers = users.filter(user =>
      user.username.toLowerCase().includes(values.toLowerCase()),
    );
    setResultUsers(filteredUsers);
  }, 500);
  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const users = await getAllUser();
      setUsers(users);
      setResultUsers(users);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleFormSubmit = async (values: unknown) => {
    const selectedUser = selectedUserRef.current;
    try {
      if (formTypeRef.current === 'edit' && selectedUser) {
        if (typeof values === 'object' && values !== null) {
          const updateValues = {
            ...values,
            id: selectedUser.id, // Đảm bảo id được gán từ selectedUser
          };
          const req = makeDataUserEditRequest(
            updateValues as UserType & Record<string, number | string>,
          );
          setResultUsers(prevUsers => {
            return prevUsers.map(user => {
              if (user._id === selectedUser._id) {
                return {
                  ...user,
                  ...req,
                };
              }
              return user;
            });
          });
          await updateUser(selectedUser._id, req);
          fetchData();
          dispatch(hiddenDrawer());
          setIsEditClockModalOpen(false);
          notificationController.success({
            message: t('user_updated_successfully'),
          });
        }
      } else {
        const req = makeDataUserRequest(
          values as UserType & Record<string, number | string>,
        );
        const newUserResponse = await createUser(req);
        const newUserDoc: UserType = {
          _id: newUserResponse._id || 0,
          email: newUserResponse.email || '',
          id: newUserResponse.id || 0,
          username: newUserResponse.username || '',
          password: newUserResponse.password || '',
        };
        setUsers(prevUsers => [...prevUsers, newUserDoc]);
        fetchData();
        dispatch(hiddenDrawer());
        setIsEditClockModalOpen(false);
        notificationController.success({
          message: t('user_created_successfully'),
        });
      }
    } catch (error) {
      console.log('🚀 ~ handleFormSubmit ~ error:', error);
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status === 409) {
        notificationController.error({
          message: t('username_or_email_already_exists'),
        });
      } else {
        notificationController.error({
          message: t('username_or_email_already_exists'),
        });
      }
    }
  };
  const handleClickRemove = (record: UserType) => {
    dispatch(
      setContentModal(
        <FModal
          title={strings().delete_user}
          description={
            strings().youre_deleting_an_user_this_action_cannot_be_undone
          }
          onOk={() => handleDeleteUser(record)}
        />,
      ),
    );
  };
  const handleDeleteUser = async (record: UserType) => {
    console.log('Delete user', record);
    try {
      await deleteUser(record.id);
      setResultUsers(users.filter(user => user.id !== record.id));
      fetchData();
      dispatch(setContentModal(null));
      dispatch(hiddenModal());
      notificationController.success({
        message: t('user_deleted_successfully'),
      });
    } catch (error) {
      console.log('error', error);
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status === 409) {
        notificationController.error({
          message: `Failed to delete user:`,
        });
      } else {
        notificationController.error({
          message: 'Failed to delete user',
        });
      }
    }
  };
  function handleShowDrawer(
    stt: 'create' | 'edit' | 'view',
    record: UserType | null = null,
  ) {
    selectedUserRef.current = record;
    formTypeRef.current = stt;
    if (stt === 'edit') {
      form.setFieldsValue({
        username: record?.username,
        password: record?.password,
        email: record?.email,
      });
    }
    handleEditClockClick();
  }

  const [isEditClockModalOpen, setIsEditClockModalOpen] = useState(false);
  const handleEditClockClick = () => {
    setIsEditClockModalOpen(true);
  };
  function renderHeaderRight() {
    return (
      <Flex vertical={false} gap={20} align="center" justify="flex-start">
        <BaseButton
          type="primary"
          className="capitalize add-button"
          icon={<i className="fa-light fa-circle-plus" />}
          onClick={() => {
            formTypeRef.current = 'create';
            form.resetFields(); // Reset lại các field trong form khi tạo mới
            setIsEditClockModalOpen(true);
          }}
        >
          {t('add')}
        </BaseButton>
        <Modal
          title={
            formTypeRef.current === 'edit' ? t('edit_user') : t('add_user')
          }
          visible={visible}
          onCancel={() => setIsEditClockModalOpen(false)}
          open={isEditClockModalOpen}
          onOk={() => form.submit()} // Gọi submit form thay vì gọi trực tiếp handleFormSubmit
        >
          <Form
            form={form}
            name="frm-user"
            initialValues={{ name: name }}
            autoComplete="off"
            onFinish={handleFormSubmit}
          >
            <Form.Item<FieldType>
              label={strings().username}
              name="username"
              rules={[
                { required: true, message: strings().please_input_your_name },
                {
                  pattern: /^\S*$/,
                  message: strings().username_cannot_contain_spaces,
                },
                {
                  pattern: /^[a-zA-Z\s]{1,100}$/,
                  message:
                    strings()
                      .name_cannot_contain_special_characters_and_numbers,
                },
              ]}
            >
              <Input placeholder={t('label_name')} />
            </Form.Item>

            <Form.Item<FieldType>
              label={strings().password}
              name="password"
              rules={[
                {
                  required: formTypeRef.current === 'create',
                  message: strings().please_input_your_password,
                },
                {
                  pattern:
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~#$%^&+=@]).{8,}$/,
                  message: t(
                    'password_must_be_at_least_8_characters_long_including_at_least_one_uppercase_letter_one_lowercase_letter_one_number_and_one_special_character_special_characters_must_be_in',
                  ),
                },
              ]}
            >
              <Input.Password
                visibilityToggle={editPass}
                placeholder={!editPass ? '*********' : 'Enter password...'}
                style={{ backgroundColor: '#F6F7FB' }}
                iconRender={visible =>
                  visible ? (
                    <EyeOutlined
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    />
                  )
                }
              />
            </Form.Item>
            <Form.Item<FieldType>
              label={strings().email}
              name="email"
              rules={[
                { required: true, message: t('please_input_your_email') },
                {
                  message: t(
                    'email_is_not_in_the_correct_format_please_re_enter',
                  ),
                  type: 'email',
                },
              ]}
            >
              <Input placeholder={t('label_email')} />
            </Form.Item>
          </Form>
        </Modal>
      </Flex>
    );
  }
  async function handleClickRow(record: UserType) {
    try {
      const res = await getUser(record._id);
      handleShowDrawer('edit', res.data);
    } catch (error) {
      console.error('Error clicking row:', error);
    }
  }
  return (
    <div className="users-page">
      <Content style={CONTENT_STYLE}>
        <FContent renderPanelRight={renderHeaderRight} height="60%">
          <FTable
            useSearch
            onSearch={handleOnSearch}
            data={resultUsers}
            tblProps={{
              rowKey: 'id',
              loading,
              columns,
              expandable: {},
            }}
          />
        </FContent>
      </Content>
    </div>
  );
};

export default UsersPage;
