import { BannerConfigType } from '@/@types/home-carousel';
import { Col, Input, Row, Tabs, TabsProps, Typography } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface BannerConfigProps {
  bannerConfigData: BannerConfigType;
  onBannerConfigChange: (
    identifier: keyof BannerConfigType,
    value: string,
  ) => void;
}

const BannerConfig: FC<BannerConfigProps> = ({
  bannerConfigData,
  onBannerConfigChange,
}): React.JSX.Element => {
  const inputStyle = {
    width: '85%',
  };
  const { t } = useTranslation();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'VI',
      children: (
        <Row justify="space-around" align="middle" gutter={[0, 15]}>
          <Col span={12}>
            <Typography.Title level={5}>{t('title')}</Typography.Title>

            <Input
              placeholder="Biển, Hải đảo Việt Nam"
              value={bannerConfigData.title}
              onChange={event =>
                onBannerConfigChange('title', event.target.value)
              }
              style={inputStyle}
            />
          </Col>
          <Col span={12}>
            <Typography.Title level={5}>{t('sub_title')}</Typography.Title>

            <Input
              placeholder="Tiềm năng, Cơ hội, Tầm nhìn"
              onChange={event =>
                onBannerConfigChange('subtitle', event.target.value)
              }
              value={bannerConfigData.subtitle}
              style={inputStyle}
            />
          </Col>
          <Col span={12}>
            <Typography.Title level={5}>{t('article_link')}</Typography.Title>

            <Input
              placeholder="http://vasi.gov.vn/edit-homepage"
              onChange={event =>
                onBannerConfigChange('articleUrl', event.target.value)
              }
              value={bannerConfigData.articleUrl}
              style={inputStyle}
            />
          </Col>
          <Col span={12}>
            <Typography.Title level={5}>{t('button_title')}</Typography.Title>

            <Input
              placeholder="Đọc thêm..."
              onChange={event =>
                onBannerConfigChange('buttonName', event.target.value)
              }
              value={bannerConfigData.buttonName}
              style={inputStyle}
            />
          </Col>
        </Row>
      ),
    },
    {
      key: '2',
      label: 'EN',
      children: (
        <Row justify="space-around" align="middle" gutter={[0, 15]}>
          <Col span={12}>
            <Typography.Title level={5}>{t('title')}</Typography.Title>

            <Input
              value={bannerConfigData.title_en}
              onChange={event => onBannerConfigChange('title_en', event.target.value)}
              style={inputStyle}
            />
          </Col>
          <Col span={12}>
            <Typography.Title level={5}>{t('sub_title')}</Typography.Title>

            <Input
              onChange={event => onBannerConfigChange('subtitle_en', event.target.value)}
              value={bannerConfigData.subtitle_en}
              style={inputStyle}
            />
          </Col>
          <Col span={12}>
            <Typography.Title level={5}>{t('article_link')}</Typography.Title>

            <Input
              placeholder="http://vasi.gov.vn/edit-homepage"
              onChange={event => onBannerConfigChange('articleUrl', event.target.value)}
              value={bannerConfigData.articleUrl}
              style={inputStyle}
            />
          </Col>
          <Col span={12}>
            <Typography.Title level={5}>{t('button_title')}</Typography.Title>

            <Input
              onChange={event => onBannerConfigChange('buttonName_en', event.target.value)}
              value={bannerConfigData.buttonName_en}
              style={inputStyle}
            />
          </Col>
        </Row>
      ),
    },
  ];

  

  return (
    <>
      <div className="banner-config">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </>
  );
};

export default BannerConfig;
