import { AllCategoriesData } from '@/@types/categories';
import { ArticleData, ArticleType } from '@/@types/post';
import { httpApi } from '@/services/http.service';
import { message } from 'antd';

import { get } from 'lodash';

export const getAllArticle = async (): Promise<ArticleType[]> => {
  const res = await httpApi.get<{
    total: number;
    data: ArticleType[];
  }>('/articles');
  return res.data.data;
};

export const getArticleDetail = async (id: string) => {
  const res = await httpApi.get<ArticleData>(`/articles/${id}`);
  return res.data;
};

export const getAllArticleByParam = async (limit: number, search: string) => {
  const res = await httpApi.get<ArticleType>('/articles', {
    params: { limit, search },
  });

  return res;
};

export const getAllCategories = async () => {
  const res = await httpApi.get<{
    success: boolean;
    data: AllCategoriesData;
  }>('/categories');

  return res.data.data;
};

export const createPost = async (data: ArticleType): Promise<ArticleType> => {
  // Except for media problem, all form create has been working fine

  const formData = new FormData();
  formData.append('title', data.title);
  formData.append('content', data.content);
  if (data.title_en) {
    formData.append('title_en', data.title_en);
  }
  if (data.content_en) {
    formData.append('content_en', data.content_en);
  }
  formData.append(
    'categoriesId',
    JSON.stringify(data.categoriesId.filter(c => c)),
  );
  if (data.fileExcel) {
    formData.append('excel', data.fileExcel);
  }

  if (data.fileThumbnail) {
    formData.append('thumbnail', data.fileThumbnail);
  } else {
    formData.append('mediaId', data.mediaId);
  }
  console.log(data);

  const res = await httpApi.post<ArticleType>(`/articles`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return res.data;
};

export const updatePost = async (newsId: string, data: ArticleType) => {
  const formData = new FormData();

  formData.append('title', data.title);
  formData.append('content', data.content);

  if (data.title_en) {
    formData.append('title_en', data.title_en);
  }
  if (data.content_en) {
    formData.append('content_en', data.content_en);
  }
  if (data.fileExcel) {
    formData.append('excel', data.fileExcel);
  }

  if (data.fileThumbnail) {
    formData.append('thumbnail', data.fileThumbnail);
  } else {
    formData.append('mediaId', data.mediaId);
  }

  formData.append(
    'categoriesId',
    JSON.stringify(data.categoriesId.filter(c => c)),
  );

  try {
    const res = await httpApi.put(`/articles/${newsId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    console.log('🚀 ~ updatePost ~ res:', res.data);
    return res.data;
  } catch (error: any) {
    message.error(error?.message);
    throw error;
  }
};

export const deletePost = async (postId: string): Promise<void> => {
  await httpApi.delete<void>(`/articles/${postId}`);
};

export const getInitFormFromPost = (post: ArticleType | undefined) => {
  return {
    id: get(post, 'id', ''),
    title: get(post, 'title', ''),
    slug: get(post, 'slug', ''),
    excerpt: get(post, 'excerpt', ''),
    content: get(post, 'content', ''),
    category: get(post, 'categories', ''),
    mediaId: get(post, 'mediaId', ''),
    content_en: get(post, 'content_en', ''),
    title_en: get(post, 'title_en', ''),
    excelName: get(post, 'excelName', ''),
    tables: get(post, 'tables', ''),
    thumbnail: get(
      post,
      'thumbnail',
      'http://portal.fint.vn:5005/wp-content/themes/fint/assets/images/no-thumbnail.jpg',
    ),
  };
};
