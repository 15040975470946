import { createContext, useContext } from 'react';

interface LayoutContextProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useLayoutContext = (): LayoutContextProps => {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error('useLayoutContext must be used within a LayoutProvider');
  }

  return context;
};

const LayoutContext = createContext<LayoutContextProps | undefined>(
  undefined,
);

export { LayoutContext, useLayoutContext };
