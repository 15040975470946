import React, { FC } from 'react';
import './styles.scss';
import { generalItems } from '@/layouts/main/MainContent/Sidebar/sidebarNavigation';
import { settingsItems } from '@/layouts/main/MainContent/Sidebar/sidebarNavigation';
import SidebarGroup from './SidebarGroup';
import { useAppSelector } from '@/hooks/reduxHooks';
import { selectedSidebar } from '@/store/slices/sidebarSlice';

const SidebarContent: FC = (): React.JSX.Element => {
  const isOpenSidebar = useAppSelector(selectedSidebar);

  return (
    <div className={`sidebar-content ${isOpenSidebar ? 'sidebar-content-open--active' : ''}`}>
      <SidebarGroup title="site_configs" itemList={generalItems} />
      <SidebarGroup title="general" itemList={settingsItems} />
    </div>
  );
};

export default SidebarContent;
