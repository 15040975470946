import { TaxonomyData } from '@/@types/news';
import { Col, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import TargetBox from './components/TargetBox';
import TaxonomyList from './components/TaxonomyList';
import './styles.scss';
import { HomeConfigData } from '@/@types/home-carousel';

export interface TaxonomyProps {
  allTaxonomy: TaxonomyData[];
  activeTaxonomy: HomeConfigData['taxonomy'];
  onChangeActive: (data: string[]) => void;
  language: 'en' | 'vi';
}

const Taxonomy: FC<TaxonomyProps> = ({
  allTaxonomy,
  activeTaxonomy,
  onChangeActive,
  language,
}): React.JSX.Element => {
  const [widgetsCard, setWidgetsCard] = useState<string[]>([]);
  const [onTargetBox, setOnTargetBox] = useState(false);

  useEffect(() => {
    if (Array.isArray(activeTaxonomy)) {
      const activeTaxonomyIds: string[] = activeTaxonomy.map(item => item._id);
      setWidgetsCard(activeTaxonomyIds);
      onChangeActive(activeTaxonomyIds);
    }
  }, []);

  useEffect(() => {
    onChangeActive(widgetsCard);
  }, [widgetsCard]);

  const handleOnDrag = (event: React.DragEvent, widgetItemID: string) => {
    event.dataTransfer.setData('widgetID', widgetItemID);
  };

  const handleOnDrop = (event: React.DragEvent) => {
    const widgetID = event.dataTransfer.getData('widgetID');
    setWidgetsCard([...widgetsCard, widgetID]);
    setOnTargetBox(false);
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setOnTargetBox(true);
  };

  return (
    <div className="taxonomy">
      <Row gutter={[20, 0]}>
        <Col span={12}>
          <TaxonomyList
            language={language}
            allTaxonomy={allTaxonomy}
            handleOnDrag={handleOnDrag}
            handleOnDrop={handleOnDrop}
            widgetsCard={widgetsCard}
          />
        </Col>
        <Col span={12}>
          <TargetBox
            language={language}
            onTargetBox={onTargetBox}
            widgetsCard={widgetsCard}
            setWidgetsCard={setWidgetsCard}
            onDrop={event => handleOnDrop(event)}
            onDragOver={event => handleDragOver(event)}
            allTaxonomy={allTaxonomy}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Taxonomy;
