import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IFormState {
  formValue: Record<string, string | number | unknown>;
  initialValues: unknown;
}

const initialState = {
  formValue: {},
} as IFormState;

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormValue: (state, action: PayloadAction<{ value: Record<string, string | number | unknown> }>) => {
      const value = action.payload.value;
      state.formValue = { ...state.formValue, value };
    },

    setInitialValues: (state, action: PayloadAction<{ value: unknown }>) => {
      state.initialValues = action.payload.value;
    },
  },
});

export const { setFormValue, setInitialValues } = formSlice.actions;
export default formSlice.reducer;
