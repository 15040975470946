import userReducer from '@/store/slices/userSlice';
import authReducer from '@/store/slices/authSlice';
import nightModeReducer from '@/store/slices/nightModeSlice';
import themeReducer from '@/store/slices/themeSlice';
import pwaReducer from '@/store/slices/pwaSlice';
import appSlice from '@/store/slices/appSlice';
import formSlice from './formSlice';
import drawerSlice from './drawerSlice';
import sidebarSlice from './sidebarSlice';

export default {
  user: userReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  app: appSlice,
  drawer: drawerSlice,
  form: formSlice,
  sidebar: sidebarSlice
};
