import logo from '@/assets/images/site/vasi.jpg';
const APPCONFIG = {
  siteName: 'Vasi',
  logo: logo,
  slogan: 'Vasi',
  format: {
    date: 'DD/MM/YYYY',

    /**
     * Using for advanced search request
     */
    date1: 'YYYY-MM-DD',
    datetime: 'lll',
    time: 'HH:mm:ss',
    currency: 'pt-BR',
    number: 'pt-BR',
    percent: 'pt-BR',
  },
  debounceTime: 500,
};

export default APPCONFIG;
