import MonacoComponent from '@/components/common/Monaco';
import { Content } from 'antd/es/layout/layout';

const CodeEditorComponent = () => {
  return (
    <Content>
      <MonacoComponent />
    </Content>
  );
};

export default CodeEditorComponent;
