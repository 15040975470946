import React, { FC, useEffect, useState } from 'react';
import './styles.scss';
import { NavigationConfigItem } from '@/@types/general';
import NavSelection from './NavSelection';
import { Button, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { capitalize, isUnique } from '@/utils/utils';
import { useTranslation } from 'react-i18next';
import { updateGeneral } from '@/services/general.service';
import { CategoryData } from '@/@types/post';
import { getAllMenu } from '@/services/menu.service';
import { MenuRoot } from '@/@types/menus';

interface NavigationConfigProps {
  activeNavigationConfig:
    | (NavigationConfigItem & { id: string })[]
    | (NavigationConfigItem[] | undefined)
    | null; // [ ] ?
}

// { 'footer': 546546546540650465 }

export interface MenuType {
  identifier: 'footer' | 'header left' | 'header right';
}

type NavigationConfig = {
  [x in MenuType['identifier']]: number | null; // [ ] ?
};

const NavigationConfig: FC<NavigationConfigProps> = ({
  activeNavigationConfig,
}): React.JSX.Element => {
  const [navigationConfig, setNavigationConfig] = useState<string[] | null>(
    null,
  );

  const { t } = useTranslation();

  const [allMenu, setAllMenu] = useState<MenuRoot['data'] | undefined>();

  useEffect(() => {
    // [Helper Function] Fetch and Set Current Navigation Config from 'config/general' endpoint

    const setActiveNavigationState = () => {
      if (activeNavigationConfig) {
        const preConfig = [
          activeNavigationConfig?.[1].id,
          activeNavigationConfig?.[0].id,
          activeNavigationConfig?.[2].id,
        ];
        console.log('🪳 ~ file: index.tsx:52 ~ setActiveNavigationState ~ preConfig||', preConfig);


        setNavigationConfig(preConfig.map(String));
      }
    };

    setActiveNavigationState();
    fetchAllMenu();
  }, [activeNavigationConfig]);

  const fetchAllMenu = async () => {
    const respond = await getAllMenu();

    // Need to migrate new navigation api for this, so that the option function can display data
    // Navigation now changed to "menu"
    setAllMenu(respond);
  };

  const handleUpdateNavConfigs = (
    id: string,
    identifier: MenuType['identifier'],
  ) => {
    // identifier: 'footer' | 'header left' | 'header right';

    const setNavConfigAtIndex = (index: number, id: string) => {
      setNavigationConfig(prevConfig => {
        if (prevConfig) {
          const newConfig = [...prevConfig];
          newConfig[index] = id;
          return newConfig;
        }

        return prevConfig;
      });
    };

    switch (identifier) {
      case 'header left':
        setNavConfigAtIndex(1, id);
        break;
      case 'header right':
        setNavConfigAtIndex(0, id);
        break;
      case 'footer':
        setNavConfigAtIndex(2, id);
        break;
      default:
        break;
    }
  };

  const handleSubmitConfig = () => {
    // [x] NavigationConfig has been setup correctly, just need to submit the array to server when it lives

    // Early prevent user push duplicated config hence break the navs api
    if (!isUnique(navigationConfig)) {
      message.error(t('Duplication Found'));
      return;
    }

    try {
      if (navigationConfig) {
        updateGeneral({
          navigation: navigationConfig,
        });
      }
      
    } catch (error) {
      console.log('Error', error);
    } finally {
      message.success(t('success'));
    }
  };

  const renderNavSelection = (
    // Render nav section's UI
    identifier: MenuType['identifier'],
    preSelectedValue: CategoryData['_id'],
  ) => {
    return (
      <NavSelection
        title={`${capitalize(identifier)}`}
        allNavigation={allMenu?.map(navItem => ({
          value: navItem._id,
          label: `${navItem.title}`,
        }))}
        onUpdateNavConfigs={id => handleUpdateNavConfigs(id, identifier)}
        preSelectedValue={preSelectedValue}
      />
    );
  };

  const handleRenderNavSelection = () => {
    if (!navigationConfig) {
      return null;
    }

    // Pre-selected value will be the index of the value array fetched from /config/general
    return (
      <>
        {renderNavSelection(t('header_left_navigation'), navigationConfig[0])}
        {renderNavSelection(t('header_right_navigation'), navigationConfig[1])}
        {renderNavSelection(t('footer_navigation'), navigationConfig[2])}
      </>
    );
  };

  if (!navigationConfig) {
    return <p>Loading...</p>;
  }

  return (
    <div className="navigation-config">
      <div className="navigation-config__all">
        {handleRenderNavSelection()}

        <Button
          type="primary"
          icon={<CheckOutlined />}
          size="middle"
          style={{ marginTop: '20px' }}
          onClick={handleSubmitConfig}
        >
          {t('Update Navigation')}
        </Button>
      </div>
    </div>
  );
};

export default NavigationConfig;
