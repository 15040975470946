import React, { FC } from 'react';
import './styles.scss';
import { Dropdown, MenuProps } from 'antd';
import LanguageSwitcher from '@/components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { doLogout } from '@/store/slices/authSlice';

interface HeaderProfileProps {
  name: string;
  img: string;
  role: string;
}

const HeaderProfile: FC<HeaderProfileProps> = ({
  name,
  role,
}): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleSubmit = () => {
    dispatch(doLogout());
    window.location.href = '/auth/login';
  };
  const items: MenuProps['items'] = [
    {
      key: '4',
      label: <LanguageSwitcher />,
    },
    {
      key: '5',
      label: (
        <li onClick={e => e.preventDefault()}>
          <button
            className="btn btn-pill btn-outline-primary btn-sm"
            onClick={handleSubmit}
          >
            {t('logout')}
          </button>
        </li>
      ),
    },
  ];

  return (
    <div className="nav-right ">
      <ul className="nav-menus">
        <li className="profile-nav onhover-dropdown">
          <div className="media profile-media">
            <div className="media-body d-xxl-block box-col-none">
              <div
                className="d-flex align-items-center gap-2"
                style={{ justifyContent: 'flex-end' }}
              >
                <span className="uppercase">{name}</span>
                <Dropdown menu={{ items }} trigger={['click']}>
                  <a onClick={e => e.preventDefault()}>
                    <i className="middle fa fa-angle-down"></i>
                  </a>
                </Dropdown>
              </div>
              <p className="mb-0 font-roboto lowercase">{role}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default HeaderProfile;
