import MediaUploadGroup from '@/components/media/MediaUploadGroup';
import {
  getAllHomeConfig,
  getConfigID,
  updateHomePageConfig,
} from '@/services/home.service';
import { getAllNewsTaxonomy } from '@/services/news.service';
import { CONTENT_STYLE } from '@/styles/themes/constants';

import {
  BannerConfigType,
  HomeConfigData,
  HomePageOutputType,
  Slider,
} from '@/@types/home-carousel';
import { IData } from '@/@types/media';
import { TaxonomyData } from '@/@types/news';
import { getMediaDetailByID } from '@/services/media.service';
import { defaultStyle } from '@/utils/theme';
import {
  CheckOutlined,
  ClearOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  message,
  Row,
  Spin,
  Tabs,
  TabsProps,
  UploadFile,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BannerConfig from './components/BannerConfig';
import CarouselMediaGrid from './components/CarouselMediaGrid';
import Taxonomy from './components/Taxonomy';
import './styles.scss';

export interface AllTaxonomy {
  id: number;
  name: string;
}
const HomePage: FC = (): React.JSX.Element => {
  const [pendingUploadList, setPendingUploadList] = useState<UploadFile[]>([]);
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const [newsData, setNewsData] = useState<{ allTaxonomy: TaxonomyData[] }>();

  // Control loading state to make sure the data is fetched
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Fetch All Config Data State
  const [configData, setConfigData] = useState<HomeConfigData>();
  const [pendingSubmitData, setPendingSubmitData] =
    useState<HomePageOutputType>();
  const [revertData, setRevertData] = useState<HomePageOutputType>();

  const [mergedData, setMergedData] = useState<HomeConfigData['sliders']>([]);

  const { t } = useTranslation();

  const handleTransformConfigData = (_configData: HomeConfigData): void => {
    if (!_configData) {
      return;
    }

    const preConfig: HomePageOutputType = {
      banner: {
        title: _configData?.banner?.title || '',
        subtitle: _configData?.banner?.subtitle || '',
        buttonName: _configData?.banner?.buttonName || '',
        articleUrl: _configData?.banner?.articleUrl || '',
      },
      mediaIds: _configData?.sliders.map(slider => slider._id) || [],
      categoriesId: _configData?.taxonomy.map(taxonomy => taxonomy._id) || [],
      type: 'HOME_PAGE', // Must match exactly "HOME_PAGE"
    };

    setPendingSubmitData(preConfig);
    setRevertData(preConfig);
  };

  useEffect(() => {
    const fetchAndTransformData = async () => {
      setIsLoading(true);

      // Fetch all config and news categories
      // await handleFetchAllHomeConfig();
      const _configData = await getAllHomeConfig();
      await fetchListOfNewsTaxonomy();

      // Transform Data when all data is fetched
      if (_configData) {
        setConfigData(_configData);
        handleTransformConfigData(_configData); // This might cause data mismatch between configData and pendingSubmitData bug
      }

      setIsLoading(false);
    };

    fetchAndTransformData();
  }, []);

  // This useEffect is only used in case the data is not fetched yet
  // useEffect(() => {
  //   if (configData) {
  //     handleTransformConfigData();
  //   }
  // }, [configData]);

  useEffect(() => {
    const fetchDetail = async () => {
      let mergedActiveCarouselData = [];

      if (pendingSubmitData?.mediaIds) {
        const res = await handleBatchGetMediaDetail(pendingSubmitData.mediaIds);
        mergedActiveCarouselData = res;
        setMergedData(mergedActiveCarouselData);
      } else {
        const res = await handleBatchGetMediaDetail(
          configData?.sliders.map(slider => slider._id) || [],
        );
        mergedActiveCarouselData = res;
        setMergedData(mergedActiveCarouselData);
      }
      setMergedData(mergedActiveCarouselData);
    };

    fetchDetail();
  }, [pendingSubmitData?.mediaIds, configData?.sliders]);

  const handleBatchGetMediaDetail = async (mediaIDs: string[]) => {
    try {
      const result = await Promise.all(
        mediaIDs.map(async id => {
          return await handleGetMediaDetail(id);
        }),
      );

      return result;
    } catch (error) {
      console.log('Error fetching media detail by IDs', error);
      return [];
    }
  };

  const handleGetMediaDetail = async (id: string): Promise<IData> => {
    try {
      const res = await getMediaDetailByID(id);
      return res.data;
    } catch (error) {
      console.error('getMediaDetail Error', error);
      message.error(t('error'));
      throw error;
    }
  };

  const fetchListOfNewsTaxonomy = async () => {
    const allNewsTaxonomy = await getAllNewsTaxonomy();

    if (!allNewsTaxonomy) return null;

    setNewsData({
      allTaxonomy: allNewsTaxonomy,
    });

    setIsLoading(false);
  };

  const handleSubmit = async () => {
    const configID = await getConfigID();

    try {
      if (!pendingSubmitData) {
        throw new Error('Config data is undefined');
      }
      console.log('Payload Sent', pendingSubmitData);
      await updateHomePageConfig(pendingSubmitData, configID);

      message.success(t('success'));
    } catch (error) {
      message.error(('Error updating home page config:' + error) as string);
    }
  };

  const buttonStyle = {
    marginTop: '20px',
    marginLeft: '10px',
  };

  const handleFileChange = async ({
    fileList,
    file,
  }: {
    fileList: UploadFile<any>[];
    file: UploadFile<any>;
  }) => {
    setPendingUploadList(fileList);

    if (file.status === 'done') {
      const respondId: Slider['_id'] = file.response._id;

      const updatedPendingConfigData = [
        respondId,
        ...(pendingSubmitData?.mediaIds || []),
      ];

      setPendingSubmitData(prevConfig => {
        if (prevConfig) {
          return {
            ...prevConfig,
            mediaIds: updatedPendingConfigData,
          };
        }
      });

      setPendingUploadList([]); // Clear the pending list after successful image upload

      message.success(
        t('Image saved successfully, please make sure to save changes'),
      );
    } else if (file.status === 'error') {
      message.error(file.response.message);
    }
  };

  const handleBannerConfigChange = (
    identifier: keyof BannerConfigType,
    data: string,
  ) => {
    setConfigData(prevConfig => {
      if (prevConfig) {
        return {
          ...prevConfig,
          banner: {
            ...prevConfig.banner,
            [identifier]: data,
          },
        };
      }
    });

    setPendingSubmitData(prevConfig => {
      if (prevConfig) {
        return {
          ...prevConfig,
          banner: {
            ...prevConfig.banner,
            [identifier]: data,
          },
        };
      }
    });
  };

  const handleChangeActiveTaxonomy = (
    data: HomePageOutputType['categoriesId'],
  ) => {
    const outputData: HomePageOutputType['categoriesId'] = data;
    setPendingSubmitData(prevConfig => {
      if (prevConfig) {
        return {
          ...prevConfig,
          categoriesId: outputData,
        };
      }
    });
  };

  const handleChangeActiveCarousel = (data: HomeConfigData['sliders']) => {
    // Set pending result array

    const outputData: HomePageOutputType['mediaIds'] = data.map(
      item => item._id,
    );

    setPendingSubmitData(prevConfig => {
      if (prevConfig) {
        return {
          ...prevConfig,
          mediaIds: outputData,
        };
      }
    });
  };

  const handleSubmitSelection = (result: string[] | number[]) => {
    //@ts-expect-error ts-migrate(2339) FIXME: Property 'mediaIds' does not exist on type 'HomePage...
    setPendingSubmitData(prevConfig => {
      if (prevConfig) {
        return {
          ...prevConfig,
          mediaIds: [...result, ...prevConfig.mediaIds],
        };
      }
    });
  };

  const handleReset = () => {
    setIsLoading(true); // Toggle reload Component

    // Hard to reset config data since it's already updated in configData State. Code below is only a quick fix (which costs an additional state)
    setPendingSubmitData(revertData);
    setConfigData(preConfig => {
      if (preConfig && revertData) {
        return {
          ...preConfig,
          banner: revertData.banner,
        };
      }
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 500);

    message.info(t('reset'));
  };

  // Make sure that all data is loaded
  if (isLoading || !newsData || !configData) {
    return (
      <>
        <div className="home-page">
          <Content style={CONTENT_STYLE}>
            <div className="home-page--wrapper">
              <div
                className="home-page--inner"
                style={{
                  height: 600,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 200 }} spin />}
                />
              </div>
            </div>
          </Content>
        </div>
      </>
    );
  }

  const taxonomyTabsItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'VI',
      children: (
        <Taxonomy
          language="vi"
          allTaxonomy={newsData.allTaxonomy}
          activeTaxonomy={configData.taxonomy}
          onChangeActive={handleChangeActiveTaxonomy}
        />
      ),
    },
    {
      key: '2',
      label: 'EN',
      children: (
        <Taxonomy
          language="en"
          allTaxonomy={newsData.allTaxonomy}
          activeTaxonomy={configData.taxonomy}
          onChangeActive={handleChangeActiveTaxonomy}
        />
      ),
    },
  ];

  const renderTaxonomyTabs = () => {
    return <Tabs items={taxonomyTabsItems} />;
  };

  return (
    <div className="home-page">
      <Content style={CONTENT_STYLE}>
        <div className="home-page--wrapper">
          <div className="home-page--inner">
            {/* <h1>Home Page</h1> */}

            <BannerConfig
              bannerConfigData={configData.banner}
              onBannerConfigChange={handleBannerConfigChange}
            />

            <Divider>{t('Taxonomy Configuration')}</Divider>

            <Button type="default" size="large" style={{ paddingInline: 23.5 }}>
              <Link to={'/categories'}>{t('Manage Taxonomy')}</Link>
            </Button>

            {/* Changed to <Categories/> in new api endpoint */}

            {renderTaxonomyTabs()}

            <Divider>{t('Carousel Configuration')}</Divider>

            <MediaUploadGroup
              isShowDrawer={isShowDrawer}
              setIsShowDrawer={setIsShowDrawer}
              pendingUploadList={pendingUploadList}
              //@ts-expect-error fix later
              handleFileChange={handleFileChange}
              handleSubmitSelection={handleSubmitSelection}
              excludedMedia={pendingSubmitData?.mediaIds || []}
            />

            <CarouselMediaGrid
              activeCarouselData={mergedData}
              onChangeActive={handleChangeActiveCarousel}
            />

            <Row justify={'center'} align={'middle'}>
              <Button
                type="default"
                icon={<CheckOutlined />}
                size="middle"
                style={{ ...buttonStyle, ...defaultStyle.primaryButton }}
                onClick={handleSubmit}
              >
                {t('apply')}
              </Button>
              <Button
                type="default"
                icon={<ClearOutlined />}
                size="middle"
                style={buttonStyle}
                onClick={handleReset}
              >
                {t('reset')}
              </Button>
            </Row>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default HomePage;
