import { httpApi } from '@/services/http.service';
import {
  LoginRequest,
  LoginResponseDefault,
  NewPasswordData,
  NewPasswordRequest,
  ResetPasswordRequest,
  SecurityCodePayload,
  SignUpRequest,
  UpdateUserProfileRequest,
  UpdateUserProfileResponse,
} from '@/types/auth.type';

export const updateUserProfileAPI = (
  userPayload: UpdateUserProfileRequest,
): Promise<UpdateUserProfileResponse> =>
  httpApi
    .put<UpdateUserProfileResponse>(`_api/v3/user/1`, { ...userPayload })
    .then(({ data }) => data);

export interface ChangePasswordProfileRequest {
  old_password: string;
  new_password: string;
}

export const changePasswordProfile = (
  payload: ChangePasswordProfileRequest,
): Promise<ChangePasswordProfileRequest> =>
  httpApi
    .post<ChangePasswordProfileRequest>(`_api/v3/user/change_password`, {
      ...payload,
    })
    .then(({ data }) => data);

export const login = (
  loginPayload: LoginRequest,
): Promise<LoginResponseDefault> =>
  httpApi
    .post<LoginResponseDefault>(
      '/auth/login',
      { ...loginPayload },
      {
        withCredentials: false,
      },
    )
    .then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi
    .post<undefined>('_api/signUp', { ...signUpData })
    .then(({ data }) => data);

export const resetPassword = (
  resetPasswordPayload: ResetPasswordRequest,
): Promise<undefined> =>
  httpApi
    .post<undefined>('/auth/forgot', { ...resetPasswordPayload })
    .then(({ data }) => data);

// export const forgotPassword = (forgotPasswordPayload: ForgotPasswordRequest): Promise<undefined> =>
//   httpApi.post<undefined>('/auth/change', {...forgotPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (
  securityCodePayload: SecurityCodePayload,
): Promise<undefined> =>
  httpApi
    .post<undefined>('_api/verifySecurityCode', { ...securityCodePayload })
    .then(({ data }) => data);

export const setNewPassword = (
  newPasswordData: NewPasswordData,
): Promise<undefined> =>
  httpApi
    .post<undefined>('/auth/change', { ...newPasswordData })
    .then(({ data }) => data);

export const logout = (): Promise<undefined> =>
  httpApi.post<undefined>('/auth/logout', {}).then(({ data }) => data);

export const doChangePassword = (
  changePassPayload: NewPasswordRequest,
): Promise<undefined> =>
  httpApi
    .post<undefined>('_api/changePassword', { ...changePassPayload })
    .then(({ data }) => data);
