import { CategoryPayLoad, CategoryType } from '@/@types/categories';
import { httpApi } from '@/services/http.service';
import { get } from 'lodash';

export const getAllCategory = async (): Promise<CategoryType[]> => {
  const res = await httpApi.get<{
    success: boolean;
    data: CategoryType[];
  }>('/categories');
  return res.data.data;
};
export const getCategory = async (id: string) => {
  const res = await httpApi.get<CategoryType>(`/categories/${id}`);
  return res;
};

export const createCategory = async (
  data: CategoryPayLoad,
): Promise<CategoryType> => {
  const res = await httpApi.post<CategoryType>('/categories', data);
  return res.data;
};

export const getAllCategoryByParam = async (limit: number, search: string) => {
  const res = await httpApi.get<CategoryType>(`/categories`, {
    params: { limit, search },
  });

  return res;
};

export const updateCategory = async (
  categoryId: string,
  data: CategoryType,
) => {
  const res = await httpApi.put<CategoryType>(
    `/categories/${categoryId}`,
    data,
  );
  return res;
};

export const deleteCategory = async (categoryId: string): Promise<void> => {
  await httpApi.delete<void>(`/categories/${categoryId}`);
};

export const deleteMultiUser = async (ids: string[]) => {
  // const res = await httpApi.delete<UserType.IUser>(`_api/v3/user`, { ids });
  ids.forEach(async id => {
    await deleteCategory(id);
  });
};
export const getInitFormFromCategory = (category: CategoryType | null) => ({
  _id: get(category, '_id', ''),
  name: get(category, 'name', ''),
  slug: get(category, 'slug', ''),
  title_en: get(category, 'title_en', ''),
});
export const makeDataCategoryRequest = (
  data: CategoryType & Record<string, number | string>,
): CategoryType => ({
  _id: data._id,
  name: data.name,
  slug: data.slug,
  title_en: data.title_en
});

export const makeDataCategoryEditRequest = (
  data: CategoryType & Record<string, number | string>,
): CategoryType => ({
  _id: data._id,
  name: data.name,
  slug: data.slug,
  title_en: data.title_en
});
