import {  GeneralLogoType, GeneralType } from '@/@types/general';
import { httpApi } from '@/services/http.service';
import { get } from 'lodash';

export const getAllGeneral = async (): Promise<GeneralType> => {
  const res = await httpApi.get<{
    success: boolean;
    data: GeneralType;
  }>('/config/general');
  return res.data.data;
};

export const getGeneral = async (id: number): Promise<GeneralType> => {
  const res = await httpApi.get<GeneralType>(`/general/${id}`);
  return res.data ;
};

export const getAllGeneralByLogoLogin = async (): Promise<GeneralLogoType> => {
  const res = await httpApi.get<{
    success: boolean;
    data: GeneralLogoType;
  }>('/config/logo');
  return res.data.data;
};
// export const createMenu = async (data: MenuItemPayLoad): Promise<MenuItemPike> => {
//   const res = await httpApi.post<MenuItemPike>(`/navigation`, data);
//   return res.data;
// };
// export const updateGeneral = async (generalId: number, data: GeneralPayLoad): Promise<GeneralType> => {
//   const res = await httpApi.put<GeneralType>(`/general/${generalId}`, data);
//   return res.data;
// };


export const getInitFormFromGeneral = (general: GeneralType | null) => {
  return {
    slogan: get(general, 'slogan', ''),
    _attachments: get(general, '_attachments', ''),
    name: get(general, 'name', ''),
    address: get(general, 'address', ''),
    email: get(general, 'email', ''),
    twitter: get(general, 'twitter', ''),
    facebook: get(general, 'facebook', ''),
    instagram: get(general, 'instagram', ''),
    linkedin: get(general, 'linkedin', ''),
  };
};
export const makeDataGeneralEditRequest = (data: GeneralType & Record<string, number | string>): GeneralType => ({
  id: data.id,
  slogan: data.slogan,
  _attachments: data.attachments,
  name: data.name,
  address: data.address,
  phone: data.phone,
  email: data.email,
  twitter: data.twitter,
  facebook: data.facebook,
  instagram: data.instagram,
  linkedin: data.linkedin
});

export type GeneralPatchBody = {
  navigation: string[]
} & Omit<GeneralType, 'navigation'> // [ ] ?

// Patch general
export const updateGeneral = async (configData: Partial<GeneralPatchBody>) => {
  const res = await httpApi.put<{
    general: Partial<GeneralPatchBody>;
  }>('/config/general', configData);
  return res.data;
};
