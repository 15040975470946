import React, { FC } from 'react';

import './styles.scss';

import { useLayoutContext } from '@/layouts/main/layoutContext';
import images from '@/assets/images';
import { useTranslation } from 'react-i18next';

interface SidebarLogoProps { }

const SidebarLogo: FC<SidebarLogoProps> = (): React.JSX.Element => {

  const { t } = useTranslation();
  const { isSidebarOpen, setIsSidebarOpen } = useLayoutContext();

  return (
    <div className="sidebar-logo">
      <div className="logo-wrapper">
        {' '}
        <div className='vasi-banner'>
          <img src={images.vasi_logo} />
          <h4>{t('vasi')}</h4>{' '}

        </div>
        {/* <div className="back-btn">
          {' '}
          <i className="fa fa-angle-left"> </i>{' '}
        </div>{' '} */}
      </div>
      <div
        onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="toggle-sidebar">
        {' '}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-grid status_toggle middle sidebar-toggle"
        >
          {' '}
          <rect x={3} y={3} width={7} height={7} />{' '}
          <rect x={14} y={3} width={7} height={7} />{' '}
          <rect x={14} y={14} width={7} height={7} />{' '}
          <rect x={3} y={14} width={7} height={7} />{' '}
        </svg>{' '}
      </div>{' '}
      <div className="logo-icon-wrapper">
        <a href="index.html">
          <img
            className="img-fluid"
            src="../assets/images/logo/logo-icon.png"
          />
        </a>
      </div>
    </div>
  );
};

export default SidebarLogo;
