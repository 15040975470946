import vasi_logo from './images/site/logovasi.png';
import vasi_banner from './images/site/vasi-banner.png';
import dummy_profile from './images/site/dummy_profile.jpeg';


const images = {
  vasi_logo,
  dummy_logo: dummy_profile,
  vasi_banner
};
export default images;
