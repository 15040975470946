import { DeleteOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Avatar, Button, Card } from 'antd';
import React, { FC } from 'react';
import './styles.scss';

interface SortableItemsProps {
  id: string
  title: React.ReactNode 
  index: number 
  setWidgetsCard: React.Dispatch<React.SetStateAction<string[]>>;
}

const SortableItems: FC<SortableItemsProps> = ({
  id,
  title,
  index,
  setWidgetsCard,
}): React.JSX.Element => {
  
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const deleteButton = () => {
    return <Button icon={<DeleteOutlined />} onClick={handleDelete}></Button>;
  };

  const handleDelete = () => {
    console.log('🪳 ~ file: index.tsx:44 ~ handleDelete~ id', id);
    setWidgetsCard(widgetsCard => widgetsCard.filter(item => item !== id));
  };

  const cardTitle = (
    <>
      <Avatar size={25} icon={index + 1} style={{ marginRight: 10 }} />
      {title}
    </>
  );

  return (
    <div
      className="sortable-items"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <Card title={cardTitle} size="small" extra={deleteButton()} />
    </div>
  );
};

export default SortableItems;
