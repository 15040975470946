import { Priority } from '@//constants/enums/priorities';
import { BaseBadgeProps } from '@/components/common/BaseBadge/BaseBadge';
import { NotificationType } from '@/components/common/BaseNotification/BaseNotification';
import { Severity } from '@/types/common.type';
import { Dayjs } from 'dayjs';
import i18next from 'i18next';
import _ from 'lodash';
import { AnyObject } from 'yup';
import { Dates } from './Dates';

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1),
    )
    .join('');
};

export const isUnique = (array: unknown[] | null) => {
  if (!array) {
    return false;
  }

  return new Set(array).size === array.length;
};

export const capitalize = (word: string): string =>
  `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const getDifference = (
  value: number,
  prevValue: number,
): string | null =>
  prevValue !== 0
    ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%`
    : '100%';

export const normalizeProp = (
  prop: string | number | [number, number],
): string =>
  typeof prop === 'number'
    ? `${prop}px`
    : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--success-color)';
    case Priority.MEDIUM:
      return 'var(--warning-color)';
    case Priority.HIGH:
      return 'var(--error-color)';
    default:
      return 'var(--success-color)';
  }
};

export const defineColorBySeverity = (
  severity: NotificationType | undefined,
  rgb = false,
): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a.filter(elem => !b.find(subElem => subElem[key] === elem[key])).concat(b);

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumber = (value: number): string => {
  if (value < 10) {
    return pad(value);
  }
  return _.isNumber(value)
    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    : value;
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const msToH = (ms: number): number => Math.floor(ms / 3600000);

export const hToMS = (h: number): number => h * 3600000;

export const mapBadgeStatus = (status: BaseBadgeProps['status']): Severity => {
  if (!status || status === 'default' || status === 'processing') {
    return 'info';
  }

  return status;
};

export const pad = (d: number) => {
  return d > 0 && d < 10 ? '0' + d.toString() : d.toString();
};

export const array2CSV = (array: AnyObject[]): string => {
  const headers: string[] = [];

  // Hàm đệ quy để lấy tất cả các khóa (keys) từ tất cả các đối tượng
  function getAllKeys(obj: AnyObject) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          getAllKeys(obj[key]); // Gọi đệ quy nếu giá trị là một đối tượng
        } else {
          headers.push(key);
        }
      }
    }
  }

  // Lấy header từ tất cả các đối tượng
  getAllKeys(array[0]);

  // Loại bỏ các header trùng lặp và chuẩn hóa
  const uniqueHeaders = [...new Set(headers)];

  // Tạo dòng header trong CSV
  const headerRow = uniqueHeaders.map(header => `"${header}"`).join(',');

  // Tạo dữ liệu CSV
  const csv = `${headerRow}\n${array
    .map(obj => {
      return uniqueHeaders
        .map(header => {
          const keys = header.split('.');
          let value = obj;
          for (const k of keys) {
            if (value && value[k] !== undefined) {
              value = value[k];
            } else {
              value = {};
              break;
            }
          }
          return `"${value !== null ? value.toString().replace(/"/g, '""') : ''}"`;
        })
        .join(',');
    })
    .join('\n')}`;

  return csv;
};

/**
 * Get Severity css class style
 * @param rawSeverity
 * @returns
 */
export const getSeverityStyleClass = (rawSeverity: string): string => {
  if (!rawSeverity) {
    return rawSeverity;
  }

  let severity = `<p>${rawSeverity}</p>`;
  const className = (className: string) => `f-severity--${className}`;
  const raw = rawSeverity.toLowerCase();
  switch (raw.toLowerCase()) {
    case 'critical':
      severity = `<p class="${className(raw)}">C</p>`;
      break;
    case 'high':
      severity = `<p class="${className(raw)}">H</p>`;
      break;
    case 'med':
      severity = `<p class="${className(raw)}">M</p>`;
      break;
    case 'low':
      severity = `<p class="${className(raw)}">L</p>`;
      break;
    case 'info':
      severity = `<p class="${className(raw)}">I</p>`;
      break;
    case 'unclassified':
      severity = `<p class="${className(raw)}">U</p>`;
      break;

    default:
      break;
  }

  return severity;
};

/**
 * Get Severity css class style
 * @param rawStatus
 * @returns
 */
export const getStatusStyleClass = (rawStatus: string): string => {
  if (!rawStatus) {
    return rawStatus;
  }

  const className = (className: string) => `f-status--${className}`;
  return `<p class="${className(rawStatus.toLowerCase())}">${rawStatus}</p>`;
};

export const getFirstCharUC = (str: string): string =>
  str.length > 0 ? str[0].toUpperCase() : '';

export const coverToCaseSnake = (str: string): string => {
  return str
    .replace(/\s+/g, '_')
    .toLowerCase()
    .replace(/([A-Z])/g, match => '_' + match.toLowerCase())
    .replace(/[^a-zA-Z0-9_]/g, '')
    .replace(/_{2,}/g, '_')
    .replace(/^_+|_+$/g, '');
};

export const currentLanguage = () => {
  console.log('i18next.language', i18next.language);
  return i18next.language;
};

export const getDayDateMonth = (value: Dayjs | null) => {
  if (!value) return {};
  const day = Dates.getDays()[value.day()];
  const date = value.date().toString();
  const month = Dates.getMonthsShort()[value.month()];
  return { day, date, month };
};

export type ArrayElement = string | number | number[];

export const handleChangeFeild = (
  lng: string,
  value: string,
  valueChanged: string,
) => {
  if (lng === 'vi') {
    return value;
  } else {
    return valueChanged;
  }
};

export function generateRandomId(length: number = 10): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
  }
  return result;
}