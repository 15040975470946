import { IData, MediaType } from '@/@types/media';
import { deleteMedia, getAllMediaWithParams } from '@/services/media.service';
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Image,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Spin,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCopyToClipboard from '@/hooks/useCopyToClipboard';
import './styles.scss';

const MediaGrid: FC<{
  reRender?: boolean;
  isShowSearch?: boolean;
  isShowPagination?: boolean;
}> = ({ reRender }) => {
  const [media, setMedia] = useState<MediaType>();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { copyToClipboard } = useCopyToClipboard(2000);
  const [mediaDetail, setMediaDetail] = useState<{
    filename: string;
    id: string;
    url: string;
  }>({
    filename: '',
    id: '',
    url: '',
  });
  const { t } = useTranslation();

  const openNotification = (
    type: 'success' | 'info' | 'warning' | 'error',
    message: string,
    description: string,
    placement: 'top' | 'bottom',
    duration: number,
  ) => {
    api.info({
      type,
      message,
      description,
      placement,
      duration,
    });
  };

  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    pageSize: 30,
  });

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const params = {
        paged: paginationState.currentPage,
        limit: paginationState.pageSize,
      };
      const source = await getAllMediaWithParams(params);

      setMedia(source);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [paginationState.currentPage, paginationState.pageSize, reRender]);

  const handlePageChange = (page: number, pageSize: number) => {
    setPaginationState({ currentPage: page, pageSize: pageSize });
  };

  // const { Search } = Input;

  // const handleSearch = async (value: string) => {
  //   try {
  //     const params = {
  //       paged: paginationState.currentPage,
  //       limit: paginationState.pageSize,
  //       search: value,
  //     };
  //     const source = await getAllMediaWithParams(params);

  //     setMedia(source);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const { confirm } = Modal;

  const showDeleteConfirm = (itemID: number) => {
    confirm({
      title: t('Are you sure delete this image'),
      icon: <ExclamationCircleFilled />,
      content: t('This action cannot be undone'),
      okText: t('yes'),
      okType: 'primary',
      cancelText: t('cancel'),

      styles: {
        footer: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },

      onOk() {
        handleDelete(itemID);
      },
    });
  };

  const handleDelete = async (itemID: number) => {
    try {
      await deleteMedia(itemID);
    } catch (error) {
      console.error('Error deleting media:', error);
    } finally {
      fetchData();
    }
  };

  const handleShowImageInfo = (item: IData) => {
    setMediaDetail({
      filename: item.filename,
      id: item._id,
      url: item.url,
    });
    setIsModalOpen(true);
  };

  const handleCopyImageInfo = (url: string) => () => {
    try {
      copyToClipboard(url);
      openNotification('success', t('copy_image'), `${url}`, 'top', 3);
    } catch (error) {
      console.error(error);
    }
  };

  const badgeButton = (itemID: number, item: IData) => {
    return (
      <div>
        <Button
          icon={<InfoCircleOutlined />}
          onClick={() => handleShowImageInfo(item)}
        ></Button>
        <Button
          icon={<DeleteOutlined />}
          onClick={() => showDeleteConfirm(itemID)}
        ></Button>
      </div>
    );
  };

  return (
    <>
      {contextHolder}

      <div className="media-grid">
        {isModalOpen ? (
          <Modal
            title={t('media_detail')}
            open={isModalOpen}
            onCancel={() => {
              setIsModalOpen(false);
            }}
            footer={[
              <Button
                key="ok"
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                {t('close')}
              </Button>,
              <Button
                type="primary"
                onClick={handleCopyImageInfo(mediaDetail.url)}
              >
                {t('copy_url')}
              </Button>,
            ]}
          >
            <p>
              <strong>{t('ID')}</strong>: {mediaDetail.id}
            </p>
            <p>
              <strong>{t('name')}</strong>: {mediaDetail.filename}
            </p>
            <p>
              <strong>{t('url')}</strong>:{' '}
              {/* <span style={{ color: 'blueviolet' }}>{mediaDetail.url}</span> */}
              <Input value={mediaDetail.url} />
            </p>
          </Modal>
        ) : (
          ''
        )}

        <Spin
          tip="Loading..."
          spinning={loading}
          style={{ background: 'transparent', minHeight: '100%' }}
        >
          {/* <div className="media-grid__header">
            <Search
              placeholder={t('Search...')}
              onSearch={handleSearch}
              style={{
                marginBlock: 20,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            />
          </div> */}
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24, lg: 32 },
              { xs: 8, sm: 16, md: 24, lg: 32 },
            ]}
          >
            {media?.data?.map((item: IData) => (
              <Col
                lg={4}
                xs={24}
                md={12}
                className="gutter-row"
                span={6}
                key={item.url}
              >
                <Badge count={badgeButton(item.id, item)} offset={[-28, 4]}>
                  <Image
                    className="media-item"
                    src={item?.sizes.mobile}
                    preview={{ src: item?.url }}
                  />
                </Badge>
              </Col>
            ))}
          </Row>

          <Pagination
            current={Number(media?.page)}
            total={media?.totalItem}
            style={{
              marginBlock: 20,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            showTotal={total => `${t('total')} ${total} ${t('picture')}`}
            onChange={(page, pageSize) => {
              handlePageChange(page, pageSize);
            }}
            pageSize={paginationState.pageSize}
            showSizeChanger
            pageSizeOptions={[30, 50, 100]}
          />
        </Spin>
      </div>
    </>
  );
};

export default MediaGrid;
