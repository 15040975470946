import React, { FC } from 'react';
import './styles.scss';

interface PageTitleProps {
  currentPage: string;
  breadcrumb: {
    title: string;
    subtitle: string;
  };
}

const PageTitle: FC<PageTitleProps> = ({
  currentPage,
  // breadcrumb,
}): React.JSX.Element => {
  return (
    <div className="page-title">
      <div className="row">
        <div className="col-6">
          <h4>{currentPage}</h4>
        </div>
        {/* <div className="col-6">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">
                <svg className="stroke-icon">
                  <use href="../assets/svg/icon-sprite.svg#stroke-home" />
                </svg>
              </a>
            </li>
            <li className="breadcrumb-item">{breadcrumb.subtitle}</li>
            <li className="breadcrumb-item active">{breadcrumb.title}</li>
          </ol>
        </div> */}
      </div>
    </div>
  );
};

export default PageTitle;
