import React, { FC, ReactNode, useState } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { setOpenSidebar } from '@/store/slices/sidebarSlice';

interface SidebarListProps {
  label: ReactNode;
  children: ReactNode;
  labelString?: string;
  link: string;
}

const SidebarList: FC<SidebarListProps> = ({
  link,
  label,
  children,
}): React.JSX.Element => {

  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(true); 


  return (
    <li className="sidebar-list">
      <Link
        className={`sidebar-link sidebar-title active `}
        onClick={() => { setIsOpen(!isOpen), dispatch(setOpenSidebar(false)) }}
        to={link}
      >
        {/* <span className="lan-3">{label}</span> */}
        {label}
        {children !== undefined && (
          <div className="according-menu">
            <i className={`fa fa-angle-${isOpen ? 'down' : 'right'}`} />
          </div>
        )}
      </Link>
      <ul
        className={`sidebar-submenu ${children !== undefined ? 'has-children' : ''}`}
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        {children}
      </ul>
    </li>
  );
};

export default SidebarList;
