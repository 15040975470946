import { IData, MediaType } from '@/@types/media';
import { getAllMediaWithParams } from '@/services/media.service';
import { Col, Pagination, Row, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';

import './styles.scss';

interface SelectableMediaGridProps {
  reRender?: boolean | undefined;
  pendingSelectedImages: number[] | undefined;
  setPendingSelectedImages: React.Dispatch<React.SetStateAction<number[]>>;
  excludedMedia?: string[] | number[] | undefined;
  type?: 'muti' | 'single';
}

const SelectableMediaGrid: FC<SelectableMediaGridProps> = ({
  reRender,
  pendingSelectedImages,
  setPendingSelectedImages,
  excludedMedia,
  type = 'muti',
}): React.JSX.Element => {
  const [media, setMedia] = useState<MediaType>();

  const [loading, setLoading] = useState(true);

  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    pageSize: 10,
  });

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const params = {
        paged: paginationState.currentPage,
        limit: paginationState.pageSize,
      };
      const source = await getAllMediaWithParams(params);

      setMedia(source);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setPaginationState({ currentPage: page, pageSize: pageSize });
  };

  // const { Search } = Input;

  // const handleSearch = async (value: string) => {
  //   try {
  //     const params = {
  //       paged: paginationState.currentPage,
  //       limit: paginationState.pageSize,
  //       search: value,
  //     };
  //     const source = await getAllHomeMediaWithParams(params);
  //     setMedia(source);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSelectImage = (item: IData, type: 'muti' | 'single') => {
    switch (type) {
      case 'muti':
        setPendingSelectedImages(prevState => {
          if (prevState?.includes(item.id)) {
            return prevState.filter(id => id !== item.id);
          } else {
            return [...prevState, item.id];
          }
        });
        break;
      case 'single':
        setPendingSelectedImages(prevState => {
          if (prevState?.includes(item.id)) {
            return prevState.filter(id => id !== item.id);
          } else {
            return [item.id];
          }
        });
        break;
      default:
        console.error('Unknown type of selection');
        break;
    }
  };

  useEffect(() => {
    fetchData();
  }, [paginationState.currentPage, paginationState.pageSize, reRender]);

  //prettier-ignore

  let mediaWithoutSelected

  if (!excludedMedia) {
    mediaWithoutSelected = media?.data;
  }

  mediaWithoutSelected = media?.data.filter(
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'MediaType'.
    (item: { id: number }) => !excludedMedia?.includes(item.id),
  );

  // const { t } = useTranslation();

  return (
    <div className="selectable-media-grid">
      <Spin
        tip="Loading..."
        spinning={loading}
        style={{ background: 'transparent', height: '100%' }}
      >
        <div className="media-grid__header" style={{ marginBottom: 20 }}>
          {/* <Search placeholder={t('Search...')} onSearch={handleSearch} /> */}
        </div>
        <div className="media-grid__header">
          <Pagination
            current={Number(media?.page)}
            total={media?.totalItem}
            style={{
              marginBottom: 20,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            onChange={(page, pageSize) => {
              handlePageChange(page, pageSize);
            }}
            pageSize={18}
            showSizeChanger={false}
          />
        </div>
        <div className="media-grid--inner">
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24, lg: 32 },
              { xs: 8, sm: 16, md: 24, lg: 32 },
            ]}
          >
            {mediaWithoutSelected?.map((item: IData) => (
              <Col
                lg={4}
                xs={24}
                md={12}
                className="gutter-row"
                span={6}
                key={item.url}
              >
                <button
                  className={`clickable-image ${pendingSelectedImages?.includes(item.id) ? 'active' : ''}`}
                  onClick={() => handleSelectImage(item, type)}
                >
                  <img className="gallery-image" src={item?.url} />
                </button>
              </Col>
            ))}
          </Row>
        </div>
      </Spin>
    </div>
  );
};

export default SelectableMediaGrid;
